import React from "react";
import "./RadioTicket.scss";

const RadioTicket = ({
  options = [],
  updateState,
  label,
  checked,
  displayError,
  displayErrorMessage
}) => {
  const handleChange = (e) => {
    updateState(e.target.value);
  };

  return (
    <div className='RadioTicket'>

      <p className="label">{label}</p>
      <div className="container-selects">
        {options.length > 0 ? (
          options.map((item, index) => (
            <label key={index} className={`RadioTicket ${checked === item ? "selected" : ""}`}>
              <div>
                <span className="radio">
                  <input
                    type="radio"
                    onChange={handleChange}
                    value={item}
                    checked={checked === item}
                  />
                  <span className="control" />
                </span>
                <p>{item}</p>
              </div>
            </label>
          ))
        ) : (
          <p>Nenhuma opção disponível</p>
        )}
      </div>
      {displayError && !checked && (
        <p className="error-message">{displayErrorMessage}</p>
      )}
    </div>
  );
};

RadioTicket.defaultProps = {
  label: "",
  options: []
};

export default RadioTicket;
