import { DeleteOutlined, InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Select,
  Table,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import Dragger from "antd/es/upload/Dragger";
import dayjs from "dayjs";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCreateEvent, apiCreateEventLogo, apiCreateEventTicket } from "../../../../api/events";
import { EVENT_CATEGORY_CHOICES, EVENT_NOMENCLATURE_CHOICES, EVENT_SUBJECT_CHOICES } from "../../../../utils/eventsSelect";
import { formatPrice } from '../../../../utils/format';
import { searchPostalCode } from "../../../../utils/searchPostalCode";
import AddQuestions from "../AddQuestions/AddQuestions";
import "./EventCreate.scss";

const EventCreate = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [description, setDescription] = useState('');
  const [nameClature, setNameClature] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [category, setCategory] = useState();
  const [subject, setSubject] = useState();
  const [fileList, setFileList] = useState();

  const handleEditorChange = (newContent) => {
    setDescription(newContent);
  };

  const config = {
    readonly: false,
    placeholder: 'Digite aqui...',
    buttons: [
      'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',
    ],
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
  }

  // ticket
  const [nameTicket, setNameTicket] = useState();
  const [descriptionTicket, setDescriptionTicket] = useState();
  const [starterQuestionList, setStarterQuestionList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const formatDateForSubmission = (date) => {
    if (!date) return null;

    const year = date.year();
    const month = String(date.month() + 1).padStart(2, '0');
    const day = String(date.date()).padStart(2, '0');
    const hours = String(date.hour()).padStart(2, '0');
    const minutes = String(date.minute()).padStart(2, '0');
    const seconds = String(date.second()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleNameClature = (value) => {
    setNameClature(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleSubjectChange = (value) => {
    setSubject(value);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = formatDateForSubmission(date);
      setStartDate(formattedDate);
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = formatDateForSubmission(date);
      setEndDate(formattedDate);
    } else {
      setEndDate(null);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await apiCreateEventLogo(fileList[0]?.originFileObj);
      const imageUrl = response.url;

      const payload = {
        name: name,
        description: JSON.stringify(description),
        subject: subject,
        category: category,
        nomenclature: nameClature,
        starts_at: startDate,
        image_url: imageUrl,
        ends_at: endDate,
        address: {
          name: address.name_comercial,
          street: address.street,
          number: address.number,
          complement: address.complement,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
          postal_code: address.postal_code
        }
      };

      const eventJSON = await apiCreateEvent(payload);

      for (const ticket of tickets) {
        const payloadTicket = {
          name: ticket.name,
          description: ticket.description,
          amount: Math.floor(ticket.amount),
          questions: ticket.starterQuestionList,
        };

        await apiCreateEventTicket(eventJSON.id, payloadTicket);
      }

      message.success("Evento criado com sucesso!");
      navigate('/eventos');
    } catch (error) {
      message.error("Erro ao criar evento.");
    }
  };

  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        if (!description || description.trim() === '') {
          message.error("Por favor, insira a descrição.");
          return;
        }
        if (tickets.length === 0) {
          message.error("Por favor, adicione pelo menos um ticket.");
          return;
        }
        handleSubmit();
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  // ADDRESS
  const [address, setAddress] = useState({
    name: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    postal_code: '',
  });

  useEffect(() => {
    if (address.postal_code.length === 8) {
      checkPostalCode(address.postal_code);
    }
  }, [address.postal_code]);

  const checkPostalCode = (number) => {
    searchPostalCode(number, (data) => {
      if (data && !data.erro) {
        const updatedAddress = {
          name: data.localidade,
          street: data.logradouro,
          number: address.number,
          complement: address.complement,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          postal_code: data.cep,
        };

        setAddress(updatedAddress);
        form.setFieldsValue(updatedAddress);
      } else {
        message.error("CEP não encontrado. Preencha manualmente.");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // TODO TICKET
  const addQuestion = () => {
    const questionList = [...questions, ""];
    setQuestions(questionList);
  };

  const handleUploadChange = (info) => {
    const newFileList = info.fileList.slice(-1);
    setFileList(newFileList);

    if (info.file.status === "done") {
      message.success(`${info.file.name} arquivo carregado com sucesso`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} falha no carregamento`);
    }
  };

  // TICKETS
  const [tickets, setTickets] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTicketId, setEditingTicketId] = useState(null);
  const [internalAmount, setInternalAmount] = useState("");
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    amount: ''
  });

  const validateFields = () => {
    const newErrors = {
      name: '',
      description: '',
      amount: ''
    };

    if (!nameTicket) newErrors.name = 'Por favor, insira o nome do ticket!';
    if (!descriptionTicket) newErrors.description = 'Por favor, insira a descrição do ticket!';
    if (!isFree && internalAmount <= 0) newErrors.amount = 'Por favor, insira um preço válido!';

    setErrors(newErrors);

    return !newErrors.name && !newErrors.description && !newErrors.amount;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setInternalAmount(parseInt(numericValue, 10) || 0);
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsFree(checked);
    if (checked) {
      setInternalAmount(0);
    }
  };

  const openModal = () => {
    setIsModalVisible(true);
    setIsEditing(false);
    setNameTicket('');
    setDescriptionTicket('');
    setInternalAmount(0);
    setStarterQuestionList([]);
  };

  const openModalToEdit = (ticket) => {
    setIsEditing(true);
    setEditingTicketId(ticket.id);
    setNameTicket(ticket.name);
    setDescriptionTicket(ticket.description);
    setInternalAmount(ticket.amount)
    setQuestions(ticket.questions || []);
    setStarterQuestionList(ticket.starterQuestionList || []);
    setIsModalVisible(true);
    setErrors({})
  };

  const handleSaveTicket = () => {
    if (validateFields()) {
      if (isEditing) {
        const updatedTickets = tickets.map((ticket) => {
          if (ticket.id === editingTicketId) {
            return {
              ...ticket,
              name: nameTicket,
              description: descriptionTicket,
              amount: internalAmount,
              questions: questions,
              starterQuestionList: starterQuestionList,
            };
          }
          return ticket;
        });
        setTickets(updatedTickets);
      } else {
        setTickets([
          ...tickets,
          {
            id: new Date().getTime(),
            name: nameTicket,
            description: descriptionTicket,
            amount: internalAmount,
            questions: questions,
            starterQuestionList: starterQuestionList,
          },
        ]);
      }

      setIsModalVisible(false);
      resetForm();
    }
  };

  const handleDeleteTicket = () => {
    const updatedTickets = tickets.filter(ticket => ticket.id !== editingTicketId);
    setTickets(updatedTickets);
    setIsModalVisible(false);
    resetForm();
  };

  const resetForm = () => {
    setNameTicket("");
    setDescriptionTicket("");
    setQuestions([]);
    setStarterQuestionList([]);
    setInternalAmount(0);
  };

  const columns = [
    {
      title: 'Nome do Ticket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Preço',
      dataIndex: 'amount',
      key: 'price',
      render: (amount) => `${formatPrice(amount / 100)}`,
    },
    {
      title: 'Ação',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => openModalToEdit(record)}>
          Editar
        </Button>
      ),
    },
  ];

  const props = {
    name: "file",
    multiple: false,
    onChange: handleUploadChange,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png,.gif",
    style: { width: '100%' },
    beforeUpload: () => false,
  };

  return (
    <div className="EventCreate">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <h2 className="title-page">Criar Evento</h2>
          </div>
          <Divider />
          <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Eventos</Breadcrumb.Item>
          <Breadcrumb.Item>Criar Evento</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">1. Infos do Evento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Form form={form}>
                <Form.Item
                  label="Nome do Evento"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o nome do link.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>

                <Form.Item
                  label="Categoria"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a categoria.",
                    },
                  ]}
                >
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    placeholder="Selecione a categoria"
                  >
                    {EVENT_CATEGORY_CHOICES.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Assunto"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione o assunto.",
                    },
                  ]}
                >
                  <Select
                    value={subject}
                    onChange={handleSubjectChange}
                    placeholder="Selecione o assunto"
                  >
                    {EVENT_SUBJECT_CHOICES.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>
            <div className="container-right">
              <Form form={form}>
                <Form.Item
                  label="Nomenclatura"
                  name="nameClature"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a nomenclatura.",
                    },
                  ]}
                >
                  <Select
                    value={nameClature}
                    onChange={handleNameClature}
                    placeholder="Selecione a nomenclatura"
                  >
                    {EVENT_NOMENCLATURE_CHOICES.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Data de Início"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a data de início.",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    onChange={handleStartDateChange}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecione a data de Início.",
                      },
                    ]}
                    value={startDate ? dayjs(startDate) : null}
                  />
                </Form.Item>
                <Form.Item
                  label="Data de Fim"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a data de fim.",
                    },
                  ]}>
                  <DatePicker
                    showTime
                    onChange={handleEndDateChange}
                    value={endDate ? dayjs(endDate) : null}
                  />
                </Form.Item>

              </Form>
            </div>
          </div>

          <div className="container-inputs" style={{ flexDirection: 'column' }}>

            <Form form={form}>
              <Form.Item
                label="Imagem de divulgação"
                name="eventImage"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione uma imagem.",
                  },
                ]}
                getValueFromEvent={({ fileList }) => fileList}
              >
                <Dragger
                  {...props}>
                  {fileList && fileList.length > 0 && fileList[0].originFileObj ? (
                    <img
                      src={URL.createObjectURL(fileList[0].originFileObj)}
                      alt="preview"
                      style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                    />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ fontSize: "32px", color: "#1890ff" }} />
                      </p>
                      <p className="ant-upload-text">Clique ou arraste a imagem aqui</p>
                      <p className="ant-upload-hint">
                        A dimensão recomendada é de <strong>1600 x 838</strong> (mesma proporção do formato utilizado nas páginas de evento no Facebook). Formato <strong>JPEG, GIF ou PNG</strong> de no máximo <strong>2MB</strong>. Imagens com dimensões diferentes serão redimensionadas.
                      </p>
                    </>
                  )}
                </Dragger>
              </Form.Item>
            </Form>
          </div>
          <div className="container-width" style={{ marginTop: 30 }}>
            <label>Descrição</label>
            <JoditEditor
              value={description}
              config={config}
              onBlur={handleEditorChange}
              onChange={(newContent) => { }}
            />
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">2. Endereço do evento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-right">
              <Form
                form={form}
              >
                <Form.Item
                  label="CEP"
                  name="postal_code"
                  rules={[{ required: true, message: 'Por favor, insira o CEP.' }]}
                >
                  <Input
                    name="postal_code"
                    value={address.postal_code}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Nome Comercial"
                  name="name_comercial"
                  rules={[{ required: true, message: 'Por favor, insira o nome comercial.' }]}
                >
                  <Input
                    name="name_comercial"
                    value={address.name}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Rua"
                  name="street"
                  rules={[{ required: true, message: 'Por favor, insira a rua.' }]}
                >
                  <Input
                    name="street"
                    value={address.street}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Bairro"
                  name="neighborhood"
                  rules={[{ required: true, message: 'Por favor, insira o bairro.' }]}
                >
                  <Input
                    name="neighborhood"
                    value={address.neighborhood}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="container-left">
              <Form form={form} >
                <Form.Item
                  label="Número"
                  name="number"
                  rules={[{ required: true, message: 'Por favor, insira o número.' }]}
                >
                  <Input
                    name="number"
                    value={address.number}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Complemento"
                  name="complement"
                  rules={[{ required: true, message: 'Por favor, insira o complemento.' }]}
                >
                  <Input
                    name="complement"
                    value={address.complement}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Estado"
                  name="state"
                  rules={[{ required: true, message: 'Por favor, insira o estado.' }]}
                >
                  <Input
                    name="state"
                    value={address.state}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Cidade"
                  name="city"
                  rules={[{ required: true, message: 'Por favor, insira a cidade.' }]}
                >
                  <Input
                    name="city"
                    value={address.city}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
            </div>

          </div>
        </Content>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: tickets.length > 0 ? 280 : 0,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle" style={{ marginBottom: 30 }}>
            <h3 className="subtitle-page">2. Tickets do evento</h3>
            <Button type="primary" icon={<PlusCircleOutlined />} onClick={openModal}>Adicionar Ticket</Button>
          </div>
          {tickets.length > 0 && <Table columns={columns} dataSource={tickets} />}
          {tickets.length === 0 && <p style={{ textAlign: 'center' }}>Não há tickets cadastrados. <br /> <strong style={{ color: "red" }}>(Obrigatório)</strong></p>}
          <Modal
            title={isEditing ? "Editar Ticket" : "Adicionar Ticket"}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={[
              isEditing && (
                <Button key="delete" type="primary" danger icon={<DeleteOutlined />} onClick={handleDeleteTicket}>
                  Excluir
                </Button>
              ),
              <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                Cancelar
              </Button>,
              <Button key="submit" type="primary" onClick={handleSaveTicket}>
                {isEditing ? "Salvar" : "Adicionar"}
              </Button>
            ]}
          >
            <Form layout="vertical">
              <Form.Item
                label="Nome"
                validateStatus={errors.name ? 'error' : ''}
                help={errors.name}
                className="required"
              >
                <Input
                  value={nameTicket}
                  onChange={(e) => setNameTicket(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Descrição"
                validateStatus={errors.description ? 'error' : ''}
                help={errors.description}
                className="required"
              >
                <Input.TextArea
                  value={descriptionTicket}
                  onChange={(e) => setDescriptionTicket(e.target.value)}
                />
              </Form.Item>
              <div>
                <Form.Item
                  validateStatus={errors.amount ? 'error' : ''}
                  help={errors.amount}
                  style={{ margin: 0 }}
                  className="required"
                  label="Preço">

                  <Input
                    value={formatPrice(internalAmount / 100)}
                    onChange={handleAmountChange}
                    disabled={isFree}
                  />
                </Form.Item>
                <Form.Item className="checkbox-item">
                  <Checkbox
                    checked={isFree}
                    onChange={handleCheckboxChange}
                  >
                    Ingresso grátis
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="container-question-button" style={{ marginBottom: 30 }}>
                <h3>Perguntas Iniciais</h3>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => { addQuestion() }}
                >
                  Nova Pergunta
                </Button>
              </div>
              <AddQuestions
                starterQuestionList={starterQuestionList}
                setStarterQuestionList={setStarterQuestionList}
                setQuestions={setQuestions}
                questions={questions}
              />
            </Form>
          </Modal>

        </Content>
        <div className="container-buttons">
          <Button
            onClick={onFinish}
            type="primary"
          >
            Criar evento
          </Button>
        </div>
      </Layout>
    </div >
  );
};

export default EventCreate;