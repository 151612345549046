import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  message,
  notification,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { apiAccessCreate, apiAccessEdit, apiAccessList } from "../../../../api/access";
import "./AccessCreate.scss";

const AccessCreateModal = ({ visible, onClose, fetchPage, onCreate }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [name, setName] = useState();
  const [email, setEmail] = useState("TESTE");
  const [password, setPassword] = useState();
  const [role, setRole] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingFetch, setLoadingFetch] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const clearStates = () => {
    form.resetFields();
    setName('');
    setEmail('');
    setRole('');
    setPassword('');
    setConfirmPassword('');
  };


  const validateConfirmPassword = (_, value) => {
    if (value && value !== password) {
      return Promise.reject(new Error("As senhas não coincidem"));
    }
    return Promise.resolve();
  };

  const validateUsername = (_, value) => {
    const regex = /^[a-zA-Z0-9@\/.+_-]*$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        "O nome de usuário deve conter apenas letras, números e os caracteres @/./+/-/_"
      )
    );
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value === "admin");
  };

  const handleSubmit = async () => {
    setLoadingFetch(true);
    let userExistID = null;

    try {
      const jsonCreate = {
        email,
        password,
      };

      const createResponse = await apiAccessCreate(jsonCreate)
        .then((response) => response)
        .catch((error) => {
          throw error;
        });

      const editPayload = {
        username: name,
        is_superuser: role,
      };

      await apiAccessEdit(createResponse.user.id, editPayload)
        .then((response) => response)
        .catch((error) => {
          userExistID = createResponse.user.id;
          throw error;
        });

      setLoadingFetch(false);
      notification.success({
        message: "Sucesso",
        description: "Acesso criado com sucesso.",
        placement: "topRight",
      });

      await apiAccessList({ page: 1 })
      fetchPage(1)
      clearStates();
      onClose();  // Close modal on success
      if (onCreate) {
        onCreate(createResponse.user.id);
      }
    } catch (error) {
      setLoadingFetch(false);
      if (
        error.status === 400 &&
        error?.message?.email?.[0] === "Esse campo deve ser  único."
      ) {
        form.setFields([
          {
            name: "email",
            errors: [
              "O email inserido já está em uso. Por favor, escolha outro email.",
            ],
          },
        ]);
      } else if (
        error.status === 400 &&
        error.message.username[0] ===
        "Um usuário com este nome de usuário já existe."
      ) {
        notification.warning({
          message: "Atenção",
          description:
            "Acesso criado com sucesso. Porém o nome de usuário já existe. Altere o nome de usuário.",
          placement: "topRight",
        });
        // Consider showing userExistID in a modal or some other feedback
      } else {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao processar a requisição. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      }
      clearStates();
      fetchPage(1)
      await apiAccessList({ page: 1 })
    }
  };

  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        if (password && password === confirmPassword) {
          handleSubmit();
        } else {
          message.error("As senhas não coincidem");
        }
      })
      .catch(() => {
        message.error("Verifique todos os campos preenchidos.");
      });
  };

  const validateEmail = (rule, value, callback) => {
    if (value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        callback("Por favor, insira um email válido!");
      } else {
        callback();
      }
    } else {
      callback("Por favor, insira o email");
    }
  };

  return (
    <Modal
      title="Criação de usuário"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingFetch}
          onClick={onFinish}
        >
          Criar usuário
        </Button>
      ]}
    >
      <div className="AccessCreate">
        <Content
          style={{
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">Informações de usuário</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o nome",
                    },
                    {
                      validator: validateUsername,
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o email",
                    },
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Item>
                <Form.Item
                  label="Cargo"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione o cargo",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={handleRoleChange}
                    value={role ? "admin" : "vendedor"}
                  >
                    <Radio value="admin">Administrador</Radio>
                    <Radio value="vendedor">Vendedor</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
            <div className="container-right">
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Senha"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a senha",
                    },
                    {
                      validator: validateConfirmPassword,
                    },
                    {
                      min: 8,
                      message: "A senha deve ter pelo menos 8 caracteres",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                      message:
                        "A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial",
                    }
                  ]}
                >
                  <Input.Password
                    placeholder="****************"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                </Form.Item>

                <Form.Item
                  label="Confirmar Senha"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, confirme sua senha",
                    },
                    {
                      validator: validateConfirmPassword,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="****************"
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>
      </div>
    </Modal>
  );
};

export default AccessCreateModal;
