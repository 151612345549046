import { Breadcrumb, Divider, Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React from 'react';

const Home = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <div className='Home'>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h2 className="title-page">Home</h2>
          </div>
          <Divider />
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page" style={{ fontSize: '30px' }}>Bem vindo(a) ao DL PAY</h3>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Home
