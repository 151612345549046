export const bankOptions = [
  { value: "001", label: "Banco do Brasil" },
  { value: "003", label: "Banco da Amazônia" },
  { value: "004", label: "Banco do Nordeste" },
  { value: "021", label: "Banestes" },
  { value: "025", label: "Banco Alfa" },
  { value: "027", label: "Besc" },
  { value: "029", label: "Banerj" },
  { value: "031", label: "Banco Beg" },
  { value: "033", label: "Banco Santander Banespa" },
  { value: "036", label: "Banco Bem" },
  { value: "037", label: "Banpará" },
  { value: "038", label: "Banestado" },
  { value: "039", label: "BEP" },
  { value: "040", label: "Banco Cargill" },
  { value: "041", label: "Banrisul" },
  { value: "044", label: "BVA" },
  { value: "045", label: "Banco Opportunity" },
  { value: "047", label: "Banese" },
  { value: "062", label: "Hipercard" },
  { value: "063", label: "Ibibank" },
  { value: "065", label: "Lemon Bank" },
  { value: "066", label: "Banco Morgan Stanley Dean Witter" },
  { value: "069", label: "BPN Brasil" },
  { value: "070", label: "Banco de Brasília – BRB" },
  { value: "072", label: "Banco Rural" },
  { value: "073", label: "Banco Popular" },
  { value: "074", label: "Banco J. Safra" },
  { value: "075", label: "Banco CR2" },
  { value: "076", label: "Banco KDB" },
  { value: "096", label: "Banco BMF" },
  { value: "104", label: "Caixa Econômica Federal" },
  { value: "107", label: "Banco BBM" },
  { value: "116", label: "Banco Único" },
  { value: "151", label: "Nossa Caixa" },
  { value: "175", label: "Banco Finasa" },
  { value: "184", label: "Banco Itaú BBA" },
  { value: "204", label: "American Express Bank" },
  { value: "208", label: "Banco Pactual" },
  { value: "212", label: "Banco Matone" },
  { value: "213", label: "Banco Arbi" },
  { value: "214", label: "Banco Dibens" },
  { value: "217", label: "Banco Joh Deere" },
  { value: "218", label: "Banco Bonsucesso" },
  { value: "222", label: "Banco Calyon Brasil" },
  { value: "224", label: "Banco Fibra" },
  { value: "225", label: "Banco Brascan" },
  { value: "229", label: "Banco Cruzeiro" },
  { value: "230", label: "Unicard" },
  { value: "233", label: "Banco GE Capital" },
  { value: "237", label: "Bradesco" },
  { value: "241", label: "Banco Clássico" },
  { value: "243", label: "Banco Stock Máxima" },
  { value: "246", label: "Banco ABC Brasil" },
  { value: "248", label: "Banco Boavista Interatlântico" },
  { value: "249", label: "Investcred Unibanco" },
  { value: "250", label: "Banco Schahin" },
  { value: "252", label: "Fininvest" },
  { value: "254", label: "Paraná Banco" },
  { value: "263", label: "Banco Cacique" },
  { value: "265", label: "Banco Fator" },
  { value: "266", label: "Banco Cédula" },
  { value: "300", label: "Banco de la Nación Argentina" },
  { value: "318", label: "Banco BMG" },
  { value: "320", label: "Banco Industrial e Comercial" },
  { value: "356", label: "ABN Amro Real" },
  { value: "341", label: "Itau" },
  { value: "347", label: "Sudameris" },
  { value: "351", label: "Banco Santander" },
  { value: "353", label: "Banco Santander Brasil" },
  { value: "366", label: "Banco Societe Generale Brasil" },
  { value: "370", label: "Banco WestLB" },
  { value: "376", label: "JP Morgan" },
  { value: "389", label: "Banco Mercantil do Brasil" },
  { value: "394", label: "Banco Mercantil de Crédito" },
  { value: "399", label: "HSBC" },
  { value: "409", label: "Unibanco" },
  { value: "412", label: "Banco Capital" },
  { value: "422", label: "Banco Safra" },
  { value: "453", label: "Banco Rural" },
  { value: "456", label: "Banco Tokyo Mitsubishi UFJ" },
  { value: "464", label: "Banco Sumitomo Mitsui Brasileiro" },
  { value: "477", label: "Citibank" },
  { value: "479", label: "Itaubank (antigo Bank Boston)" },
  { value: "487", label: "Deutsche Bank" },
  { value: "488", label: "Banco Morgan Guaranty" },
  { value: "492", label: "Banco NMB Postbank" },
  { value: "494", label: "Banco la República Oriental del Uruguay" },
  { value: "495", label: "Banco La Provincia de Buenos Aires" },
  { value: "505", label: "Banco Credit Suisse" },
  { value: "600", label: "Banco Luso Brasileiro" },
  { value: "604", label: "Banco Industrial" },
  { value: "610", label: "Banco VR" },
  { value: "611", label: "Banco Paulista" },
  { value: "612", label: "Banco Guanabara" },
  { value: "613", label: "Banco Pecunia" },
  { value: "623", label: "Banco Panamericano" },
  { value: "626", label: "Banco Ficsa" },
  { value: "630", label: "Banco Intercap" },
  { value: "633", label: "Banco Rendimento" },
  { value: "634", label: "Banco Triângulo" },
  { value: "637", label: "Banco Sofisa" },
  { value: "638", label: "Banco Prosper" },
  { value: "643", label: "Banco Pine" },
  { value: "652", label: "Itaú Holding Financeira" },
  { value: "653", label: "Banco Indusval" },
  { value: "654", label: "Banco A.J. Renner" },
  { value: "655", label: "Banco Votorantim" },
  { value: "707", label: "Banco Daycoval" },
  { value: "719", label: "Banif" },
  { value: "721", label: "Banco Credibel" },
  { value: "734", label: "Banco Gerdau" },
  { value: "735", label: "Banco Pottencial" },
  { value: "738", label: "Banco Morada" },
  { value: "739", label: "Banco Galvão de Negócios" },
  { value: "740", label: "Banco Barclays" },
  { value: "741", label: "BRP" },
  { value: "743", label: "Banco Semear" },
  { value: "745", label: "Banco Citibank" },
  { value: "746", label: "Banco Modal" },
  { value: "747", label: "Banco Rabobank International" },
  { value: "748", label: "Banco Cooperativo Sicredi" },
  { value: "749", label: "Banco Simples" },
  { value: "751", label: "Dresdner Bank" },
  { value: "752", label: "BNP Paribas" },
  { value: "753", label: "Banco Comercial Uruguai" },
  { value: "755", label: "Banco Merrill Lynch" },
  { value: "756", label: "Banco Cooperativo do Brasil" },
  { value: "757", label: "KEB" }
];
