import { ConfigProvider } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import PanelLayout from "./common/PanelLayout/PanelLayout";
import ProtectedRoute from "./common/ProtectedRoute/ProtectedRoute";
import Accreditation from "./pages/Accreditation/Accreditation";
import ApiKeyList from "./pages/ApiKeyList/ApiKeyList";
import DisputeManagement from "./pages/DisputeManagement/DisputeManagement";
import ExternalPayment from "./pages/ExternalPayment/ExternalPayment";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import PaymentCreate from "./pages/PaymentCreate/PaymentCreate";
import PaymentInfo from "./pages/PaymentInfo/PaymentInfo";
import PaymentInfoDetails from "./pages/PaymentInfoDetails/PaymentInfoDetails";
import PaymentPlans from "./pages/PaymentPlans/PaymentPlans";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import SaleInfo from "./pages/SaleInfo/SaleInfo";
import SalesListing from "./pages/SalesListing/SalesListing";
import SignUp from "./pages/SignUp/SignUp";
import SimulationTax from "./pages/SimulationTax/SimulationTax";
import SplitPlan from "./pages/SplitPlan/SplitPlan";
import SplitPlanDetails from "./pages/SplitPlanDetails/SplitPlanDetails";
import WebhookList from "./pages/WebhookList/WebhookList";
import Access from "./pages/access/Access/Access";
import AccessInfo from "./pages/access/AccessInfo/AccessInfo";
import AccountDetails from "./pages/accounts/AccountDetails/AccountDetails";
import Accounts from "./pages/accounts/Accounts/Accounts";
import EventCheckoutDetails from "./pages/events/EventCheckoutDetails/EventCheckoutDetails";
import EventCreate from "./pages/events/EventCreate/EventCreate";
import EventDetails from "./pages/events/EventDetails/EventDetails";
import Events from "./pages/events/Events/Events";
import PriceList from "./pages/priceList/PriceList/PriceList";
import CheckoutTicket from "./pages/tickets/CheckoutTicket/CheckoutTicket";
import ResponseTicket from "./pages/tickets/ResponseTicket/ResponseTicket";
import TicketSelector from "./pages/tickets/TicketSelector/TicketSelector";

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    // colorPrimary: '#00B327',
                },
                components: {
                    Steps: {
                        // colorPrimary: '#1890FF',
                        colorTextBase: '#000000',
                        algorithm: true
                    },
                },
            }}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <ProtectedRoute>
                            <PanelLayout><Home /></PanelLayout>
                        </ProtectedRoute>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/listagem-vendas" element={<ProtectedRoute>
                        <PanelLayout><SalesListing /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/listagem-vendas" element={<ProtectedRoute>
                        <PanelLayout><SalesListing /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/simulador-taxas" element={<ProtectedRoute>
                        <PanelLayout><SimulationTax /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/transacao/:slug?" element={<ProtectedRoute>
                        <PanelLayout><SaleInfo /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/disputas" element={<ProtectedRoute>
                        <PanelLayout><DisputeManagement /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/pagamentos" element={<ProtectedRoute>
                        <PanelLayout><PaymentPlans /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/pagamento/:slug?" element={<ProtectedRoute>
                        <PanelLayout><PaymentInfo /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/detalhe/pagamento/:id?/:idDetails?" element={<ProtectedRoute>
                        <PanelLayout><PaymentInfoDetails /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/planos-e-taxas/detalhe/:slug?" element={<ProtectedRoute>
                        <PanelLayout><SplitPlanDetails /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/pagamento/criar" element={<ProtectedRoute>
                        <PanelLayout><PaymentCreate /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/webhooks" element={<ProtectedRoute>
                        <PanelLayout><WebhookList /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/apikey" element={<ProtectedRoute>
                        <PanelLayout><ApiKeyList /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/planos-e-taxas" element={<ProtectedRoute>
                        <PanelLayout><SplitPlan /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/teste" element={<ProtectedRoute>
                        <PanelLayout><PriceList /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/credenciamento" element={<ProtectedRoute>
                        <PanelLayout><Accreditation /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/eventos" element={<ProtectedRoute>
                        <PanelLayout><Events /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/eventos/:id?" element={<ProtectedRoute>
                        <PanelLayout><EventDetails /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/eventos/detalhe/:eventID?/:checkoutID??" element={<ProtectedRoute>
                        <PanelLayout><EventCheckoutDetails /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/eventos/criar" element={<ProtectedRoute>
                        <PanelLayout><EventCreate /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/evento/:name?/:id?" element={<TicketSelector />} />
                    <Route path="/evento/checkout/:eventID?/:id?" element={<CheckoutTicket />} />
                    <Route path="/evento/pagamento/:eventID?/:checkoutID?" element={<ResponseTicket />} />
                    <Route path="/admin/acessos" element={<ProtectedRoute>
                        <PanelLayout><Access /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/admin/acessos/:slug?" element={<ProtectedRoute>
                        <PanelLayout><AccessInfo /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/lojistas" element={<ProtectedRoute>
                        <PanelLayout><Accounts /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/lojista/detalhes/:slug?" element={<ProtectedRoute>
                        <PanelLayout><AccountDetails /></PanelLayout>
                    </ProtectedRoute>} />
                    <Route path="/register" element={<SignUp />} />
                    <Route path="/pagar/:slug?" element={<ExternalPayment />} />
                    <Route path="/recovery" element={<RecoverPassword />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;
