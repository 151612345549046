import React from 'react'
import './Profile.scss'
import { Button, Divider } from 'antd'

const Profile = ({handleProfile}) => {
  return (
    <div className='Profile'>
      <div className="content">
        <div className="title">Perfil</div>
        <Divider />
        <div className="form">
          <p>Em qual perfil você se encaixa?</p>
          <div className="flex-column">
            <Button onClick={() => handleProfile("fisica")} size="large">Pessoa Física</Button>
            <Button onClick={() => handleProfile("juridica")} size="large">Pessoa Jurídica</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
