import React from 'react';
import { Input as InputAntd } from 'antd';

const Input = ({ title, margin, ...props }) => {
  return (
    <div style={{ margin }}>
      {title && <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>{title}</div>}
      <InputAntd {...props} />
    </div>
  );
};

export default Input;
