import React from 'react';
import Question from '../Question/Question';
import './CheckoutQuestions.scss';

const CheckoutQuestions = ({ questions, onAnswerChange, errorForm }) => {
  return (
    <div className="checkout-questions">
      {questions.map(({ uniqueKey, question_label, question_type, answer, options }) => (
        <Question
          key={uniqueKey}
          question={{ prompt: question_label, type: question_type, options }}
          answer={answer}
          setAnswer={(newAnswer) => onAnswerChange(uniqueKey, newAnswer)}
          errorForm={errorForm}
          uniqueKey={uniqueKey}
        />
      ))}
    </div>
  );
};


export default CheckoutQuestions;
