import { Navigate, Outlet } from "react-router-dom";
import { getItemFromStorage } from "../../../helpers/storage";
// import { getUserStorage } from "../../../helper/loginStorage";

const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
  const infoUserString = getItemFromStorage('accessToken');

  if (!infoUserString) {
    return <Navigate to="/login" replace />;
  } else {
    return children ? children : <Outlet />;
  }
};

export default ProtectedRoute;
