import { Breadcrumb, Divider, Empty, Input, Layout, Skeleton, Table, notification, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiSplitPlans } from '../../../api/splitPlans';
import './SplitPlan.scss';

const { Content } = Layout;

const SplitPlan = () => {
  const [dataSales, setDataSale] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Função para buscar e atualizar os dados da API
  const handleListSplitPlans = async (page) => {
    setLoading(true);
    try {
      const json = await apiSplitPlans({ page });
      setDataSale(json);
      setFilteredData(json.results);
      setPagination(prev => ({
        ...prev,
        total: json.count,
        current: page,
      }));
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Função para busca
  const handleSearch = async (value) => {
    setSearchText(value);
    setLoading(true);
    try {
      if (value === '') {
        // Se a busca estiver vazia, carrega a página atual
        await handleListSplitPlans(pagination.current);
      } else {
        // Realiza a busca com o valor fornecido
        const json = await apiSplitPlans({ page: 1, search: value });
        setFilteredData(json.results);
        setPagination({
          current: 1,
          total: json.count,
          pageSize: pagination.pageSize,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  // Função de alteração da páginação
  const onChange = (pagination) => {
    handleListSplitPlans(pagination.current);
  };

  useEffect(() => {
    handleListSplitPlans(pagination.current);
  }, [pagination.current]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <p
          onClick={() => navigate(`/planos-e-taxas/detalhe/${record.id}`)}
          style={{ textTransform: "uppercase", cursor: 'pointer' }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      ),
    },
  ];

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  return (
    <div className='SplitPlan'>
      <Layout style={{ padding: '1.25rem' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <h2 className='title-page'>Planos e Taxas</h2>
          <Divider />
          <Breadcrumb.Item>Operação</Breadcrumb.Item>
          <Breadcrumb.Item>Planos e Taxas</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="box-divider">
            <h2 className='subtitle-page'>Planos e Taxas</h2>
          </div>
          <Divider />
          <Input.Search
            placeholder='Pesquisar'
            allowClear
            enterButton
            value={searchText}
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 16, maxWidth: '200px' }}
          />
          {loading ? (
            <Skeleton active />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              dataSource={filteredData.map((item) => ({ ...item, key: item.id }))}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                showSizeChanger: false,
                onChange: (page) => handleListSplitPlans(page),
              }}
              bordered
            />
          )}
        </Content>
        <Divider />
      </Layout>
    </div>
  );
};

export default SplitPlan;
