import { InfoCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Divider, Empty, Form, Input, Layout, notification, Result, Skeleton, Table, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiPaymentsCheckoutDetails } from '../../../api/payment';
import { apiTransactionsList } from '../../../api/transactions';
import { formatDate, formatPrice } from '../../../utils/format';
import { useIsMobileHook } from '../../../utils/WindowHooks';
import InputMoney from '../../common/InputMoney/InputMoney';
import './PaymentInfoDetails.scss';

const PaymentInfoDetails = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const { idDetails } = useParams();

  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    apiPaymentsCheckoutDetails(id, idDetails).then((json) => {
      setLoading(false);
      setInitialValues(json);
      form.setFieldsValue({ name: json?.customer.name });
      form.setFieldsValue({ phone_number: json?.customer.phone_number });
      form.setFieldsValue({ email: json?.customer.email });
      form.setFieldsValue({ price: json?.online_sell?.amount });
      form.setFieldsValue({ expiration_date: json?.online_sell?.expiration_date });
      form.setFieldsValue({ name_online_sell: json?.online_sell?.name });
      form.setFieldsValue({ reference_online_sell: json?.online_sell?.reference });
    });
  }, [id, idDetails]);

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const [form] = Form.useForm();
  const isMobile = useIsMobileHook(1600);
  const [filteredData, setFilteredData] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [isFetching, setIsFetching] = useState(false);


  const handleSimulate = async (page) => {
    setIsFetching(true);
    try {
      const json = await apiTransactionsList({ page, sell: idDetails });
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
    } catch (error) {
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro a tabela de vendas",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor a tabela de vendas. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("500");
        notification.error("Erro ao buscar as vendas");
      }
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    handleSimulate(pagination.current);
  }, [pagination.current]);

  const onChange = (pagination) => {
    handleSimulate(pagination.current);
  };

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const statusMap = {
    succeeded: <span className="badge badge-success">Confirmada</span>,
    failed: <span className="badge badge-error">Não autorizada</span>,
    canceled: <span className="badge badge-error">Cancelado</span>,
    waiting: <span className="badge yellow">Aguardando</span>,
    mixed: <span className="badge yellow">Múltiplos Status</span>,
    pre_authorized: <span className="badge yellow">Pré Autorizado</span>,
    reversed: <span className="badge yellow">Invertido</span>,
    pending: <span className="badge orange">Pendente</span>,
    new: <span className="badge blue">Novo</span>,
    created: <span className="badge blue">Novo</span>,
    partial_refunded: <span className="badge blue">Reembolsado Parcial</span>,
    dispute: <span className="badge badge-error">Em disputa</span>,
    chargeback: <span className="badge black">Chargeback</span>
  };

  const renderStatus = (status) => {
    return statusMap[status] || <span className="badge black">Indefinido</span>;
  };

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Data:{" "}
        <p
          dangerouslySetInnerHTML={{ __html: formatDate(record.created_at) }}
        />
      </span>
      <span>
        Tipo:{" "}
        <p
          dangerouslySetInnerHTML={{
            __html: (() => {
              switch (record.payment_type) {
                case "credit":
                  return "Crédito";
                case "debit":
                  return "Débito";
                case "pix":
                  return "Pix";
                case "boleto":
                  return "Boleto";
                default:
                  return "Desconhecido";
              }
            })(),
          }}
        />
      </span>
      <span>
        Bandeira:{" "}
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: record.gateway_authorizer }}
        />
      </span>
      <span>
        Valor bruto:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.amount) }} />
      </span>
      <span>
        MDR:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.fees) }} />
      </span>
      <span>
        Valor líquido:{" "}
        <p
          dangerouslySetInnerHTML={{
            __html: formatPrice(record.original_amount),
          }}
        />
      </span>
      <span>
        Status:{" "}
        {renderStatus(record.status)}
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link onClick={window.scrollTo(0, 0)} to={`/transacao/${record.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
    </div>
  );



  const columns = [
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatDate(confirmed) }}
        />
      ),
      responsive: ["sm"],
    },
    {
      title: "CE",
      dataIndex: "statement_descriptor",
      key: "statement_descriptor",
      sorter: (a, b) => {
        const statusOrder = {
          1: 1,
          0: 2,
        };
        return statusOrder[a.confirmed] - statusOrder[b.confirmed];
      },
    },
    {
      title: "Tipo",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (paymentType) => {
        switch (paymentType) {
          case "credit":
            return "Crédito";
          case "debit":
            return "Débito";
          case "pix":
            return "Pix";
          case "boleto":
            return "Boleto";
          default:
            return "Desconhecido";
        }
      },
      responsive: ["sm"],
    },
    {
      title: "Bandeira",
      dataIndex: "gateway_authorizer",
      key: "gateway_authorizer",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
      responsive: ["xxl"],
    },
    {
      title: "Valor Bruto",
      dataIndex: "amount",
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      key: "amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "MDR",
      dataIndex: "fees",
      key: "fees",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["xxl"],
    },
    {
      title: "Valor Líquido",
      dataIndex: "original_amount",
      key: "original_amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      sorter: (a, b) => {
        const statusOrder = {
          succeeded: 1,
          failed: 2,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      },
      responsive: ["lg"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link onClick={window.scrollTo(0, 0)} to={`/transacao/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "",
      responsive: ["lg"],
    },
  ];

  return (
    <div className='PaymentInfoDetails'>
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <>
              <Breadcrumb
                style={{
                  margin: "16px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  {loading && <Skeleton paragraph={{ rows: 1 }} />}
                  {!loading && <h2 className="title-page">Compra no link - {initialValues?.online_sell?.name}</h2>}
                </div>
                <Divider />
                {loading && <Skeleton paragraph={{ rows: 0 }} />}
                {!loading && (<>
                  <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Pagamento</Breadcrumb.Item>
                  <Breadcrumb.Item>Compra no link - {initialValues?.online_sell?.name}</Breadcrumb.Item>
                </>)}
              </Breadcrumb>
              <Content
                style={{
                  padding: 24,
                  margin: "30px 0 0 0",
                  minHeight: 280,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <div className="container-subtitle">
                  {loading && <Skeleton paragraph={{ rows: 0 }} />}
                  {!loading && <h3 className="subtitle-page">1. Detalhes do comprador</h3>}
                </div>
                <Divider />
                {loading && <Skeleton paragraph={{ rows: 2 }} />}
                {!loading && (
                  <div className="container-inputs">
                    <div className="container-inputs">
                      <div className="container-left">
                        <Form form={form}>
                          <Form.Item
                            label="Nome"
                            name="name"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            label="Telefone"
                            name="phone_number"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                        </Form>
                      </div>
                      <div className="container-right">
                        <Form form={form}>
                          <Form.Item
                            label="Email"
                            name="email"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
              </Content>

              {!loading && <>
                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">2. Detalhes da venda</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-inputs">
                      <div className="container-left">
                        <Form form={form}>
                          <Form.Item
                            label="Valor"
                            name='price'
                          >
                            <InputMoney
                              disabled
                              value={100 || "0,00"}
                              placeholder="R$ 0,00"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Data de Vencimento"
                            name="expiration_date"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                        </Form>
                      </div>
                      <div className="container-right">
                        <Form form={form}>
                          <Form.Item
                            label="Nome"
                            name="name_online_sell"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            label="Referência"
                            name="reference_online_sell"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">3. Transações</h3>
                  </div>
                  <Divider />
                  <Table
                    columns={columns}
                    locale={{ emptyText: <Empty description="Sem dados" /> }}
                    expandable={{
                      expandedRowRender: isMobile ? expandedRowRender : undefined,
                      expandedRowKeys: expandedRowKeys,
                      onExpand: (expanded, record) => handleRowClick(record),
                      responsive: ["xl"],
                    }}
                    dataSource={
                      filteredData &&
                      filteredData.map((item) => ({ ...item, key: item.id }))
                    }
                    pagination={{
                      current: pagination.current,
                      total: pagination.total,
                      pageSize: pagination.pageSize,
                      showSizeChanger: false,
                    }}
                    bordered
                    onChange={onChange}
                  />
                </Content>
              </>}
            </>
          </Layout>
        </>
      )}
    </div>
  )
}

export default PaymentInfoDetails;
