import { baseSiteEndPoint, buildHeaders, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiAccountsList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/unified/accounts/sellers`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccountDetails = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/unified/accounts/sellers/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiAccountEdit = async (id, dataJSON) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/unified/accounts/sellers/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(dataJSON),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};