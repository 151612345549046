import { Breadcrumb, Divider, Input, Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiDetailsSplitPlans } from "../../../api/splitPlans";
import { apiDetailsZoopPlan } from "../../../api/zoopPlan";
import SplitDetailsTable from "./SplitDetailsTable/SplitDetailsTable";
import "./SplitPlanDetails.scss";
const SplitPlanDetails = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const id = parts[parts.length - 1];
  const [dataTransaction, setDataTransaction] = useState();
  const [name, setName] = useState();
  const [plan, setPlan] = useState();
  const [description, setDescription] = useState();
  const [boleto, setBoleto] = useState();
  const [credit, setCredit] = useState();
  const [pix, setPix] = useState();
  const [expirateDate, setExpirateDate] = useState()
  const [date, setDate] = useState()
  const [link, setLink] = useState()
  const [dataZoop, setDataZoop] = useState();
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    if (id) {
      apiDetailsSplitPlans(id).then((json) => {
        setDataTransaction(json)
        setName(json.name)
        setPlan(json.zoop_tax_plan.name)
        setDescription(json.zoop_tax_plan.description)

        apiDetailsZoopPlan(json.zoop_tax_plan.id).then((zoop) => {
          setDataZoop(zoop)
        })
      })
    }
  }, []);
  return (
    <div className="SplitPlanDetails">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h2 className="title-page">{dataTransaction?.name}</h2>
            {/* <Button onClick={() => setEnableEdit(!enableEdit)} type={`${enableEdit ? 'primary' : ''}`}>EDITAR</Button> */}
          </div>
          <Divider />
          <Breadcrumb.Item>Planos e Taxas</Breadcrumb.Item>
          <Breadcrumb.Item>{dataTransaction?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">1. Propriedades gerais</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div>
              <p>Nome</p>
              <Input
                disabled={!enableEdit}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="container-two">
              <div>
                <p>PLANO</p>
                <Input
                  disabled={!enableEdit}
                  onChange={(e) => setPlan(e.target.value)}
                  value={plan}
                />
              </div>
              <div>
                <p>Descrição</p>
                <Input
                  disabled={!enableEdit}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </div>
            {/* <div>
              <p>Descrição</p>
              <Input
                disabled={!enableEdit}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div> */}
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: '30px 0 0 0',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">2. Tabelas de preços</h3>
          </div>
          <Divider />
          {/* <div className="container-inputs">
          </div> */}

          {dataTransaction && dataZoop && <SplitDetailsTable
            splitDetails={dataTransaction?.split_details}
            zoopPlans={dataZoop} />}
        </Content>


        <div className="container-buttons" style={{ marginTop: "30px", display: 'flex', justifyItems: 'space-between', width: '100%' }}>
          {/* <Button onClick={() => deleteContent()} type="primary" danger>Excluir link de pagamento</Button>
          {enableEdit && <Button onClick={() => handleSubmit()} type="primary">Salvar alterações</Button>} */}
        </div>
      </Layout>
    </div >
  );
};

export default SplitPlanDetails;
