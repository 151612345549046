import { getItemFromStorage } from "../helpers/storage";

export const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer token"
};

export const processResponse = response => {
  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const buildHeaders = (authorization = "", contentType = "application/json", accept = "application/json") => {
  const headers = {
    Accept: accept
  };

  if (contentType && contentType !== "") {
    headers["Content-Type"] = contentType;
  }

  const auth = authorization || getItemFromStorage('accessToken');
  if (auth) {
    headers["Authorization"] = `Bearer ${auth}`;
  }

  return headers;
};

export const urlFilters = (url, filters) => {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      const value = filters[key];

      if (value !== "") {
        const symbol = url.includes("?") ? "&" : "?";
        url += `${symbol}${key}=${value}`;
      }
    });
  }
  return url;
};

export const getSellerID = () => {
  return JSON.parse(getItemFromStorage('seller'))?.id;
}

export const clearStorageExceptSeller = () => {
  let seller = null;
  try {
    seller = localStorage.getItem('seller');
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.error("Error clearing storage:", error);
  } finally {
    if (seller) {
      localStorage.setItem('seller', seller);
    }
    window.location.href = "/login";
  }
};




export const baseSiteEndPoint = "https://dl.ngshop.com.br";