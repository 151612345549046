import { Button, Divider, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { countriesList } from '../../../../../helpers/enumsHelper';
import { statesBrazil } from '../../../../../utils/statesBrazil';
import './Responsible.scss';

const { Option } = Select;

const Responsible = ({ data = {}, onSave, onDiscard, setUnsavedChanges, setDataToSave }) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [localData, setLocalData] = useState(data);
  const [originalData, setOriginalData] = useState(data);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const initializedData = {
      person: {
        address: {},
        ...data.person
      },
      ...data
    };
    setLocalData(initializedData);
    setOriginalData(initializedData);
  }, [data]);

  useEffect(() => {
    if (localData) {
      setDataToSave(getChangedFields(localData, originalData));
      validateFields(localData);
    }
  }, [localData, setDataToSave]);

  const validateFields = (data) => {
    const newErrors = {};
    const person = data.person || {};
    const address = person.address || {};

    if (!person.first_name) newErrors.first_name = 'Campo obrigatório';
    if (!person.last_name) newErrors.last_name = 'Campo obrigatório';
    if (!person.email) newErrors.email = 'Campo obrigatório';
    if (!person.mother_name) newErrors.mother_name = 'Campo obrigatório';
    if (!person.birthdate) newErrors.birthdate = 'Campo obrigatório';
    if (!person.phone) newErrors.phone = 'Campo obrigatório';
    if (!person.taxpayer_id) newErrors.taxpayer_id = 'Campo obrigatório';
    if (!address.neighborhood) newErrors.neighborhood = 'Campo obrigatório';
    if (!address.city) newErrors.city = 'Campo obrigatório';
    if (!address.state) newErrors.state = 'Campo obrigatório';
    if (!address.postal_code) newErrors.postal_code = 'Campo obrigatório';
    if (!address.complement) newErrors.complement = 'Campo obrigatório';

    setErrors(newErrors);
    return newErrors;
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (value) => {
    setLocalData(prevData => {
      const newData = { ...prevData };
      newData.person = {
        ...newData.person,
        birthdate: value // Atualiza a data de nascimento
      };
      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [field, nestedField] = name.split('.');

    setLocalData(prevData => {
      const newData = { ...prevData };

      if (nestedField) {
        newData.person = {
          ...newData.person,
          address: {
            ...newData.person?.address,
            [nestedField]: value
          }
        };
      } else {
        newData.person = {
          ...newData.person,
          [field]: value
        };
      }

      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const handleSelectChange = (value, name) => {
    const [field, nestedField] = name.split('.');

    setLocalData(prevData => {
      const newData = { ...prevData };

      if (nestedField) {
        newData.person = {
          ...newData.person,
          address: {
            ...newData.person?.address,
            [nestedField]: value
          }
        };
      } else {
        newData.person = {
          ...newData.person,
          [field]: value
        };
      }

      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const getChangedFields = (currentData, originalData) => {
    const changedFields = {};

    const checkChanges = (current, original, path = '') => {
      for (const key in current) {
        if (current[key] !== original[key]) {
          if (typeof current[key] === 'object' && current[key] !== null) {
            checkChanges(current[key], original[key] || {}, `${path}${key}.`);
          } else {
            changedFields[`${path}${key}`] = current[key];
          }
        }
      }
    };

    checkChanges(currentData, originalData);
    return changedFields;
  };

  const handleSave = async () => {
    const newErrors = validateFields(localData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const changes = getChangedFields(localData, originalData);
      console.log('Changed Fields:', changes);

      if (Object.keys(changes).length > 0) {
        await onSave(changes);
        setOriginalData(localData);
      }
      setEnableEdit(false);
      setUnsavedChanges(false);
    } else {
      alert('Por favor, corrija os campos obrigatórios.');
    }
  };

  const handleDiscard = async () => {
    await onDiscard();
    setEnableEdit(false);
    setUnsavedChanges(false);
  };

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  return (
    <div className='Responsible'>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className='subtitle-page'>Responsável</p>
        <Button
          onClick={() => setEnableEdit(!enableEdit)}
          type={enableEdit ? "primary" : ""}
        >
          {enableEdit ? "Cancelar" : "Editar"}
        </Button>
      </div>
      <Divider />
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item
              label="Primeiro Nome"
              help={errors.first_name}
              validateStatus={errors.first_name ? 'error' : ''}
            >
              <Input
                name="first_name"
                disabled={!enableEdit}
                placeholder='Primeiro Nome'
                value={localData?.person?.first_name || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Segundo Nome"
              help={errors.last_name}
              validateStatus={errors.last_name ? 'error' : ''}
            >
              <Input
                name="last_name"
                disabled={!enableEdit}
                placeholder='Segundo Nome'
                value={localData?.person?.last_name || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="CPF"
              help={errors.taxpayer_id}
              validateStatus={errors.taxpayer_id ? 'error' : ''}
            >
              <Input
                name="taxpayer_id"
                disabled={!enableEdit}
                placeholder='CPF'
                value={formatCPF(localData?.person?.taxpayer_id || "")}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Data de Nascimento"
              help={errors.birthdate}
              validateStatus={errors.birthdate ? 'error' : ''}
            >
              <input
                type="date"
                name="birthdate"
                id='birthdate'
                disabled={!enableEdit}
                value={localData?.person?.birthdate || ""}
                onChange={(e) => handleDateChange(e.target.value)} // Passa o valor diretamente
              />
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item
              label="Telefone"
              help={errors.phone}
              validateStatus={errors.phone ? 'error' : ''}
            >
              <Input
                name="phone"
                disabled={!enableEdit}
                placeholder='Telefone'
                value={localData?.person?.phone || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              help={errors.email}
              validateStatus={errors.email ? 'error' : ''}
            >
              <Input
                name="email"
                disabled={!enableEdit}
                placeholder='Email'
                value={localData?.person?.email || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Nome da mãe"
              help={errors.mother_name}
              validateStatus={errors.mother_name ? 'error' : ''}
            >
              <Input
                name="mother_name"
                disabled={!enableEdit}
                placeholder='Nome da mãe'
                value={localData?.person?.mother_name || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Divider />
      <p className='subtitle-page'>Endereço</p>
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item
              label="CEP"
              help={errors.postal_code}
              validateStatus={errors.postal_code ? 'error' : ''}
            >
              <Input
                name="address.postal_code"
                disabled={!enableEdit}
                placeholder='CEP'
                value={localData?.person?.address?.postal_code || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Número"
              help={errors.number}
              validateStatus={errors.number ? 'error' : ''}
            >
              <Input
                name="address.number"
                disabled={!enableEdit}
                placeholder='Número'
                value={localData?.person?.address?.number || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Bairro"
              help={errors.neighborhood}
              validateStatus={errors.neighborhood ? 'error' : ''}
            >
              <Input
                name="address.neighborhood"
                disabled={!enableEdit}
                placeholder='Bairro'
                value={localData?.person?.address?.neighborhood || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Estado"
              help={errors.state}
              validateStatus={errors.state ? 'error' : ''}
            >
              <Select
                name="address.state"
                disabled={!enableEdit}
                placeholder="Selecione o Estado"
                value={localData?.person?.address?.state || ''}
                onChange={(value) => handleSelectChange(value, 'address.state')}
              >
                {Object.entries(statesBrazil).map(([key, label]) => (
                  <Option key={key} value={key}>{label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item
              label="Rua"
              help={errors.street}
              validateStatus={errors.street ? 'error' : ''}
            >
              <Input
                name="address.street"
                disabled={!enableEdit}
                placeholder='Rua'
                value={localData?.person?.address?.street || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Complemento"
              help={errors.complement}
              validateStatus={errors.complement ? 'error' : ''}
            >
              <Input
                name="address.complement"
                disabled={!enableEdit}
                placeholder='Complemento'
                value={localData?.person?.address?.complement || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Cidade"
              help={errors.city}
              validateStatus={errors.city ? 'error' : ''}
            >
              <Input
                name="address.city"
                disabled={!enableEdit}
                placeholder='Cidade'
                value={localData?.person?.address?.city || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="País"
              help={errors.country_code}
              validateStatus={errors.country_code ? 'error' : ''}
            >
              <Select
                name="address.country_code"
                disabled={!enableEdit}
                placeholder='País'
                value={localData?.person?.address?.country_code || ""}
                onChange={(value) => handleSelectChange(value, 'address.country_code')}
              >
                {Object.entries(countriesList).map(([key, label]) => (
                  <Option key={key} value={key}>{label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
      {enableEdit &&
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button onClick={handleSave} type="primary">Salvar</Button>
          <Button onClick={handleDiscard}>Descartar</Button>
        </div>
      }
    </div>
  );
};

export default Responsible;
