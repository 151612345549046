import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiPayCharge } from '../../../api/checkout';
import { apiSimulationTax } from '../../../api/simulation';
import { formatPrice } from '../../../utils/format';
import { useCpfInput } from '../../../utils/formHooks';
import { searchPostalCode } from '../../../utils/searchPostalCode';
import { validateCardNumber, validateCEP, validateEmail, validateName, validatePhone, validateTaxpayerID } from '../../../utils/validatesCheckout';
import ExpiryDateSelector from '../ExpiryDateSelector/ExpiryDateSelector';
import InputCheckout from '../InputCheckout/InputCheckout';
import StateSelect from '../StateSelect/StateSelect';
import './CheckoutForm.scss';

const CheckoutForm = ({ infoProduct }) => {
  const [paymentType, setPaymentType] = useState('');
  const [cpf, handleCpfChange, validCPF] = useCpfInput("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    ccNumber: '',
    ccName: '',
    ccCvv: '',
    taxpayer: '',
    addressCep: '',
    addressLine1: '',
    addressNumber: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressComplement: '',
  });

  const [errors, setErrors] = useState({});
  const [installments, setInstallments] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [returnPix, setReturnPix] = useState(false);
  const [returnBoleto, setReturnBoleto] = useState(false);
  const [returnCard, setReturnCard] = useState(false);
  const [errorPurchase, setErrorPurchase] = useState(false);

  useEffect(() => {
    if (infoProduct) {
      fetchInstallments(infoProduct.max_installments, infoProduct.max_installments_no_tax, infoProduct.sale_price, infoProduct.sale_seller_id);
    }
  }, [infoProduct]);

  const getInstallmentValue = (data, installment, buyerLiable) => {
    let installments = data[buyerLiable ? "buyer_liable" : "seller_liable"]["credit"]
    installment = installments[installment]
    let maxValue = {}
    for (let brand in installment) {
      let value = installment[brand]
      if (!parseFloat(maxValue.amount) || parseFloat(value.amount) > parseFloat(maxValue.amount)) {
        maxValue = value
      }
    }
    return maxValue
  }

  const fetchInstallments = async (max_installments, max_installments_no_tax, sale_price, sale_seller_id) => {
    try {
      const data = await apiSimulationTax(sale_price.replace(",", "."), sale_seller_id);
      const newInstallments = [];

      for (let i = 0; i < parseInt(max_installments); i++) {
        const hasTax = (i + 1) > parseInt(max_installments_no_tax);
        const value = getInstallmentValue(data, i + 1, hasTax);
        const installmentFormatted = (parseFloat(value.amount) / (i + 1)).toFixed(2).replace(".", ",");

        newInstallments.push({
          text: `${i + 1}x de R$ ${installmentFormatted} ${hasTax ? "(com juros)" : "(sem juros)"}`,
          installments: i + 1
        });
      }

      setInstallments(newInstallments);
    } catch (error) {
      console.error('Erro ao buscar parcelas', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (installments.length > 0) {
      const firstInstallment = installments[0].installments;
      setSelectedInstallment(firstInstallment);
    }
  }, [installments, setSelectedInstallment]);

  const validate = () => {
    const newErrors = {};
    // Validação de nome
    const nameError = validateName(formData?.customerName);
    if (nameError) {
      newErrors.customerName = nameError;
    }

    // Validação do e-mail
    const emailError = validateEmail(formData?.customerEmail);
    if (emailError) {
      newErrors.customerEmail = emailError;
    }

    // Validação do telefone
    const phoneError = validatePhone(formData?.customerPhone);
    if (phoneError) {
      newErrors.customerPhone = phoneError;
    }

    if (paymentType === 'card') {
      const ccNameError = validateName(formData?.ccName);
      if (ccNameError) {
        newErrors.ccName = ccNameError;
      }
      if (!formData.ccCvv) newErrors.ccCvv = 'Esse campo é obrigatório';

      const ccNumberError = validateCardNumber(formData?.ccNumber);
      if (ccNumberError) {
        newErrors.ccNumber = ccNumberError;
      }
      if (selectedInstallment === null) newErrors.installments = 'Esse campo é obrigatório';
    }

    if (paymentType === 'boleto') {
      const taxpayerError = validateTaxpayerID(formData?.taxpayer);
      if (taxpayerError) {
        newErrors.taxpayer = taxpayerError;
      }

      const cepError = validateCEP(formData?.postalcode);
      if (cepError) {
        newErrors.postalcode = cepError;
      }

      if (!formData.addressCep) newErrors.addressCep = 'Esse campo é obrigatório';
      if (!formData.addressLine1) newErrors.addressLine1 = 'Esse campo é obrigatório';
      if (!formData.addressNumber) newErrors.addressNumber = 'Esse campo é obrigatório';
      if (!formData.addressNeighborhood) newErrors.addressNeighborhood = 'Esse campo é obrigatório';
      if (!formData.addressCity) newErrors.addressCity = 'Esse campo é obrigatório';
      if (!formData.addressState) newErrors.addressState = 'Esse campo é obrigatório';
    }

    if (paymentType === 'pix') {
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      setIsDisabled(true);
      if (paymentType === 'card') {
        const payload = {
          email: formData.customerEmail,
          phone: formData.customerPhone,
          name: formData.customerName,
          type: 'credit_card',
          installments: selectedInstallment,
          card: {
            number: formData.ccNumber,
            name: formData.ccName,
            cvv: formData.ccCvv,
            expirationMonth: selectedMonth,
            expirationYear: selectedYear
          }
        }
        setLoading(true);
        apiPayCharge(infoProduct.sale_id, payload).then((data) => {
          setLoading(false)
          if (data?.acquirerError || data?.payment?.status !== "succeeded") {
            setErrorPurchase(true)
            return;
          }
          setReturnCard(data)
        }).catch(() => {
          setErrorPurchase(true)
          setLoading(false)
        })
      }

      if (paymentType === 'pix') {
        const payload = {
          email: formData.customerEmail,
          phone: formData.customerPhone,
          name: formData.customerName,
          type: 'pix',
        }
        setLoading(true);
        apiPayCharge(infoProduct.sale_id, payload).then((data) => {
          setReturnPix(data)
          setLoading(false);
        }).catch(() => {
          setErrorPurchase(true)
          setLoading(false)
        })
      }

      if (paymentType === 'boleto') {
        const payload = {
          email: formData.customerEmail,
          phone: formData.customerPhone,
          name: formData.customerName,
          type: 'boleto',
          taxpayerId: formData.taxpayer,
          address: {
            line1: formData.addressLine1,
            line2: formData.addressNumber,
            city: formData.addressCity,
            state: formData.addressState,
            postalCode: formData.addressCep.replace(/-/g, ''),
            neighborhood: formData.addressNeighborhood,
          }
        }
        setLoading(true);
        apiPayCharge(infoProduct.sale_id, payload).then((data) => {
          setReturnBoleto(data.payment)
          setLoading(false);
        }).catch(() => {
          setErrorPurchase(true)
          setLoading(false)
        })
      }
    }
  };
  const [validFields, setValidFields] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = '';
    let isValid = true;

    // INITIAL FORMS VALIDATIONS
    if (name === 'customerName') {
      error = validateName(value);
      isValid = error === '';
    }

    if (name === 'customerPhone') {
      error = validatePhone(value);
      isValid = error === '';
    }

    if (name === 'customerEmail') {
      error = validateEmail(value);
      isValid = error === '';
    }
    // INITIAL FORMS VALIDATIONS

    // CARD VALIDATIONS
    if (name === 'ccName') {
      error = validateName(value);
      isValid = error === '';
    }

    if (name === 'ccNumber') {
      error = validateCardNumber(value);
      isValid = error === '';
    }

    if (name === 'taxpayer') {
      error = validateTaxpayerID(value);
      isValid = error === '';
    }

    //BOLETO VALIDATIONS
    if (name === 'postalcode') {
      error = validateCEP(value);
      if (error === '') {
        setPostalCodeData(null);
        checkPostalCode(value);
      }
      isValid = error === '';
    }

    if (value.trim() === '' && !error) {
      error = 'Este campo é obrigatório';
      isValid = false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setValidFields((prevValid) => ({
      ...prevValid,
      [name]: isValid,
    }));
  };

  const [selectedMonth, setSelectedMonth] = useState('01');
  const [selectedYear, setSelectedYear] = useState('24');

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  // CEP VALIDATOR
  const [postalCodeData, setPostalCodeData] = useState(null);

  const checkPostalCode = (number) => {
    searchPostalCode(number, setPostalCodeData)
  }

  const setFieldValidity = (fieldName, isValid) => {
    setValidFields(prevValid => ({
      ...prevValid,
      [fieldName]: isValid
    }));
  };
  useEffect(() => {
    if (postalCodeData && !postalCodeData?.erro) {
      handleChange({ target: { name: 'addressLine1', value: postalCodeData?.logradouro } });
      handleChange({ target: { name: 'addressCity', value: postalCodeData?.localidade } });
      handleChange({ target: { name: 'addressNeighborhood', value: postalCodeData?.bairro } });
      handleChange({ target: { name: 'addressState', value: postalCodeData?.uf } });
      handleChange({ target: { name: 'addressCep', value: postalCodeData?.cep } });
      // Set fields as valid
      setFieldValidity('addressNeighborhood', true);
      setFieldValidity('addressState', true);
      setFieldValidity('addressCity', true);
      setFieldValidity('addressLine1', true);
    }
  }, [postalCodeData]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(returnPix.payment.pix.copy_paste);
      notification.success({
        message: "Sucesso",
        description: "Código copiado com sucesso!",
        placement: "topRight",
      });
    } catch (err) {
      notification.error({
        message: "Erro",
        description: "Falha ao copiar o código.",
        placement: "topRight",
      });
    }
  };

  // ERROR PURCHASE
  function CircleAlertIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" x2="12" y1="8" y2="12" />
        <line x1="12" x2="12.01" y1="16" y2="16" />
      </svg>
    );
  }

  function OrderUnsuccessful() {
    return (
      <div className="OrderUnsuccessful">
        <div className="content">
          <CircleAlertIcon className="icon" />
          <h1 className="title">Pedido malsucedido</h1>
          <p className="message">
            Lamentamos, mas houve um problema ao processar seu pagamento. Tente novamente ou entre em contato com o suporte ao cliente se o problema persistir.
          </p>
          <div className="actions">
            <button onClick={() => window.location.reload()} className="primary">Tentar novamente</button>
            <button className="secondary">Suporte</button>
          </div>
        </div>
      </div>
    );
  }

  const PaymentSuccess = () => {
    return (
      <div className="PaymentSuccess">
        <div className="content">
          <CircleCheckIcon className="icon" />
          <h1 className="title">Pagamento bem-sucedido!</h1>
          <p className="message">
            Obrigado pela sua compra. Seu pedido foi processado com sucesso.
          </p>
          <div className="order-info">
            <div className="total">{formatPrice(infoProduct?.sale_price)}</div>
          </div>
        </div>
      </div>
    );
  };

  const CircleCheckIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m9 12 2 2 4-4" />
    </svg>
  );

  return (
    <React.Fragment>
      {
        !returnPix && !returnBoleto && !errorPurchase && !returnCard &&
        (<div className="CheckoutForm">
          <h2>Seus dados</h2>
          <form id="checkout-form" onSubmit={handleSubmit}>
            <div className="container-data">
              <div className="row">
                <InputCheckout
                  disabled={isDisabled}
                  label="Nome"
                  name="customerName"
                  value={formData.customerName}
                  onChange={handleChange}
                  errorMessage={errors.customerName}
                  isValid={validFields.customerName}
                />
              </div>
              <div className="row">
                <InputCheckout
                  disabled={isDisabled}
                  label="E-mail"
                  name="customerEmail"
                  type='email'
                  className="small"
                  value={formData.customerEmail}
                  onChange={handleChange}
                  errorMessage={errors.customerEmail}
                  isValid={validFields.customerEmail}
                />
                <InputCheckout
                  disabled={isDisabled}
                  label="Telefone"
                  name="customerPhone"
                  className="small"
                  value={formData.customerPhone}
                  onChange={handleChange}
                  errorMessage={errors.customerPhone}
                  isValid={validFields.customerPhone}
                />
              </div>
            </div>
            <div className="payment-type-container">
              <h2>Forma de Pagamento</h2>
              <div className="payment_buttons">
                {infoProduct?.has_credit && <button type="button" onClick={() => setPaymentType('card')} className={`payment_type_button ${paymentType === 'card' ? 'active' : ''}`}>Cartão de Crédito</button>}
                {infoProduct?.has_boleto && <button type="button" onClick={() => setPaymentType('boleto')} className={`payment_type_button ${paymentType === 'boleto' ? 'active' : ''}`}>Boleto</button>}
                {infoProduct?.has_pix && <button type="button" onClick={() => setPaymentType('pix')} className={`payment_type_button ${paymentType === 'pix' ? 'active' : ''}`}>PIX</button>}
              </div>
            </div>
            {paymentType === 'card' && <div className="payment_form">
              <InputCheckout
                disabled={isDisabled}
                label="Número do cartão"
                name="ccNumber"
                // className='small'
                value={formData.ccNumber}
                onChange={handleChange}
                errorMessage={errors.ccNumber}
                isValid={validFields.ccNumber} />
              {/* <InputCheckout
               disabled={isDisabled}
                label="Número do cartão"
                name="ccNumber"
                value={ccNumber}
                onChange={handleChangeCC}
                onBlur={handleBlurCC}
                errorMessage={errorCC}
                isValid={isValidCC}
              /> */}

              <div className="row">
                <ExpiryDateSelector
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                  onMonthChange={handleMonthChange}
                  onYearChange={handleYearChange}
                />
                <InputCheckout
                  disabled={isDisabled}
                  label="CVV"
                  name="ccCvv"
                  className='small'
                  value={formData.ccCvv}
                  maxLength={4}
                  onChange={handleChange}
                  errorMessage={errors.ccCvv}
                  isValid={validFields.ccCvv} />
              </div>

              <InputCheckout
                disabled={isDisabled}
                label="Nome do Portador"
                name="ccName"
                value={formData.ccName}
                onChange={handleChange}
                errorMessage={errors.ccName}
                isValid={validFields.ccName}
              />
              <div className="installments-container">
                <label htmlFor="installments-select">Parcelas</label>
                <select
                  id="installments-select"
                  disabled={loading}
                  onChange={(e) => {
                    setSelectedInstallment(e.target.value)
                  }}
                >
                  {installments.map((installment) => (
                    <option key={installment.installments} value={installment.installments}>
                      {installment.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>}

            {/* BOLETO */}
            {paymentType === 'boleto' && <div className="payment_form">
              <div class="payment__redirect">
                O Banco solicita algumas informações extras para a geração do boleto.
              </div>
              <InputCheckout
                disabled={isDisabled}
                label="CPF ou CNPJ"
                name="taxpayer"
                value={formData.taxpayer}
                onChange={handleChange}
                errorMessage={errors.taxpayer}
                isValid={validFields.taxpayer}
              />
              <InputCheckout
                disabled={isDisabled}
                label="CEP"
                name="postalcode"
                value={formData.postalcode}
                onChange={handleChange}
                errorMessage={errors.postalcode}
                isValid={validFields.postalcode}
                maxLength={9}
              />

              <InputCheckout
                disabled={isDisabled}
                label="Endereço"
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleChange}
                errorMessage={errors.addressLine1}
                isValid={validFields.addressLine1}
              />

              <div className="row">
                <InputCheckout
                  disabled={isDisabled}
                  label="Número"
                  name="addressNumber"
                  className="small"
                  value={formData.addressNumber}
                  onChange={handleChange}
                  errorMessage={errors.addressNumber}
                  isValid={validFields.addressNumber}
                />
                <InputCheckout
                  disabled={isDisabled}
                  label="Complemento"
                  name="addressComplement"
                  className="small"
                  value={formData.addressComplement}
                  onChange={handleChange}
                  isValid={formData.addressComplement ? true : ''}
                />
              </div>

              <InputCheckout
                disabled={isDisabled}
                label="Bairro"
                name="addressNeighborhood"
                value={formData.addressNeighborhood}
                onChange={handleChange}
                errorMessage={errors.addressNeighborhood}
                isValid={validFields.addressNeighborhood}
              />

              <div className="row">
                <InputCheckout
                  disabled={isDisabled}
                  label="Cidade"
                  name="addressCity"
                  className="small"
                  value={formData.addressCity}
                  onChange={handleChange}
                  errorMessage={errors.addressCity}
                  isValid={validFields.addressCity}
                />

                <StateSelect
                  label="Estado"
                  name="addressState"
                  value={formData.addressState}
                  onChange={handleChange}
                  errorMessage={errors.addressState}
                  isValid={validFields.addressState}
                />
              </div>

            </div>}

            {paymentType === 'pix' && <div className="payment_form">
              <div class="payment__redirect">
                Ao clicar em "Pagar" você será redirecionado para o QRCode do PIX.
              </div>
            </div>}

            <div className="payment-container">
              {loading ?
                <div className="loading-div">
                  <span className="loader-text">Processando...</span>
                  <span className="loader"></span>
                </div>
                : <button onClick={(e) => handleSubmit(e)}>PAGAR</button>}
            </div>
          </form>
        </div>)
      }

      {/* // PIX */}
      {returnPix &&
        <React.Fragment>
          <div className='pix-feedback'>
            <h2>Seu QRCode está pronto!</h2>
            <img src={returnPix.payment.pix.qr_code} alt="QR Code" />
            <h3>Pix Copia e Cola</h3>
            <div className='pix-copy-paste'>
              <div className='pix-code'>{returnPix.payment.pix.copy_paste}</div>
              <button onClick={handleCopy}>Copiar</button>
            </div>
          </div>
        </React.Fragment>
      }

      {/* // BOLETO */}
      {returnBoleto &&
        <React.Fragment>
          <div className='boleto-feedback'>
            <h2>Visualizar Boleto</h2>
            <div>
              <button onClick={() => window.open(returnBoleto?.boleto?.url, '_blank')}>Visualizar página</button>
            </div>
            <iframe
              src={returnBoleto?.boleto?.url}
              width="100%"
              height="600px"
              title="Boleto"
              style={{ border: 'none' }}
            />
          </div>
        </React.Fragment>
      }
      {returnCard && <PaymentSuccess />}
      {errorPurchase && <OrderUnsuccessful />}
    </React.Fragment>

  );
};

export default CheckoutForm;
