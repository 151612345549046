import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiGetEventNoAuth } from '../../../../api/events';
import { apiGetCheckout } from '../../../../api/ticket';
import logo from "../../../../assets/images/logo.png";
import { ReactComponent as Check } from "../../../../assets/images/response/check.svg";
import { ReactComponent as Expired } from "../../../../assets/images/response/expired.svg";
import { ReactComponent as LoadingPix } from "../../../../assets/images/response/loading-pix.svg";
import ResponseExpired from './ResponseExpired/ResponseExpired';
import ResponsePix from './ResponsePix/ResponsePix';
import ResponseSuccess from './ResponseSuccess/ResponseSuccess';
import './ResponseTicket.scss';

const ResponseTicket = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventID, checkoutID } = useParams();
  const { initial, card, pix } = location.state || {};
  const [event, setEvent] = useState();
  const [dataTicket, setDataTicket] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getEventDetails = async () => {
    try {
      const [eventData, ticketData] = await Promise.all([
        apiGetEventNoAuth(eventID),
        apiGetCheckout(eventID, checkoutID)
      ]);
      setEvent(eventData);
      setDataTicket(ticketData);
      setData(ticketData);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!event && !data) {
      getEventDetails()
    }

    if (data?.status === 'PENDING' && !pix) {
      navigate(`/evento/checkout/${eventID}/${checkoutID}`);
    }
  }, [eventID, checkoutID, data]);


  const ErrorPage = () => {
    return (
      <div className="ErrorPage">
        <div className="content">
          <TriangleAlertIcon className="icon" />
          <h1 className="title">Oops, link inválido!!</h1>
          <p className="message">
            Parece que o ID de pagamento ou o evento que você está procurando não existe. Verifique os detalhes e tente novamente.
          </p>
        </div>
      </div>
    );
  };

  const TriangleAlertIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  );

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div className='ResponseTicket'>
          <div style={{ width: '100%', height: '66px', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ height: '100%' }} />
          </div>
          {!loading && <div className="container-header">
            <div className="response-header">
              <div className="infos-header">
                {data?.status === 'FREE' && <h2>Pedido efetuado com sucesso!</h2>}
                {data?.status === 'PENDING' && <h2>Pedido iniciado</h2>}
                {data?.status === 'EXPIRED' && <h2>Pedido Expirado</h2>}
                <div className='container-infos'>
                  <div className='info'>
                    <p>nº do pedido</p>
                    <p>{event.id}</p>
                  </div>
                  {(data?.status === 'FREE' || data?.status === 'succeeded' || card?.status === 'PAID' ||  data?.status === 'PAID') &&
                    <div className="info-proccess free">
                      <Check />
                      <p>Confirmado</p>
                    </div>
                  }
                  {data?.status === 'PENDING' &&
                    <div className="info-proccess">
                      <LoadingPix />
                      <p>Aguardando pagamento</p>
                    </div>
                  }
                  {data?.status === 'EXPIRED' &&
                    <div className="info-proccess expired">
                      <Expired />
                      <p>Pagamento Expirado</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>}
          {loading &&
            <div className="container-loading">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="rgb(98, 190, 39)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path stroke-dasharray="2 4" stroke-dashoffset="6" d="M12 21c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9"><animate attributeName="stroke-dashoffset" dur="0.6s" repeatCount="indefinite" values="6;0" /></path><path stroke-dasharray="32" stroke-dashoffset="32" d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.1s" dur="0.4s" values="32;0" /></path><path stroke-dasharray="10" stroke-dashoffset="10" d="M12 16v-7.5"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="10;0" /></path><path stroke-dasharray="6" stroke-dashoffset="6" d="M12 8.5l3.5 3.5M12 8.5l-3.5 3.5"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0" /></path></g></svg>
              <p>Carregando informações do pedido...</p>
            </div>}
          {data?.status === 'EXPIRED' && <ResponseExpired data={data} event={event} dataTicket={dataTicket} />}
          {data?.status === 'PENDING' && <ResponsePix pix={pix} data={data} event={event} dataTicket={dataTicket} />}
          {(data?.status === 'FREE' || card?.status === 'PAID' || data?.status === 'succeeded' || data?.status === 'PAID') && <ResponseSuccess data={data} event={event} dataTicket={dataTicket} />}
        </div >)}
    </>
  )
}

export default ResponseTicket
