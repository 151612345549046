import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Layout,
  Result,
  Skeleton,
  Table,
  notification,
  theme
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiTransactionsDisputesList } from "../../../api/transactions";
import { useIsMobileHook } from "../../../utils/WindowHooks";
import { formatDate, formatPrice } from "../../../utils/format";
import SellerDropdown from "../../common/SellerDropdown/SellerDropdown";
import "./DisputeManagement.scss";

const { Content } = Layout;

const DisputeManagement = () => {
  const [dataDispute, setDataDispute] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1600);
  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataDispute?.results);

  const navigate = useNavigate();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setFilteredData(dataDispute.results);
    } else {
      const filtered = dataDispute?.results?.filter((item) =>
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  };
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const handleSimulate = async (page) => {
    setIsFetching(true);
    try {
      const json = await apiTransactionsDisputesList({ page });
      setDataDispute(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar as vendas",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar as vendas Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("500");
        notification.error("Erro ao buscar as vendas");
      }
    }
  };

  const onChange = (pagination) => {
    handleSimulate(pagination.current);
  };
  useEffect(() => {
    if (dataDispute?.length <= 1) {
      handleSimulate(pagination.current);
    }
  }, []);

  const columns = [
    {
      title: "Data",
      dataIndex: "expected_on",
      key: "expected_on",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatDate(confirmed) }}
        />
      ),
      responsive: ["sm"],
    },
    {
      title: "CE",
      dataIndex: "statement_descriptor",
      key: "statement_descriptor",
      sorter: (a, b) => {
        const statusOrder = {
          1: 1,
          0: 2,
        };
        return statusOrder[a.confirmed] - statusOrder[b.confirmed];
      },
    },
    {
      title: "Tipo",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (paymentType) => {
        switch (paymentType) {
          case "credit":
            return "Crédito";
          case "debit":
            return "Débito";
          case "pix":
            return "Pix";
          case "boleto":
            return "Boleto";
          default:
            return "Desconhecido";
        }
      },
      responsive: ["sm"],
    },
    {
      title: "Bandeira",
      dataIndex: "gateway_authorizer",
      key: "gateway_authorizer",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
      responsive: ["xxl"],
    },
    {
      title: "Valor Bruto",
      dataIndex: "amount",
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      key: "amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "MDR",
      dataIndex: "fees",
      key: "fees",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["xxl"],
    },
    {
      title: "Valor Líquido",
      dataIndex: "original_amount",
      key: "original_amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          dangerouslySetInnerHTML={{
            __html:
              status === "succeeded"
                ? '<span class="badge badge-success">Confirmada</span>'
                : status === "failed"
                  ? '<span class="badge badge-error">Não autorizada</span>'
                  : status === "canceled"
                    ? '<span class="badge badge-error">Cancelado</span>'
                    : status === "pre_authorized"
                      ? '<span class="badge yellow">Pré Autorizado</span>'
                      : status === "reversed"
                        ? '<span class="badge yellow">Invertido</span>'
                        : status === "pending"
                          ? '<span class="badge orange">Pendente</span>'
                          : status === "new"
                            ? '<span class="badge blue">Novo</span>'
                            : status === "created"
                              ? '<span class="badge blue">Novo</span>'
                              : status === "partial_refunded"
                                ? '<span class="badge blue">Reembolsado Parcial</span>'
                                : status === "dispute"
                                  ? '<span class="badge badge-error">Em disputa</span>'
                                  : '<span class="badge black">Chargeback</span>',
          }}
        />
      ),
      sorter: (a, b) => {
        const statusOrder = {
          succeeded: 1,
          failed: 2,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      },
      responsive: ["lg"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link to={`/transacao/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "",
      responsive: ["lg"],
    },
  ];

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Data:{" "}
        <p
          dangerouslySetInnerHTML={{ __html: formatDate(record.expected_on) }}
        />
      </span>
      <span>
        Tipo:{" "}
        <p
          dangerouslySetInnerHTML={{
            __html: (() => {
              switch (record.payment_type) {
                case "credit":
                  return "Crédito";
                case "debit":
                  return "Débito";
                case "pix":
                  return "Pix";
                case "boleto":
                  return "Boleto";
                default:
                  return "Desconhecido";
              }
            })(),
          }}
        />
      </span>
      <span>
        Bandeira:{" "}
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: record.gateway_authorizer }}
        />
      </span>
      <span>
        Valor bruto:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.amount) }} />
      </span>
      <span>
        MDR:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.fees) }} />
      </span>
      <span>
        Valor líquido:{" "}
        <p
          dangerouslySetInnerHTML={{
            __html: formatPrice(record.original_amount),
          }}
        />
      </span>
      <span>
        Status:{" "}
        <span
          dangerouslySetInnerHTML={{
            __html:
              record.status === "succeeded"
                ? '<span class="badge badge-success">Confirmada</span>'
                : record.status === "failed"
                  ? '<span class="badge badge-error">Não autorizada</span>'
                  : record.status === "canceled"
                    ? '<span class="badge badge-error">Cancelado</span>'
                    : record.status === "pre_authorized"
                      ? '<span class="badge yellow">Pré Autorizado</span>'
                      : record.status === "reversed"
                        ? '<span class="badge yellow">Invertido</span>'
                        : record.status === "pending"
                          ? '<span class="badge orange">Pendente</span>'
                          : record.status === "new"
                            ? '<span class="badge blue">Novo</span>'
                            : record.status === "partial_refunded"
                              ? '<span class="badge blue">Reembolsado Parcial</span>'
                              : record.status === "dispute"
                                ? '<span class="badge badge-error">Em disputa</span>'
                                : '<span class="badge black">Chargeback</span>',
          }}
        />
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/transacao/${record.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
    </div>
  );

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  return (
    <div className="DisputeManagement">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <h2 className="title-page">Transações sob disputa</h2>
            <SellerDropdown />
          </div>
          <Divider />
          <Breadcrumb.Item>Transações sob disputa</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className="subtitle-page">Transações sob disputa</h3>
          <Divider />
          {/*<Input.Search*/}
          {/*  placeholder='Pesquisar'*/}
          {/*  allowClear*/}
          {/*  enterButton*/}
          {/*  onSearch={handleSearch}*/}
          {/*  onChange={(e) => handleSearch(e.target.value)}*/}
          {/*  style={{ marginBottom: 16, maxWidth: '200px' }}*/}
          {/*/>*/}
          {isFetching ? (
            <Skeleton active />
          ) : activeError ? (
            renderError(errorStatus)
          ) : (
            <Table
              columns={columns}
              locale={locale}
              expandable={{
                expandedRowRender: isMobile ? expandedRowRender : undefined,
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => handleRowClick(record),
                responsive: ["xl"],
              }}
              dataSource={
                filteredData &&
                filteredData?.map((item, index) => ({ ...item, key: item.id }))
              }
              pagination={{
                current: pagination.current,
                total: pagination.total,
                showSizeChanger: false,
              }}
              bordered
              onChange={onChange}
            />
          )}
        </Content>
        <Divider />
      </Layout>
    </div>
  );
};

export default DisputeManagement;
