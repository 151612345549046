import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Form,
  Layout,
  Result,
  Skeleton,
  Table,
  message,
  notification,
  theme
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  apiApiKeyList,
  apiCreateApiKey,
  apiDeleteApiKey,
  apiEditApiKey,
} from "../../../api/apikey";
import SellerDropdown from "../../common/SellerDropdown/SellerDropdown";
import ApiKeyCopyModal from "./ApiKeyCopyModal/ApiKeyCopyModal ";
import "./ApiKeyList.scss";
import ApiKeyListModal from "./ApiKeyListModal/ApiKeyListModal";

const { Content } = Layout;

const ApiKeyList = () => {
  const [dataSales, setDataSale] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSales);
  const [webhookInfo, setWebhookInfo] = useState();

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setFilteredData(dataSales.results);
    } else {
      const filtered = dataSales.results?.filter((item) =>
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  };
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const handleListApiKeys = async (page) => {
    setLoading(true);
    try {
      const json = await apiApiKeyList({ page });
      setDataSale(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listas as apikeys!",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar as apikeys. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      }
    }
  };

  const onChange = (pagination) => {
    handleListApiKeys(pagination.current);
  };

  useEffect(() => {
    if (dataSales?.length <= 1) {
      handleListApiKeys(pagination.current);
    }
  }, []);
  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      notification.success({
        message: "Sucesso",
        description: "Link copiado com sucesso!",
        placement: "topRight",
      });
    });
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
    },
    {
      title: "Ativo",
      dataIndex: "active",
      key: "active",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p>{confirmed === true ? "Ativo" : "Desativado"}</p>
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link
          onClick={() => {
            showModal(confirme);
          }}
        >
          Editar
        </Link>
      ),
      key: "",
    },
  ];

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (info) => {
    setIsModalVisible(true);
    info ? setWebhookInfo(info) : setWebhookInfo();
  };

  const [form] = Form.useForm();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  const handleCopyKey = (key) => {
    setApiKey(key);
    setCopyModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setLoadingCreate(true);
      const values = await form.validateFields(["name", "description"]);
      const key = await apiCreateApiKey(values);
      if (key) {
        notification.success({ message: "ApiKey criado com sucesso!" });
        form.resetFields();
        handleListApiKeys(1);
        handleCopyKey(key);
        setWebhookInfo();
        setIsModalVisible(false);
      }
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao criar a apikey! Preencha todos os campos de informações necessárias.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao criar a apikey. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        message.error("Por favor, preencha todos os campos corretamente.");
      }
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleEdit = async (id) => {
    try {
      setLoadingCreate(true);
      const values = await form.validateFields([
        "name",
        "description",
        "active",
      ]);
      await apiEditApiKey(id, values);
      notification.success({ message: "ApiKey Editado com sucesso!" });
      form.resetFields();
      setIsModalVisible(false);
      handleListApiKeys(1);
      setWebhookInfo();
    } catch (error) {
      console.error(error); // Adiciona um log para identificar o erro
      message.error("Por favor, preencha todos os campos corretamente.");
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      await apiDeleteApiKey(id);
      notification.success({ message: "ApiKey excluído com sucesso!" });
      form.resetFields();
      setIsModalVisible(false);
      handleListApiKeys(1);
      setWebhookInfo();
    } catch (error) {
      notification.error({ message: "Houve um erro inesperado" });
    } finally {
      setLoadingCreate(false);
    }
  };

  return (
    <div className="ApiKeyList">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <h2 className="title-page">ApiKey</h2>
                <SellerDropdown />
              </div>
              <Divider />
              <Breadcrumb.Item>Integrações</Breadcrumb.Item>
              <Breadcrumb.Item>ApiKey</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="box-divider">
                <h2 className="subtitle-page">ApiKeys</h2>
                <Button onClick={() => showModal()} type="primary">
                  Criar ApiKey
                </Button>
              </div>
              <Divider />
              {/*<Input.Search*/}
              {/*  placeholder='Pesquisar'*/}
              {/*  allowClear*/}
              {/*  enterButton*/}
              {/*  onSearch={handleSearch}*/}
              {/*  onChange={(e) => handleSearch(e.target.value)}*/}
              {/*  style={{ marginBottom: 16, maxWidth: '200px' }}*/}
              {/*/>*/}
              {loading ? (
                <Skeleton active />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  dataSource={
                    filteredData &&
                    filteredData.map((item) => ({ ...item, key: item.id }))
                  }
                  pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  bordered
                  onChange={onChange}
                />
              )}
            </Content>
            <Divider />
          </Layout>
        </>
      )}
      <ApiKeyListModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        loading={loadingCreate}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        webhookInfo={webhookInfo}
      />
      <ApiKeyCopyModal
        copyModalVisible={apiKey !== null && copyModalVisible}
        handleCancel={() => {
          setCopyModalVisible(false);
        }}
        apiKey={apiKey}
      />
    </div>
  );
};

export default ApiKeyList;
