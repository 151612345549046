import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Transfer } from "../../../../../assets/images/ticket/transfer.svg";
import InfoModal from '../../InfoModal/InfoModal';
import ShareModal from '../../ShareModal/ShareModal';
import './ResponseExpired.scss';


const ResponseExpired = ({ data, event, dataTicket }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');

  useEffect(() => {
    if (event) {
      try {
        const startDate = parseISO(event.starts_at);
        const endDate = parseISO(event.ends_at);

        const formattedStart = format(startDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });
        const formattedEnd = format(endDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });

        setFormattedStartDate(formattedStart);
        setFormattedEndDate(formattedEnd);
      } catch (error) {
        console.error('Error parsing or formatting date:', error);
      }
    }
  }, [event]);

  return (
    <section className="product-container">
      <div className="product-info">
        <div className="header-info">
          <div className="container-header-success">
            <div className="image-container">
              <img src={event.image_url} alt="Evento" />
            </div>
            <div className="data-event">
              <p className="title">
                {event.name}
              </p>
              <div>
                <div className="event-information-container">
                  <span className="mdi--clock-outline" />
                  <div>
                    <p className="title">
                      {formattedStartDate} - {formattedEndDate}
                    </p>
                  </div>
                </div>
                <div className="event-information-container">
                  <span className="carbon--location" />
                  <div>
                    <p className="title">
                      {`${event?.address?.street}, ${event?.address?.number} - ${event?.address?.neighborhood}, ${event?.address?.city} - ${event?.address?.state}`}
                    </p>
                  </div>
                </div>
                <button onClick={openModal}>
                  <Transfer />Compartilhe com seus amigos
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="infos-success">
          <div className="info-div">
            <p className='title'>E-mail utilizado no pedido</p>
            <div className="description">
              <p className='email'>{dataTicket?.customer?.email}</p>
            </div>
          </div>
          <div className="info-div">
            <p className='title'>Como acessar seus ingressos</p>
            <div className="description">
              <div ><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7ZM12 15C11.8022 15 11.6089 15.0586 11.4444 15.1685C11.28 15.2784 11.1518 15.4346 11.0761 15.6173C11.0004 15.8 10.9806 16.0011 11.0192 16.1951C11.0578 16.3891 11.153 16.5673 11.2929 16.7071C11.4328 16.847 11.6109 16.9422 11.8049 16.9808C11.9989 17.0194 12.2 16.9996 12.3827 16.9239C12.5654 16.8482 12.7216 16.72 12.8315 16.5556C12.9414 16.3911 13 16.1978 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM4 12C4 10.4177 4.4692 8.87103 5.34825 7.55544C6.2273 6.23984 7.47673 5.21446 8.93854 4.60896C10.4003 4.00346 12.0089 3.84504 13.5607 4.15372C15.1126 4.4624 16.538 5.22433 17.6569 6.34315C18.7757 7.46197 19.5376 8.88743 19.8463 10.4393C20.155 11.9911 19.9965 13.5997 19.391 15.0615C18.7855 16.5233 17.7602 17.7727 16.4446 18.6518C15.129 19.5308 13.5823 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84286 16.1566 4 14.1217 4 12Z" fill="currentColor"></path></svg><p><strong>Não geramos um cadastro automático.</strong></p></div>
              <div ><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 18H5C4.449 18 4 17.552 4 17V7.25L11.4 12.8C11.578 12.934 11.789 13 12 13C12.211 13 12.422 12.934 12.6 12.8L20 7.25V17C20 17.552 19.551 18 19 18ZM18.333 6L12 10.75L5.667 6H18.333ZM19 4H5C3.346 4 2 5.346 2 7V17C2 18.654 3.346 20 5 20H19C20.654 20 22 18.654 22 17V7C22 5.346 20.654 4 19 4Z" fill="currentColor"></path></svg><p>Atente-se ao e-mail <strong>{dataTicket?.customer?.email}</strong>, você receberá todo retorno por lá.</p></div>
              <div ><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11.95 18q.525 0 .888-.363t.362-.887t-.362-.888t-.888-.362t-.887.363t-.363.887t.363.888t.887.362m-.9-3.85h1.85q0-.825.188-1.3t1.062-1.3q.65-.65 1.025-1.238T15.55 8.9q0-1.4-1.025-2.15T12.1 6q-1.425 0-2.312.75T8.55 8.55l1.65.65q.125-.45.563-.975T12.1 7.7q.8 0 1.2.438t.4.962q0 .5-.3.938t-.75.812q-1.1.975-1.35 1.475t-.25 1.825M12 22q-2.075 0-3.9-.787t-3.175-2.138T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8" /></svg><p>Dúvidas? entre contato com nosso <a href="mailto:suporte@dlpay.com.br" target="_blank" rel="noopener noreferrer">Email</a>.</p></div>
            </div>

          </div>
        </div>
        <ShareModal event={event} isOpen={isModalOpen} onClose={closeModal} />
        <InfoModal
          event={event}
          title='O tempo para a compra expirou'
          content='Isso é necessário para que uma reserva não fique presa e possa estar disponível para compra novamente. <br />
          <strong>Caso tenha efetuado o pagamento, em breve você receberá um email de confirmação.</strong>'
          isOpen={data.status === 'EXPIRED'}
        />
      </div>
    </section>
  )
}

export default ResponseExpired
