import React, { useState } from "react";
import "./SignUp.scss";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ProgressBar from "../../common/ProgressBar/ProgressBar";
import Profile from "./Profile/Profile";
import DataRegister from "./DataRegister/DataRegister";
import AddressRegister from "./AddressRegister/AddressRegister";
import BankRegister from "./BankRegister/BankRegister";
import DocsRegister from "./DocsRegister/DocsRegister";
import FinalRegister from "./FinalRegister/FinalRegister";

const SignUp = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [typeProfile, setTypeProfile] = useState("");

  const handleProfile = (e) => {
    setTypeProfile(e)
    setCurrentStepIndex(1)
  };

  return (
    <div className={"SignUp"}>
      <div className={"header"}>
        <div className={"navigation"}>
          <Button
            shape={"circle"}
            size={"large"}
            icon={<ArrowLeftOutlined />}
          ></Button>
          <span className={"title"}>Cadastro na DLPay</span>
        </div>
        <div className={"steps-indicator"}>
          <ProgressBar
            min={1}
            max={4}
            secondaryColor
            currentStep={currentStepIndex}
          />
        </div>
      </div>
      {currentStepIndex === 0 && <Profile handleProfile={handleProfile} />}
      {currentStepIndex === 1 && <DataRegister nextStep={() => setCurrentStepIndex(2)} />}
      {currentStepIndex === 2 && <AddressRegister nextStep={() => setCurrentStepIndex(3)} />}
      {currentStepIndex === 3 && <BankRegister nextStep={() => setCurrentStepIndex(4)} />}
      {currentStepIndex === 4 && <DocsRegister nextStep={() => setCurrentStepIndex(5)} />}
      {currentStepIndex === 5 && <FinalRegister nextStep={() => setCurrentStepIndex(5)} />}
    </div>
  );
};

export default SignUp;
