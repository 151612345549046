import { Button, Divider, Form, Input } from 'antd';
import React, { useCallback, useState } from 'react';
import { countriesList, statesList } from '../../../../../helpers/enumsHelper';
import './Company.scss';

const Company = ({ data, onSave, onDiscard, setUnsavedChanges, setDataToSave }) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [localData, setLocalData] = useState(data);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalData(prevData => {
      const newData = { ...prevData, company: { ...prevData.company, [name]: value } };
      setUnsavedChanges(true);
      return newData;
    });
  };

  const handleSave = async () => {
    if (onSave) {
      await onSave(localData);
    }
    setEnableEdit(false);
    setUnsavedChanges(false);
  };

  const handleDiscard = async () => {
    if (onDiscard) {
      await onDiscard();
    }
    setEnableEdit(false);
    setUnsavedChanges(false);
  };

  const handleDataToSave = useCallback((data) => {
    setDataToSave(data);
  }, [setDataToSave]);

  React.useEffect(() => {
    if (localData) {
      handleDataToSave(localData);
    }
  }, [localData, handleDataToSave]);

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cnpj;
  }

  return (
    <div className='Company'>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className='subtitle-page'>Empresa</p>
        <Button
          onClick={() => setEnableEdit(!enableEdit)}
          type={`${enableEdit ? "primary" : ""}`}
        >
          {enableEdit ? "Cancelar" : "Editar"}
        </Button>
      </div>
      <Divider />
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item label="Nome">
              <Input
                name="name"
                disabled={!enableEdit}
                placeholder='Nome'
                value={localData?.company?.name || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                name="email"
                disabled={!enableEdit}
                placeholder='Email'
                value={localData?.company?.email || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item label="Telefone">
              <Input
                name="phone"
                disabled={!enableEdit}
                placeholder='Telefone'
                value={localData?.company?.phone || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="CNPJ">
              <Input
                name="tax_id"
                disabled={!enableEdit}
                placeholder='CNPJ'
                value={formatCNPJ(localData?.company?.tax_id || "Não informado")}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Divider />
      <p className='subtitle-page'>Endereço</p>
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item label="CEP">
              <Input
                name="postal_code"
                disabled={!enableEdit}
                placeholder='CEP'
                value={localData?.company?.address?.postal_code || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Número">
              <Input
                name="number"
                disabled={!enableEdit}
                placeholder='Número'
                value={localData?.company?.address?.number || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Bairro">
              <Input
                name="neighborhood"
                disabled={!enableEdit}
                placeholder='Bairro'
                value={localData?.company?.address?.neighborhood || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Estado">
              <Input
                name="state"
                disabled={!enableEdit}
                placeholder='Estado'
                value={statesList[localData?.company?.address?.state] || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>

        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item label="Rua">
              <Input
                name="street"
                disabled={!enableEdit}
                placeholder='Rua'
                value={localData?.company?.address?.street || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Complemento">
              <Input
                name="complement"
                disabled={!enableEdit}
                placeholder='Complemento'
                value={localData?.company?.address?.complement || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="Cidade">
              <Input
                name="city"
                disabled={!enableEdit}
                placeholder='Cidade'
                value={localData?.company?.address?.city || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item label="País">
              <Input
                name="country_code"
                disabled={!enableEdit}
                placeholder='País'
                value={countriesList[localData?.company?.address?.country_code] || "Não informado"}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      {enableEdit &&
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleSave} type="primary">Salvar</Button>
          <Button onClick={handleDiscard}>Descartar</Button>
        </div>
      }
    </div>
  );
};

export default Company;
