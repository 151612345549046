import React from 'react';
import './RecoverPassword.scss';
import {Button} from "antd";
import logo from "../../../assets/images/logo.png";
import {useNavigate} from "react-router-dom";

const RecoverPassword = () => {
    const navigate = useNavigate();

    return (
        <div className={"RecoverPassword"}>
            <div className={"form-container"}>
                <div className={"logo"}>
                    <img src={logo} alt="Logo"/>
                </div>
                <div className={"form"}>
                    Para recuperar sua senha, entre em contato com nosso e-mail de suporte.
                    <a href={"mailto:daniel.luongo@grupoassessorando.com.br"}>daniel.luongo@grupoassessorando.com.br</a>
                </div>
                <div className={"register"}>
                    <Button type={"text"} shape={"standard"} size={"small"}
                            onClick={() => {
                                navigate(`/login`)
                            }}
                    >Lembrou sua senha?</Button>
                    <Button
                        onClick={() => {
                            navigate(`/register`)
                        }}
                    >Quero me cadastrar</Button>
                </div>
            </div>
            <div className={"background"}>
                <span>O melhor meio de pagamento feito para você.</span>
            </div>
        </div>
    );
};

export default RecoverPassword;
