import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as Arrow } from "../../../assets/images/ticket/down-arrow.svg";
import "./SelectTicket.scss";

const SelectTicket = ({
  options,
  updateState,
  value,
  label,
  displayError,
  displayErrorMessage,
  valid,
  defaultOption
}) => {
  const handleChange = e => {
    updateState(e.target.value);
  };

  return (
    <>
      <div className={`SelectTicket ${displayError ? "invalid" : ""}`}>
        <div>
          {label && <label>{label}</label>}
          <select
            className={displayError ? "invalid" : ""}
            onChange={handleChange}
            value={value}
          >
            {defaultOption && <option value="">{defaultOption}</option>}
            {options.length > 0 && options.map((option, index) => (
              <option key={index} value={option.value || option}>
                {option.label || option}
              </option>
            ))}
          </select>
          <Arrow />
        </div>

      </div>
      {displayError && (
        <p className="error-message">{displayErrorMessage}</p>
      )}
    </>
  );
};

SelectTicket.propTypes = {
  options: PropTypes.array.isRequired,
  displayError: PropTypes.bool.isRequired,
  displayErrorMessage: PropTypes.string,
  label: PropTypes.string,
  updateState: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

SelectTicket.defaultProps = {
  displayErrorMessage: "",
  label: "",
  valid: true
};

export default SelectTicket;
