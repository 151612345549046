import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

const { Option } = Select;
const { Text } = Typography;

const AddQuestions = ({ questions, setQuestions, starterQuestionList, setStarterQuestionList }) => {
  const [listOptions, setListOptions] = useState({});
  const [valueInput, setValueInput] = useState({});

  useEffect(() => {
    const initialOptions = {};
    starterQuestionList.forEach((question, index) => {
      if (question.values) {
        initialOptions[index] = question.values;
      }
    });
    setListOptions(initialOptions);
  }, [starterQuestionList]);

  const removeOption = (name, index) => {
    const updatedOptions = [...(listOptions[index] || [])];
    const optionIndex = updatedOptions.indexOf(name);
    if (optionIndex > -1) {
      updatedOptions.splice(optionIndex, 1);
      setListOptions({ ...listOptions, [index]: updatedOptions });
      handleChangeQuestions(updatedOptions, index, "values");
    }
  };

  const handleChangeQuestions = (value, index, keyName) => {
    const questionsCopy = [...starterQuestionList];
    if (questionsCopy[index]) {
      questionsCopy[index][keyName] = value;
    } else {
      const question = {};
      question[keyName] = value;
      questionsCopy.push(question);
    }
    setStarterQuestionList(questionsCopy);
  };

  const handleCreateOption = (value, index) => {
    if (value.trim().length < 5) {
      message.warning("O mínimo é 5 caracteres");
      return;
    }

    const updatedOptions = [...(listOptions[index] || []), value.trim()];
    setListOptions({ ...listOptions, [index]: updatedOptions });
    handleChangeQuestions(updatedOptions, index, "values");
    setValueInput({ ...valueInput, [index]: "" });
  };

  const handleDeleteQuestion = (index) => {
    setQuestions(questions.filter((_, idx) => idx !== index));
    setStarterQuestionList(starterQuestionList.filter((_, idx) => idx !== index));
  };

  return (
    <div style={{ width: "100%" }}>
      {questions.map((manager, index) => (
        <div style={{ margin: "40px 0" }} key={index}>
          <Card
            style={{
              marginTop: "30px",
              padding: 0,
              alignItems: "flex-end",
              borderRadius: "0px",
            }}
          >
            <Form.Item style={{ width: "100%", flexDirection: "column" }}>
              <Input
                type="text"
                value={starterQuestionList[index]?.prompt || ""}
                onChange={(e) => handleChangeQuestions(e.target.value, index, "prompt")}
                placeholder="Pergunta"
              />
            </Form.Item>
            <Form.Item label="Tipo de resposta" style={{ width: 250, marginRight: 30 }}>
              <Select
                placeholder="Tipo de resposta"
                value={starterQuestionList[index]?.type || ""}
                onChange={(value) => handleChangeQuestions(value, index, "type")}
              >
                <Option value="STRING">Texto</Option>
                <Option value="INTEGER">Numérico</Option>
                <Option value="DATE">Data</Option>
                <Option value="EMAIL">E-Mail</Option>
                <Option value="PHONE">Telefone</Option>
                <Option value="CPF">CPF</Option>
                <Option value="LIST">LISTA</Option>
                <Option value="RADIO">SELECT</Option>
              </Select>
            </Form.Item>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteQuestion(index)}
            >
              Apagar
            </Button>
          </Card>
          {(starterQuestionList[index]?.type === "LIST" || starterQuestionList[index]?.type === "RADIO") && (
            <div
              style={{
                borderRadius: "0 0 20px 20px",
                padding: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                border: "1px solid #f0f0f0",
                borderTop: "0px"
              }}
            >
              <div style={{ maxWidth: 300 }}>
                <Input
                  style={{ maxWidth: 300 }}
                  value={valueInput[index] || ""}
                  type="text"
                  placeholder="Adicionar opção"
                  onChange={(e) => setValueInput({ ...valueInput, [index]: e.target.value })}
                  onPressEnter={() => handleCreateOption(valueInput[index], index)}
                />
                <Button style={{ marginTop: "10px" }} type="primary" onClick={() => handleCreateOption(valueInput[index], index)}>
                  Adicionar
                </Button>
              </div>
              <div style={{ width: "100%", flexWrap: "wrap", display: "flex", marginTop: 10 }}>
                {listOptions[index]?.map((item, key) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 5px 3px 0",
                      padding: "0px 10px",
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      width: "fit-content",
                    }}
                  >
                    <span style={{ marginRight: 10 }}>{item}</span>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => removeOption(item, index)}
                      style={{ color: "red" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AddQuestions;
