import React, { useState } from 'react'
import './DocsRegister.scss'
import { Button, Divider, Input, Modal, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const DocsRegister = ({nextStep}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCPF = (info) => {
    console.log(info.file);
  };

  const handleAddress = (info) => {
    console.log(info.file);
  };

  const handleActivity = (info) => {
    console.log(info.file);
  };

  return (
    <div className='DocsRegister'>
      <div className="content">
        <div className="title"><span class="ion--arrow-back"></span> Documentos</div>
        <Divider />

        <div className="info-blue">
          <p>Apenas cópias com qualidade legível dos seguintes documentos</p>
        </div>
        <div className="form">
          <div className="container-inputs">
            <div>
              <p>CNH ou RG e CPF<span>*</span></p>
              <Upload onChange={handleCPF}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
            <div>
              <p>Comprovante de endereço<span>*</span></p>
              <Upload onChange={handleAddress}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
            <div>
              <p>Comprovante de atividade<span>*</span></p>
              <Upload onChange={handleActivity}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
          </div>
          <div className="infos-docs">
            <p className="mini-info">Cartão de visita, screenshot/print de tela do website, foto da fachada, contrato social, alvará de funcionamento e outros arquivos relacionados.</p>
            <ul>
              <li> Muito importante que a foto seja do documento original e esteja completa, sem corte de nenhuma numeração ou ilegibilidade de alguma característica do documento.</li>
            </ul>

            <div className="info-green">
              Aceitamos documentos digitalizados: CNH Digital com cópia do QRCode e RG Digital.
            </div>
          </div>
          <div className='container-buttons'>
            <Button onClick={nextStep} className='button-continue' size='large' type="primary">Continuar</Button>
            <Button onClick={showModal} className='button-continue' size='large' danger>Importante saber</Button>
            <Modal className='modal' title="Não são aceitos, por regra dos bancos" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <ul>
                <li>Fotocópias em preto e branco</li>
                <li>Cópia/ captura de tela de celular</li>
              </ul>

              <p className='text-outline'>Outros documentos que NÃO são aceitos por dificuldade de processamento eletrônico com os bancos</p>

              <ul>
                <li>Carteira da OAB</li>
                <li>Carteira da CRM</li>
                <li>Carteira da CRO</li>
                <li>Carteira da CRA</li>
                <li>Carteira da CREFITO</li>
              </ul>

              <div className="info-yellow">
                <p>Outras identificações desse gênero não são aceitas</p>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocsRegister
