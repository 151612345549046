// EVENT_SUBJECT_CHOICES
export const EVENT_SUBJECT_CHOICES = [
  { value: '12', label: "Acadêmico e científico" },
  { value: '46', label: "Agro" },
  { value: '13', label: "Artesanato" },
  { value: '14', label: "Casa e estilo de vida" },
  { value: '15', label: "Cinema, fotografia" },
  { value: '16', label: "Desenvolvimento pessoal" },
  { value: '17', label: "Design, métricas e produtos digitais" },
  { value: '18', label: "Direito e legislação" },
  { value: '19', label: "Empreendedorismo, negócios e inovação" },
  { value: '47', label: "Erótico, adulto, explícito" },
  { value: '20', label: "Esportes" },
  { value: '21', label: "Games e geek" },
  { value: '22', label: "Gastronomia, comidas e bebidas" },
  { value: '23', label: "Governo e política" },
  { value: '24', label: "Informática, tecnologia e programação" },
  { value: '25', label: "Marketing e vendas" },
  { value: '26', label: "Moda e beleza" },
  { value: '27', label: "Música" },
  { value: '28', label: "Outro" },
  { value: '29', label: "Religião, espiritualidade" },
  { value: '30', label: "Saúde, nutrição e bem-estar" },
  { value: '31', label: "Sociedade e cultura" },
  { value: '32', label: "Teatro, stand up e dança" }
];

// EVENT_CATEGORY_CHOICES
export const EVENT_CATEGORY_CHOICES = [
  { value: '33', label: "Competição ou torneio" },
  { value: '34', label: "Corrida" },
  { value: '35', label: "Curso, aula, treinamento ou workshop" },
  { value: '36', label: "Drive-in" },
  { value: '37', label: "Espetáculos" },
  { value: '38', label: "Feira, festival ou exposição" },
  { value: '39', label: "Festa, festival ou show" },
  { value: '40', label: "Meetup ou evento de networking" },
  { value: '41', label: "Missa ou culto" },
  { value: '42', label: "Outro" },
  { value: '43', label: "Palestra, congresso ou seminário" },
  { value: '44', label: "Passeios, excursões ou tour" },
  { value: '45', label: "Retiro ou acampamento" }
];

// EVENT_NOMENCLATURE_CHOICES
export const EVENT_NOMENCLATURE_CHOICES = [
  { value: 'TICKETING', label: "Ingresso" },
  { value: 'REGISTRATION', label: "Inscrição" },
  { value: 'CONTRIBUTION', label: "Contribuição" }
];
