import { baseSiteEndPoint, buildHeaders, processResponse } from "./common";

export const apiDetailsCheckout = (getSellerID) =>
  fetch(`${baseSiteEndPoint}/sales/getsalecontext/${getSellerID}`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(processResponse);

export const apiPayCharge = async (saleId, jsonData) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/sales/charge/${saleId}/pay`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
