import {
  Breadcrumb,
  Button,
  Divider,
  Input,
  Layout,
  notification,
  Result,
  Skeleton,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiTransactionDetail, apiVoidTransaction } from "../../../api/transactions";
import { formatPrice } from "../../../utils/format";
import "./SaleInfo.scss";
import SplitRule from "./SplitRule/SplitRule";

const SaleInfo = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const transactionID = parts[parts.length - 1];

  const [dataTransaction, setDataTransaction] = useState();
  const [loading, setLoading] = useState(false);
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const navigate = useNavigate();

  const dateObject = dataTransaction && new Date(dataTransaction?.created_at);
  const dateFormated =
    dataTransaction && format(dateObject, "dd/MM/yyyy 'às' HH:mm:ss");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/listagem-vendas")} type="primary">
              Listagem de vendas
            </Button>
          }
        />
      );
    }
  };

  const handleList = async () => {
    setLoading(true);
    try {
      const json = await apiTransactionDetail(transactionID);
      setDataTransaction(json);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400 || error.status === 404) {
        setErrorStatus("404");
      } else if (error.status === 500) {
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
      }
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  const handleVoid = async () => {
    switch (dataTransaction?.status) {
      case "confirmed":
        notification.error({
          message: "Erro",
          description: "Estornar venda não é possível, a venda já foi confirmada.",
          placement: "topRight",
        });
        return;

      case "pending":
        notification.error({
          message: "Erro",
          description: "Estornar venda não é possível, a venda está pendente.",
          placement: "topRight",
        });
        return;

      case "canceled":
        notification.error({
          message: "Erro",
          description: "Estornar venda não é possível, a venda já foi cancelada.",
          placement: "topRight",
        });
        return;

      default:
    }
    setLoading(true);
    try {
      await apiVoidTransaction(dataTransaction?.id);
      notification.success({
        message: "Sucesso",
        description: "Venda foi estornada com sucesso!",
        placement: "topRight",
      });
      navigate(`/listagem-vendas`);
      setLoading(false);
    } catch (error) {
      const status = error?.status || 400;
      let description;

      switch (status) {
        case 400:
          description = "Ocorreu um erro ao estornar a venda.";
          break;
        case 500:
          description =
            "Ocorreu um erro no servidor ao estornar a venda. Por favor, tente novamente mais tarde.";
          break;
        default:
          description = "Erro ao estornar a venda.";
          break;
      }

      notification.error({
        message: "Erro",
        description,
        placement: "topRight",
      });
      setLoading(false);
    }
  };


  return (
    <div className="SaleInfo">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        {errorStatus ? (
          renderError()
        ) : (
          <>
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              {loading ? <Skeleton active paragraph={{ rows: 0 }} /> :
                <h2 className="title-page">
                  {formatPrice(dataTransaction?.original_amount)}
                </h2>
              }
              <Divider />
              {loading ? <Skeleton active paragraph={{ rows: 0 }} /> :
                <>
                  <Breadcrumb.Item>
                    {formatPrice(dataTransaction?.original_amount)}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>VENDA #{dataTransaction?.id}</Breadcrumb.Item>
                </>
              }
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {loading ? <Skeleton active paragraph={{ rows: 1 }} /> :
                <div className="container-subtitle">
                  <h3 className="subtitle-page">1. Informações da venda</h3>
                  <span
                    className={`badge ${dataTransaction?.status === "succeeded"
                      ? "badge badge-success"
                      : dataTransaction?.status === "new"
                        ? "badge badge-success"
                        : dataTransaction?.status === "created"
                          ? "badge badge-success"
                          : dataTransaction?.status === "failed"
                            ? "badge-error"
                            : dataTransaction?.status === "canceled"
                              ? "badge-error"
                              : dataTransaction?.status === "pre_authorized"
                                ? "yellow"
                                : dataTransaction?.status === "reversed"
                                  ? "yellow"
                                  : dataTransaction?.status === "pending"
                                    ? "orange"
                                    : dataTransaction?.status === "new"
                                      ? "blue"
                                      : dataTransaction?.status === "partial_refunded"
                                        ? "blue"
                                        : dataTransaction?.status === "dispute"
                                          ? "badge-error"
                                          : dataTransaction?.status === "refunded "
                                            ? "badge-error"
                                            : "black"
                      }`}
                  >
                    {dataTransaction?.status === "succeeded"
                      ? "Confirmada"
                      : dataTransaction?.status === "new"
                        ? "Novo"
                        : dataTransaction?.status === "created"
                          ? "Novo"
                          : dataTransaction?.status === "failed"
                            ? "Não autorizada"
                            : dataTransaction?.status === "canceled"
                              ? "Cancelada"
                              : dataTransaction?.status === "pre_authorized"
                                ? "Pré Autorizada"
                                : dataTransaction?.status === "reversed"
                                  ? "Revertida"
                                  : dataTransaction?.status === "pending"
                                    ? "Pendente"
                                    : dataTransaction?.status === "new"
                                      ? "Nova"
                                      : dataTransaction?.status === "partial_refunded"
                                        ? "Estornado Parcialmente"
                                        : dataTransaction?.status === "dispute"
                                          ? "Em Disputa"
                                          : dataTransaction?.status === "refunded"
                                            ? "Estornado"
                                            : "black"}
                  </span>
                </div>
              }
              <Divider />
              {loading ? <Skeleton active paragraph={{ rows: 3 }} /> :
                <div className="container-inputs">
                  <div className="container-left">
                    <div>
                      <p>Data/hora</p>
                      <Input disabled value={dateFormated} />
                    </div>
                    <div>
                      <p>Lojista</p>
                      <Input
                        disabled
                        value={dataTransaction?.statement_descriptor}
                      />
                    </div>
                    <div>
                      <p>Valor bruto</p>
                      <Input
                        disabled
                        value={formatPrice(dataTransaction?.amount)}
                      />
                    </div>
                    <div>
                      <p>Terminal</p>
                      <Input disabled value={dataTransaction?.point_of_sale?.identification_number} />
                    </div>
                    <div>
                      <p>Identificador Adquirente</p>
                      <Input disabled value={dataTransaction?.zoop_id} />
                    </div>
                  </div>
                  <div className="container-right">
                    <div>
                      <p>Tipo</p>
                      <Input
                        disabled
                        value={
                          dataTransaction?.payment_type === "credit"
                            ? "Crédito"
                            : dataTransaction?.payment_type === "debit"
                              ? "Débito"
                              : dataTransaction?.payment_type === "pix"
                                ? "Pix"
                                : dataTransaction?.payment_type === "boleto"
                                  ? "Boleto"
                                  : "Desconhecido"
                        }
                      />
                    </div>
                    <div>
                      <p>Parcelas</p>
                      <Input
                        disabled
                        value={
                          dataTransaction?.installment_plan?.number_installments
                        }
                      />
                    </div>
                    <div>
                      <p>Método de pagamento</p>
                      <Input disabled value="Venda presencial" />
                    </div>
                    <div>
                      <p>No. Serial</p>
                      <Input disabled value={dataTransaction?.on_behalf_of} />
                    </div>
                    <div>
                      <p>Previsão de pagamento</p>
                      <Input disabled value={dataTransaction?.expected_on ? format(dataTransaction?.expected_on, "dd/MM/yyyy 'às' HH:mm:ss") : "Sem informação"} />
                    </div>
                  </div>
                </div>
              }
            </Content>
            {!loading && (
              <>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: dataTransaction?.customer ? 280 : 40,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">2. Informações do comprador</h3>
                  </div>
                  <Divider />
                  {dataTransaction?.customer ? (
                    <div className="container-inputs">
                      <div className="container-left">
                        <div>
                          <p>Nome</p>
                          <Input disabled value={dataTransaction?.customer?.name} />
                        </div>
                        <div>
                          <p>Email</p>
                          <Input
                            disabled
                            value={dataTransaction?.customer?.email}
                          />
                        </div>
                      </div>
                      <div className="container-right">
                        <div>
                          <p>Telefone</p>
                          <Input
                            disabled
                            value={dataTransaction?.customer?.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3>Comprador não foi identificado</h3>
                    </div>
                  )}
                </Content>

                {(dataTransaction?.payment_type === "credit" || dataTransaction?.payment_type === "debit") && <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">3. Informações de pagamento</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <div>
                        <p>Bandeira</p>
                        <Input
                          style={{ textTransform: "uppercase" }}
                          disabled
                          value={dataTransaction?.payment_method?.card_brand}
                        />
                      </div>
                      <div>
                        <p>Nome do titular</p>
                        <Input
                          disabled
                          value={dataTransaction?.payment_method?.holder_name}
                        />
                      </div>
                    </div>
                    <div className="container-right">
                      <div>
                        <p>Número do cartão</p>
                        <Input
                          disabled
                          value={dataTransaction?.payment_method?.first4_digits}
                        />
                      </div>
                      <div>
                        <p>Vencimento</p>
                        <Input
                          disabled
                          value={
                            dataTransaction?.payment_method?.expiration_month +
                            "/" +
                            dataTransaction?.payment_method?.expiration_year
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Content>}

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">4. Informações do autorizador</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <div>
                        <p>Código de autorização</p>
                        <Input
                          disabled
                          value={dataTransaction?.transaction_number}
                        />
                      </div>
                      <div>
                        <p>ID da transação</p>
                        <Input
                          disabled
                          value={
                            dataTransaction?.payment_authorization?.authorizer_id
                          }
                        />
                      </div>
                    </div>
                    <div className="container-right">
                      <div>
                        <p>Autorizador</p>
                        <Input
                          style={{ textTransform: "uppercase" }}
                          disabled
                          value={dataTransaction?.gateway_authorizer}
                        />
                      </div>
                      <div>
                        <p>Retorno do adquirente</p>
                        <Input
                          disabled
                          value={
                            dataTransaction?.payment_authorization
                              ?.authorization_nsu
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 150,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">5. Agenda de recebimento</h3>
                  </div>
                  <Divider />
                  <div className="container-table">
                    <p>Sem agendamentos no momento.</p>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 150,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">6. Regras de split</h3>
                  </div>
                  <Divider />
                  <div className="container-split">
                    <SplitRule originalAmount={dataTransaction?.original_amount} data={dataTransaction?.split_rules} />
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 150,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">7. Documentos relacionados</h3>
                  </div>
                  <Divider />
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 150,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">8. Local da compra</h3>
                  </div>
                  <Divider />
                  <p>Localização não informada pelo comprador ou dispositivo.</p>
                </Content>
                <div
                  className="container-buttons"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyItems: "space-between",
                    width: "100%",
                  }}
                >
                  <Button onClick={() => handleVoid()} type="primary" danger>Estornar venda</Button>
                </div>
              </>
            )}
          </>
        )}
      </Layout>
    </div>
  );
};

export default SaleInfo;
