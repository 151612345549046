import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Checkbox,
  Button,
  Spin,
  notification,
  Switch,
} from "antd";
import { apiDetailsWebhook } from "../../../../api/webhook";

const WebhookModalCreate = ({
  isModalVisible,
  handleOk,
  handleEdit,
  handleDelete,
  handleCancel,
  form,
  loading,
  webhookInfo,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);

  useEffect(() => {
    const fetchCheckoutDetails = async () => {
      try {
        setLoadingDetails(true);
        const data = await apiDetailsWebhook(webhookInfo.id);
        const initialData = {
          url: data.url,
          secret: data.secret,
          events: data.events ? data.events.split(",") : [],
        };
        form.setFieldsValue(initialData);
      } catch (error) {
        notification.error("Erro ao buscar detalhes do webhook");
      } finally {
        setLoadingDetails(false);
      }
    };

    if (webhookInfo) {
      fetchCheckoutDetails();
    } else {
      form.resetFields();
    }
  }, [webhookInfo, form]);

  const validateURL = async (rule, value) => {
    if (
      value &&
      !value.startsWith("http://") &&
      !value.startsWith("https://")
    ) {
      throw new Error(
        'Por favor, insira uma URL válida começando com "http://" ou "https://"'
      );
    }
  };

  return (
    <>
      <Modal
        title={webhookInfo ? "Edição - Webhook" : "Criação - Webhook"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={webhookInfo ? "Atualizar" : "Submit"}
        cancelText="Cancel"
        footer={[
          webhookInfo && (
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => handleDelete(webhookInfo.id)}
            >
              Deletar
            </Button>
          ),
          <Button
            key="submit"
            type="primary"
            loading={loading || loadingDetails}
            onClick={() =>
              webhookInfo ? handleEdit(webhookInfo.id) : handleOk()
            }
          >
            {webhookInfo ? "Atualizar" : "Criar"}
          </Button>,
        ]}
      >
        <Spin
          spinning={loadingDetails}
          tip="Carregando..."
          style={{ textAlign: "center" }}
        >
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Obrigatório!",
                },
                {
                  validator: validateURL,
                },
              ]}
            >
              <Input placeholder="https://dominio-exemplo.com/webhook" />
            </Form.Item>
            {!webhookInfo && (
              <Form.Item
                label="Secret"
                name="secret"
                rules={[
                  {
                    required: true,
                    message: "Obrigatório!",
                  },
                ]}
              >
                <Input placeholder="teste123" />
              </Form.Item>
            )}
            <Form.Item
              label="Events"
              name="events"
              rules={[
                {
                  required: true,
                  message: "Selecione algum evento",
                },
              ]}
            >
              <Checkbox.Group>
                <Checkbox value="statusUpdate">
                  Atualização de status das transações
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default WebhookModalCreate;
