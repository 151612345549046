import React from 'react';
import { useNavigate } from 'react-router-dom';
import './InfoModal.scss';

const InfoModal = ({ event, isOpen, onClose, title, content }) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  const handleNavigate = () => {
    const encodedName = encodeURIComponent(event.name);
    navigate(`/evento/${encodedName}/${event.id}`);
  };

  return (
    <div className={`InfoModal`} onClick={onClose}>
      <div className={`modal-content-info  ${isOpen ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="infos-modal">
          <p className='title-modal' dangerouslySetInnerHTML={{ __html: title }} />
          <div>
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <button onClick={handleNavigate} className='button-return'>VOLTAR</button>
        </div>
      </div>
    </div >
  );
};

export default InfoModal;
