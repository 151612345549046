import React, { useState } from 'react'
import './DataRegister.scss'
import { Button, DatePicker, Divider, Input, Select } from 'antd'
import { MaskIdentity } from '../../../../utils/MaskIdentity'
import { cnaeList } from './cnaeList'

const DataRegister = ({ nextStep }) => {
  const { Option } = Select;
  const [dataStep, setDataStep] = useState(0)
  const [cpf, setCPF] = useState('')
  const [phone, setPhone] = useState('')

  const formattedCnaeList = cnaeList.map(item => {
    const [value, label] = item.split(',');
    return { value, label };
  });

  const nextAllSteps = () => {
    if (dataStep === 2) {
      nextStep()
    }
  }

  const handleChange = (e) => {
    const mask =
      e.target.value.length > 6 ? "($1) $2-$3" : e.target.value.length > 2 ? "($1) $2" : "$1";
    const groupRegex =
      e.target.value.length > 10
        ? /^(\d{1,2})(\d{0,5})(\d{0,4}).*/
        : /^(\d{1,2})(\d{0,4})(\d{0,4}).*/;

    setPhone(e.target.value.replace(groupRegex, mask));
  };

  return (
    <div className='DataRegister'>
      <div className="content">
        <div className="title"><span class="ion--arrow-back"></span> Dados</div>
        <Divider />
        {dataStep === 0 &&
          <div className="form">
            <div>
              <p>Qual é seu primeiro nome?</p>
              <Input size='large' placeholder='Digite aqui....' />
            </div>
            <Button onClick={() => (setDataStep(1))} className='button-continue' size='large' type="primary">Continuar</Button>
          </div>}
        {dataStep === 1 &&
          <div className="form">
            <div className="container-inputs">
              <div>
                <p>Nome, qual é seu sobrenome?</p>
                <Input size='large' placeholder='Digite aqui....' />
              </div>
              <div>
                <p>CPF</p>
                <MaskIdentity
                  className='cpf'
                  type="text"
                  name='identifier'
                  placeholder='000.000.000-00'
                  value={cpf}
                  onChange={e => { setCPF(e.target.value) }}
                />
              </div>
              <div>
                <p>Data de nascimento</p>
                <DatePicker placeholder='01/01/2000' className='date' size='large' />
              </div>
              <div>
                <p>Telefone ou celular</p>
                <Input size='large' value={phone}
                  onChange={handleChange} placeholder="(11) 12345-6789" />
              </div>
            </div>

            <Button onClick={() => (setDataStep(2))} className='button-continue' size='large' type="primary">Continuar</Button>
          </div>}

        {dataStep === 2 &&
          <div className="form">
            <div className="container-inputs">
              <div>
                <p>CNPJ</p>
                <MaskIdentity
                  className='cpf'
                  type="text"
                  name='identifier'
                  placeholder='000.000.000/0001-50'
                  value={cpf}
                  onChange={e => { setCPF(e.target.value) }}
                />
              </div>
              <div>
                <p>Razão social</p>
                <Input size='large' placeholder='000.000.000-00' />
              </div>
              <div>
                <p>Data de abertura</p>
                <DatePicker placeholder='01/01/2000' className='date' size='large' />
              </div>
              <div>
                <p>Código Nacional de Atividade Econômica (CNAE)</p>
                {/* <Select
                  size='large'
                  defaultValue="Selecione seu CNAE"
                  style={{ width: 120 }}
                // options={cnaeList}
                >
                  {formattedCnaeList.map(cnae => (
                    <Option key={cnae.value} value={cnae.value}>{cnae.label}</Option>
                  ))}
                </Select> */}
                <Select
                  size='large'
                  defaultValue="Selecione seu CNAE"
                  style={{ width: 200 }}
                  showSearch
                  placeholder="Selecione seu CNAE"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.value?.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {formattedCnaeList.map(cnae => (
                    <Option key={cnae.value} value={cnae.value}>{cnae.label}</Option>
                  ))}
                </Select>
              </div>
            </div>

            <Button onClick={() => nextAllSteps()} className='button-continue' size='large' type="primary">Continuar</Button>
          </div>}
      </div>
    </div>
  )
}

export default DataRegister
