import { Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiDetailsCheckout } from '../../../api/checkout';
import logoDLPAY from '../../../assets/images/logo.png';
import { formatPrice } from '../../../utils/format';
import CheckoutForm from '../../common/CheckoutForm/CheckoutForm';
import './ExternalPayment.scss';

const ExternalPayment = () => {
  const [infoProduct, setInfoProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const paymentID = parts[parts.length - 1];
  const [error, setError] = useState(false);

  useEffect(() => {
    apiDetailsCheckout(paymentID)
      .then((data) => {
        setInfoProduct(data?.context);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [paymentID]);

  const ErrorPage = () => {
    return (
      <div className="ErrorPage">
        <div className="content">
          <TriangleAlertIcon className="icon" />
          <h1 className="title">Oops, link inválido!!</h1>
          <p className="message">
            Parece que o ID de pagamento ou o produto que você está procurando não existe. Verifique os detalhes e tente novamente.
          </p>
        </div>
      </div>
    );
  };

  const TriangleAlertIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  );


  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <section className='ExternalPayment'>
          <header>
            <div className="container-header">
              <div className="navigation">
                <p>{infoProduct?.seller_name}</p>
                <img src={logoDLPAY} alt="Logo DLPay Private" />
              </div>
              <h2>Finalize seu pagamento</h2>
            </div>
          </header>
          <div className="content">
            <div className="details-content">
              <div className="info-details">
                {loading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <span>{infoProduct?.sale_name}</span>
                    <p className="price">{formatPrice(infoProduct?.sale_price)}</p>
                    <strong>Descrição:</strong>
                    <p>{infoProduct?.sale_description}</p>
                  </>
                )}
                {!loading && <p className="text-absolut">
                  Atenção, esta cobrança será lançada na sua fatura de cartão de crédito como: {infoProduct?.invoice_description}
                </p>}
              </div>
              {loading && <Skeleton paragraph={{ rows: 1 }} active />}
            </div>
            <div className="forms-container">
              <div className="container-checkout">
                {!loading && <CheckoutForm infoProduct={infoProduct} />}
                {loading && <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />}
              </div>
            </div>
          </div>
        </section>
      )
      }
    </>
  );
};

export default ExternalPayment;
