import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Input,
  Layout,
  Result,
  Skeleton,
  Table,
  notification,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiCheckoutList } from "../../../api/payment";
import SellerSelect from "../../common/SellerDropdown/SellerDropdown";
import "./PaymentPlans.scss";

const { Content } = Layout;

const PaymentPlans = () => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = async (value) => {
    setLoading(true);
    try {
      if (value === "") {
        await handleSimulate(pagination.current);
      } else {
        const json = await apiCheckoutList({ page: 1, search: value });
        setFilteredData(json.results);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: json.count,
          current: 1,
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const [loading, setLoading] = useState(true);

  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const handleSimulate = async (page) => {
    setLoading(true);
    try {
      const json = await apiCheckoutList({ page });
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error?.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os planos de pagamento.",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error?.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os planos de pagamento. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
        notification.error("Erro ao buscar os planos de pagamento.");
      }
    } finally {
      setLoading(false);
    }
  };

  const onChange = (pagination) => {
    handleSimulate(pagination.current);
  };

  useEffect(() => {
    handleSimulate(pagination.current);
  }, [pagination.current]);

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      notification.success({
        message: "Sucesso",
        description: "Link copiado com sucesso!",
        placement: "topRight",
      });
    });
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (confirmed) => (
        <button onClick={() => handleCopy(confirmed)} className="link-button">
          <LinkOutlined />
        </button>
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => {
        const statusOrder = {
          1: 1,
          0: 2,
        };
        return statusOrder[a.confirmed] - statusOrder[b.confirmed];
      },
      responsive: ["sm"],
    },
    {
      title: "Transações",
      dataIndex: "payment_count",
      key: "payment_count",
      render: (count) => (
        <p
          style={{ textAlign: "right" }}
        > {count} </p>
      ),
      responsive: ["sm"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link to={`/pagamento/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />{" "}
        </Link>
      ),
      key: "",
    },
  ];

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  return (
    <div className="PaymentPlans">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <h2 className="title-page">Links de pagamento</h2>
                <SellerSelect />
              </div>
              <Divider />
              <Breadcrumb.Item>Minhas vendas</Breadcrumb.Item>
              <Breadcrumb.Item>Links de pagamento</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="box-divider">
                <h2 className="subtitle-page">Links de pagamento</h2>
                <Button
                  onClick={() => navigate("/pagamento/criar")}
                  type="primary"
                >
                  Criar Link de Pagamento
                </Button>
              </div>
              <Divider />
              {!loading && <Input.Search
                placeholder='Pesquisar'
                allowClear
                enterButton
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 16, maxWidth: '200px' }}
              />}
              {loading ? (
                <Skeleton active />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  dataSource={
                    filteredData &&
                    filteredData.map((item) => ({ ...item, key: item.id }))
                  }
                  pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  bordered
                  onChange={onChange}
                />
              )}
            </Content>
            <Divider />
          </Layout>
        </>
      )}
    </div>
  );
};

export default PaymentPlans;
