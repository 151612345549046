export const searchPostalCode = (event, saveData) => {
  const rawCep = event.replace(/\D/g, '');

  if (rawCep.length !== 8) {
    return;
  }

  const formattedCep = rawCep.slice(0, 5) + '-' + rawCep.slice(5);

  fetch(`https://viacep.com.br/ws/${formattedCep}/json/`, { mode: 'cors' })
    .then((res) => res.json())
    .then((data) => {
      saveData(data);
    })
    .catch(err => {
      console.log(err);
      console.log('Erro ao buscar CEP');
    });
}