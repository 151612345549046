import React, { useEffect, useState } from 'react';
import './DateInput.scss';

const DateInput = ({ selectedMonth, selectedYear, onMonthChange, onYearChange, error }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      setValue(formatDate(`${selectedMonth}/${selectedYear}`));
    }
  }, [selectedMonth, selectedYear]);

  const formatDate = (value) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 2) {
      return digits;
    } else if (digits.length <= 4) {
      return `${digits.slice(0, 2)}/${digits.slice(2)}`;
    } else {
      return `${digits.slice(0, 2)}/${digits.slice(2, 4)}`;
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatDate(inputValue);
    setValue(formattedValue);

    const [month, year] = formattedValue.split('/');
    if (month?.length === 2 && year?.length === 2) {
      onMonthChange(month);
      onYearChange(year);
    } else {
      onMonthChange('');
      onYearChange('');
    }
  };

  return (
    <div className="date-input">
      <div className="title">Data de Validade</div>
      <input
        id="cc-valid-date"
        className={`input ${error ? 'error' : ''}`}
        type="text"
        placeholder="MM/AA"
        value={value}
        onChange={handleChange}
        maxLength="5"
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default DateInput;
