import React, { useState } from 'react'
import './AddressRegister.scss'
import { Button, Checkbox, Divider, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { searchPostalCode } from '../../../../utils/searchPostalCode'
import { postalCodeFormatter } from '../../../../utils/formating'

const AddressRegister = ({ nextStep }) => {
  const [postalCodeData, setPostalCodeData] = useState('');
  const [postalCode, setPostalCode] = useState('')
  const [disableComplement, setDisableComplement] = useState(false);

  const checkPostalCode = () => {
    searchPostalCode(postalCodeFormatter(postalCode), setPostalCodeData)
  }

  return (
    <div className='AddressRegister'>
      <div className="content">
        <div className="title"><span class="ion--arrow-back"></span> Endereço</div>
        <Divider />
        <div className="form">
          <div className="container-form">
            <div>
              <p>CEP</p>
              <div className='search-cep'>
                <Input onChange={e => setPostalCode(e.target.value)} size='large' placeholder="00000-180" />
                <Button onClick={() => checkPostalCode()} size='large' icon={<SearchOutlined />}>
                </Button>
              </div>
            </div>
            <div>
              <p>Endereço</p>
              <Input size='large' value={postalCodeData?.logradouro} />
            </div>

            <div className="two-inputs">
              <div>
                <p>Número</p>
                <Input type='number' placeholder='100' size='large' />
              </div>

              <div>
                <p>Complemento</p>
                <Input disabled={disableComplement} size='large' />
                <Checkbox onChange={(e) => setDisableComplement(e.target.checked)}>Não tem complemento</Checkbox>
              </div>
            </div>

            <div>
              <p>Bairro</p>
              <Input size='large' value={postalCodeData?.bairro} />
            </div>

            <div className='city'>
              <p>Cidade</p>
              <div>
                <Input size='large' value={postalCodeData?.bairro} />
              </div>
            </div>
            
          </div>
          <Button onClick={() => nextStep()} className='button-continue' size='large' type="primary">Continuar</Button>
        </div>
      </div>
    </div>
  )
}

export default AddressRegister
