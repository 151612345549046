import { Breadcrumb, Button, Divider, Input, Layout, Skeleton, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { apiSimulationTax } from '../../../api/simulation';
import { formatPrice } from '../../../utils/format';
import SellerDropdown from '../../common/SellerDropdown/SellerDropdown';
import PaymentTable from './PaymentTable/PaymentTable';
import './SimulationTax.scss';

const SimulationTax = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [inputValue, setInputValue] = useState('100');
  const [dataTable, setDataTable] = useState([])
  const [isFetching, setIsFetching] = useState(false);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setInputValue(parseInt(numericValue, 10) || 0);
  };

  const handleSimulate = (value) => {
    setIsFetching(true)
    apiSimulationTax((parseFloat(inputValue)/100) + "")
      .then(json => {
        setDataTable(json)
        setIsFetching(false)
      })
      .catch(() => { setIsFetching(false) });
  }

  useEffect(() => {
    handleSimulate(100)
  }, [])

  return (
    <div className='SimulationTax'>
      <Layout
        style={{
          padding: '1.25rem',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <h2 className='title-page'>Simulador</h2>
            <SellerDropdown />
          </div>
          <Divider />
          <Breadcrumb.Item>Simulador</Breadcrumb.Item>
          <Breadcrumb.Item>Simulador</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>1. Dados da simulação</h3>
          <Divider />
          <div className="container-serch">
            <div className='box-input'>
              <p>Valor da venda</p>
              <Input
                value={formatPrice(inputValue / 100)}
                onChange={handleAmountChange}
              />
              {/* <Input
                placeholder='R$ 0,00'
                value={inputValue}
                onChange={handleChange}
              /> */}
            </div>
            <Button loading={isFetching} type="primary" onClick={() => handleSimulate()}>Simular</Button>
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: '20px 0',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>Encargos ao lojista</h3>
          <Divider />
          {isFetching && <Skeleton active paragraph={{ rows: 10 }} />}
          {!isFetching && <PaymentTable mockObject={dataTable.seller_liable} />}
        </Content>

        {!isFetching && <Content
          style={{
            padding: 24,
            margin: '20px 0',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>Encargos ao comprador</h3>
          <Divider />
          <PaymentTable mockObject={dataTable.buyer_liable} />
        </Content>}
      </Layout>
    </div>
  )
}

export default SimulationTax
