import React, { useState } from 'react'
import './BankRegister.scss'
import { Button, Checkbox, Divider, Input, Radio, Select } from 'antd'
import { Option } from 'antd/es/mentions';
import { bankOptions } from '../../../../utils/bankList'

const BankRegister = ({ nextStep }) => {
  const [postalCodeData, setPostalCodeData] = useState('');
  const [disableComplement, setDisableComplement] = useState(false);

  return (
    <div className='BankRegister'>
      <div className="content">
        <div className="title"><span class="ion--arrow-back"></span> Dados bancários</div>
        <Divider />
        <div className="form">
          <div className="container-form">
            <div>
              <p>Banco</p>
              <Select
                size="large"
                defaultValue="Selecione um banco"
                style={{ width: 200 }}
                showSearch
                placeholder="Pesquise um banco"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {bankOptions.map(bank => (
                  <Option key={bank.value} value={bank.value}>
                    {bank.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="two-bank">
              <div className='first-div'>
                <p>Agência</p>
                <Input size='large' placeholder='002' />
              </div>
              <div>
                <p>Conta</p>
                <Input size='large' placeholder='21312312' />
              </div>
            </div>
            <div>
              <p>Tipo de conta</p>
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio value={'Conta Poupança'}>Conta Poupança</Radio>
                <Radio value={'Conta Corrente'}>Conta Corrente</Radio>
              </Radio.Group>
            </div>

            <div className="two-inputs">
              <div>
                <p>Número</p>
                <Input type='number' placeholder='100' size='large' />
              </div>
            </div>

            <div>
              <p>Nome impresso na fatura do cartão</p>
              <Input placeholder='Milionário da Silva' size='large' />
            </div>
          </div>
          <Button onClick={() => nextStep()} className='button-continue' size='large' type="primary">Continuar</Button>
        </div>
      </div>
    </div>
  )
}

export default BankRegister
