import { getItemFromStorage } from "../helpers/storage";
import { baseSiteEndPoint, buildHeaders, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiSplitPlans = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/plans/plans/split`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDetailsZoopPlan = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/plans/plans/zoop/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
