import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiPaymentDetail = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/paymentplans/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPaymentEdit = async (id, jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/paymentplans/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCheckoutEdit = async (id, jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const apiCheckoutDetail = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const apiCheckoutList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCheckoutDelete = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const apiPaymentDelete = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/paymentplans/${id}`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const apiPaymentCreate = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/paymentplans/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCheckoutCreate = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'Erro desconhecido durante a criação do checkout');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const apiPaymentsCheckout = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}/payments`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPaymentsCheckoutDetails = async (id, idDetails) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}/payments/${idDetails}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPaymentDownload = async (id, format) => {
  try {
    const acceptHeader =
      format === 'csv'
        ? 'text/csv'
        : 'application/vnd.ms-excel';

    const response = await authorizedFetch(
      `${baseSiteEndPoint}/sales/seller/${getSellerID()}/checkoutlink/${id}/payments/report/`,
      {
        method: 'GET',
        headers: {
          ...buildHeaders(),
          Accept: acceptHeader,
        },
      }
    );

    return response.blob();
  } catch (error) {
    throw error;
  }
};
