import { Breadcrumb, Collapse, DatePicker, Divider, Form, Input, Layout, Skeleton, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetEventCheckoutDetails } from '../../../../api/events';

const EventCheckoutDetails = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { eventID, checkoutID } = useParams();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [form] = Form.useForm();

  const statusTranslations = {
    FREE: "Gratuito",
    EXPIRED: "Expirado",
    PENDING: "Pendente",
    PAID: "Pago",
    CANCELED: "Cancelado"
  };

  useEffect(() => {
    apiGetEventCheckoutDetails(eventID, checkoutID).then((json) => {
      console.log(json)
      setEventDetails(json)
      form.setFieldsValue({
        name: json.customer.name || "",
        email: json.customer.email || "",
        phone_number: json.customer.phone_number || "",
        startDate: json.created_at ? dayjs(json.created_at) : null,
        status: statusTranslations[json.status] || "",
        // endDate: eventDetails.ends_at ? dayjs(eventDetails.ends_at) : null,
      });
    })
  }, [eventID, checkoutID])

  return (
    <div className="EventDetails">
      {false ? (
        // renderError(errorStatus)
        <p>teste</p>
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                {loading && <Skeleton active paragraph={{ rows: 1 }} />}
                <h2 className="title-page">{eventDetails?.customer?.name}</h2>
              </div>
              <Divider />
              {loading && <Skeleton active paragraph={{ rows: 0 }} />}
              {!loading && <>
                <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Ticket</Breadcrumb.Item><Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Comprador</Breadcrumb.Item>
                <Breadcrumb.Item>{eventDetails?.customer?.name}</Breadcrumb.Item></>}
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: "30px 0 0 0",
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container-subtitle">
                {loading && <Skeleton active paragraph={{ rows: 1 }} />}
                {!loading && <h3 className="subtitle-page">1. Infos do Comprador</h3>}
              </div>
              <Divider />
              {loading && <Skeleton active paragraph={{ rows: 3 }} />}
              {!loading && <div className="container-inputs">
                <div className="container-left">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Nome do comprador"
                      name="name"
                    >
                      <Input
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Email do comprador"
                      name="email"
                    >
                      <Input
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Status"
                      name="status"
                    >
                      <Input
                        disabled={true}
                      />
                    </Form.Item>
                  </Form>
                </div>
                <div className="container-right">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Data da compra"
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a data de início.",
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Telefone"
                      name="phone_number"
                    >
                      <Input
                        disabled={true}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
              }
            </Content>

            {!loading && < Content
              style={{
                padding: 24,
                margin: "30px 0 0 0",
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container-subtitle">
                {loading && <Skeleton active paragraph={{ rows: 1 }} />}
                {!loading && <h3 className="subtitle-page">1. Tickets Comprados</h3>}
              </div>
              <Divider />
              {loading && <Skeleton active paragraph={{ rows: 3 }} />}
              {/* TICKETS */}

              <Collapse>
                {eventDetails?.tickets?.map((ticket, index) => (
                  <Collapse.Panel
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`Ingresso ${index + 1}: ${ticket.ticket.name === 'Free' ? 'Gratuito' : ticket.ticket.name}`}</span>
                        <span>{`R$ ${ticket.ticket === "Free" ? "Gratuito" : (ticket.ticket.amount / 100).toFixed(2)}`}</span>
                      </div>
                    }
                    key={ticket.id}
                  >
                    {ticket.answers.length > 0 ? <h4>Respostas:</h4> : <h4 style={{ fontStyle: 'italic', opacity: '0.6' }}>Esse ingresso não possui respostas.</h4>}
                    {ticket.answers.map((answer, answerIndex) => (
                      <div key={answerIndex}>
                        <p><strong>{answer.question}: <br /></strong> {answer.answer}</p>
                      </div>
                    ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Content>}
          </Layout>
        </>
      )
      }
    </div >
  )
}
export default EventCheckoutDetails
