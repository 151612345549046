import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiAccountsList } from '../../../../../api/accounts';
import { apiSplitPlans } from '../../../../../api/splitPlans';
import './DivisionFees.scss';

const { Option } = Select;

const DivisionFees = ({ data, onSave, onDiscard, setUnsavedChanges, setDataToSave }) => {
  const [n1Options, setN1Options] = useState([]);
  const [n2Options, setN2Options] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [developerOptions, setDeveloperOptions] = useState([]);
  const [feePlanOptions, setFeePlanOptions] = useState([]);
  const [n1Loading, setN1Loading] = useState(false);
  const [n2Loading, setN2Loading] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [developerLoading, setDeveloperLoading] = useState(false);
  const [feePlanLoading, setFeePlanLoading] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [localData, setLocalData] = useState(data);
  const [originalData, setOriginalData] = useState(data);
  const [errors, setErrors] = useState({});
  const [distributors, setDistributors] = useState({});

  const fetchOptions = async (value, setOptions, setLoading, apiCall) => {
    setLoading(true);
    try {
      const response = await apiCall({ search: value });
      const results = response.results.reduce((acc, item) => {
        acc[item.id] = item.display_name || item.name;
        return acc;
      }, {});
      setDistributors(prev => ({ ...prev, ...results }));
      setOptions(response.results.map(item => ({
        value: item.id,
        label: item.display_name || item.name
      })));
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFeePlans = async (value = '') => {
    setFeePlanLoading(true);
    try {
      const response = await apiSplitPlans({ search: value });
      setFeePlanOptions(response.results.map(item => ({
        value: item.id,
        label: item.name
      })));
    } catch (error) {
      console.error("Erro ao buscar planos de taxa:", error);
    } finally {
      setFeePlanLoading(false);
    }
  };

  const handleDropdownVisibleChange = async (visible, setOptions, setLoading, apiCall) => {
    if (visible) {
      await fetchOptions('', setOptions, setLoading, apiCall);
    }
  };

  const handleSearch = async (value, setOptions, setLoading, apiCall) => {
    await fetchOptions(value, setOptions, setLoading, apiCall);
  };

  useEffect(() => {
    if (data) {
      const updatedData = {
        ...data,
        split: {
          ...data.split,
          n1_distributor: data.split.n1_distributor ? data.split.n1_distributor.id : null,
          n2_distributor: data.split.n2_distributor ? data.split.n2_distributor.id : null,
          agent: data.split.agent ? data.split.agent.id : null,
          developer: data.split.developer ? data.split.developer.id : null,
          plan: data.split.plan ? data.split.plan.id : null,
        }
      };
      setLocalData(updatedData);
      setOriginalData(updatedData);

      if (data.split.n1_distributor) {
        setDistributors(prev => ({
          ...prev,
          [data.split.n1_distributor.id]: data.split.n1_distributor.display_name
        }));
      }
      if (data.split.n2_distributor) {
        setDistributors(prev => ({
          ...prev,
          [data.split.n2_distributor.id]: data.split.n2_distributor.display_name
        }));
      }
      if (data.split.agent) {
        setDistributors(prev => ({
          ...prev,
          [data.split.agent.id]: data.split.agent.display_name
        }));
      }
      if (data.split.developer) {
        setDistributors(prev => ({
          ...prev,
          [data.split.developer.id]: data.split.developer.display_name
        }));
      }
      if (data.split.plan) {
        setFeePlanOptions(prev => [
          ...prev,
          { value: data.split.plan.id, label: data.split.plan.name }
        ]);
      }
    }

    fetchFeePlans();
  }, [data]);

  useEffect(() => {
    if (localData) {
      setDataToSave(getChangedFields(localData, originalData));
      validateFields(localData);
    }
  }, [localData, setDataToSave]);

  const validateFields = (data) => {
    const newErrors = {};
    if (!data?.split?.n1_distributor) newErrors.n1_distributor = 'Campo obrigatório';
    if (!data?.split?.n2_distributor) newErrors.n2_distributor = 'Campo obrigatório';
    if (!data?.split?.agent) newErrors.agent = 'Campo obrigatório';
    if (!data?.split?.developer) newErrors.developer = 'Campo obrigatório';
    if (!data?.split?.plan) newErrors.plan = 'Campo obrigatório';
    setErrors(newErrors);
    return newErrors;
  };

  const getChangedFields = (currentData, originalData) => {
    const changedFields = {};

    const checkChanges = (current, original, path = '') => {
      for (const key in current) {
        if (current[key] !== original[key]) {
          if (typeof current[key] === 'object' && current[key] !== null) {
            checkChanges(current[key], original[key] || {}, `${path}${key}.`);
          } else {
            changedFields[`${path}${key}`] = current[key];
          }
        }
      }
    };

    checkChanges(currentData, originalData);
    return changedFields;
  };

  const handleSave = async () => {
    const newErrors = validateFields(localData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const changes = getChangedFields(localData, originalData);
        if (Object.keys(changes).length > 0) {
          await onSave(changes);
          setOriginalData(localData);
        }

        setEnableEdit(false);
        setUnsavedChanges(false);
      } catch (error) {
        console.error("Erro ao salvar as mudanças:", error);
        alert('Ocorreu um erro ao salvar. Tente novamente.');
      }
    } else {
      alert('Por favor, corrija os campos obrigatórios.');
    }
  };

  const handleDiscard = async () => {
    await onDiscard();
    setEnableEdit(false);
    setUnsavedChanges(false);
  };

  const handleSelectChange = (value, field) => {
    setLocalData(prevData => {
      const newData = {
        ...prevData,
        split: {
          ...prevData.split,
          [field]: value
        }
      };
      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const getDisplayName = (id) => distributors[id] || 'Não informado';

  return (
    <div className='DivisionFees'>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className='subtitle-page'>Divisão de Taxas</p>
        <Button
          onClick={() => setEnableEdit(!enableEdit)}
          type={enableEdit ? "primary" : ""}
        >
          {enableEdit ? "Cancelar" : "Editar"}
        </Button>
      </div>
      <Divider />
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item label="Plano de Taxa">
              <div className='input-container'>
                <Select
                  showSearch
                  value={localData?.split?.plan || undefined}
                  className={errors.plan ? 'error-select' : ''}
                  placeholder="Selecione um plano de taxa"
                  onChange={(value) => handleSelectChange(value, 'plan')}
                  onSearch={(value) => handleSearch(value, setFeePlanOptions, setFeePlanLoading, apiSplitPlans)}
                  onDropdownVisibleChange={(visible) => handleDropdownVisibleChange(visible, setFeePlanOptions, setFeePlanLoading, apiSplitPlans)}
                  notFoundContent={feePlanLoading ? "Carregando..." : "Nenhum plano de taxa encontrado"}
                  style={{ width: '100%' }}
                  filterOption={false}
                  loading={feePlanLoading}
                  disabled={!enableEdit}
                >
                  {feePlanOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {localData?.split?.plan && (
                  <EyeOutlined
                    onClick={() => window.open(`/planos-e-taxas/detalhe/${localData.split.plan}`, "_blank")}
                    className='eye-icon'
                  />
                )}
              </div>
              {errors.plan && <div className="error-text">{errors.plan}</div>}
            </Form.Item>
            <Form.Item label="Agente">
              <div className='input-container'>
                <Select
                  showSearch
                  value={getDisplayName(localData.split.agent) || localData?.split?.agent || undefined}
                  className={errors.agent ? 'error-select' : ''}
                  placeholder="Selecione um agente"
                  onChange={(value) => handleSelectChange(value, 'agent')}
                  onSearch={(value) => handleSearch(value, setAgentOptions, setAgentLoading, apiAccountsList)}
                  onDropdownVisibleChange={(visible) => handleDropdownVisibleChange(visible, setAgentOptions, setAgentLoading, apiAccountsList)}
                  notFoundContent={agentLoading ? "Carregando..." : "Nenhum agente encontrado"}
                  style={{ width: '100%' }}
                  filterOption={false}
                  loading={agentLoading}
                  disabled={!enableEdit}
                >
                  {agentOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {localData?.split?.agent && (
                  <EyeOutlined
                    onClick={() => window.open(`/lojista/detalhes/${localData.split.agent}`, "_blank")}
                    className='eye-icon'
                  />
                )}
              </div>
              {errors.agent && <div className="error-text">{errors.agent}</div>}
            </Form.Item>
            <Form.Item label="Desenvolvedor">
              <div className='input-container'>
                <Select
                  showSearch
                  value={getDisplayName(localData.split.developer) || localData?.split?.developer || undefined}
                  className={errors.developer ? 'error-select' : ''}
                  placeholder="Selecione um desenvolvedor"
                  onChange={(value) => handleSelectChange(value, 'developer')}
                  onSearch={(value) => handleSearch(value, setDeveloperOptions, setDeveloperLoading, apiAccountsList)}
                  onDropdownVisibleChange={(visible) => handleDropdownVisibleChange(visible, setDeveloperOptions, setDeveloperLoading, apiAccountsList)}
                  notFoundContent={developerLoading ? "Carregando..." : "Nenhum desenvolvedor encontrado"}
                  style={{ width: '100%' }}
                  filterOption={false}
                  loading={developerLoading}
                  disabled={!enableEdit}
                >
                  {developerOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {localData?.split?.developer && (
                  <EyeOutlined
                    onClick={() => window.open(`/lojista/detalhes/${localData.split.developer}`, "_blank")}
                    className='eye-icon'
                  />
                )}
              </div>
              {errors.developer && <div className="error-text">{errors.developer}</div>}
            </Form.Item>
          </Form>
        </div>
        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item label="Distribuidor N1">
              <div className='input-container'>
                <Select
                  showSearch
                  value={getDisplayName(localData.split.n1_distributor) || localData?.split?.n1_distributor || undefined}
                  className={errors.n1_distributor ? 'error-select' : ''}
                  placeholder="Selecione um distribuidor N1"
                  onChange={(value) => handleSelectChange(value, 'n1_distributor')}
                  onSearch={(value) => handleSearch(value, setN1Options, setN1Loading, apiAccountsList)}
                  onDropdownVisibleChange={(visible) => handleDropdownVisibleChange(visible, setN1Options, setN1Loading, apiAccountsList)}
                  notFoundContent={n1Loading ? "Carregando..." : "Nenhum distribuidor encontrado"}
                  style={{ width: '100%' }}
                  filterOption={false}
                  loading={n1Loading}
                  disabled={!enableEdit}
                >
                  {n1Options.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {localData?.split?.n1_distributor && (
                  <EyeOutlined
                    onClick={() => window.open(`/lojista/detalhes/${localData.split.n1_distributor}`, "_blank")}
                    className='eye-icon'
                  />
                )}
              </div>
              {errors.n1_distributor && <div className="error-text">{errors.n1_distributor}</div>}
            </Form.Item>
            <Form.Item label="Distribuidor N2">
              <div className='input-container'>
                <Select
                  showSearch
                  value={getDisplayName(localData.split.n2_distributor) || localData?.split?.n2_distributor || undefined}
                  className={errors.n2_distributor ? 'error-select' : ''}
                  placeholder="Selecione um distribuidor N2"
                  onChange={(value) => handleSelectChange(value, 'n2_distributor')}
                  onSearch={(value) => handleSearch(value, setN2Options, setN2Loading, apiAccountsList)}
                  onDropdownVisibleChange={(visible) => handleDropdownVisibleChange(visible, setN2Options, setN2Loading, apiAccountsList)}
                  notFoundContent={n2Loading ? "Carregando..." : "Nenhum distribuidor encontrado"}
                  style={{ width: '100%' }}
                  filterOption={false}
                  loading={n2Loading}
                  disabled={!enableEdit}
                >
                  {n2Options.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {localData?.split?.n2_distributor && (
                  <EyeOutlined
                    onClick={() => window.open(`/lojista/detalhes/${localData.split.n2_distributor}`, "_blank")}
                    className='eye-icon'
                  />
                )}
              </div>
              {errors.n2_distributor && <div className="error-text">{errors.n2_distributor}</div>}
            </Form.Item>
          </Form>
        </div>
      </div>
      {
        enableEdit && (
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 10 }}>
            <Button onClick={handleSave} type="primary">Salvar</Button>
            <Button onClick={handleDiscard}>Descartar</Button>
          </div>
        )
      }
    </div>
  );
};

export default DivisionFees;
