import { message } from 'antd';
import React from 'react';
import { ReactComponent as Share } from "../../../../assets/images/ticket/share.svg";
import { ReactComponent as WhatsApp } from "../../../../assets/images/ticket/whatsapp.svg";
import './ShareModal.scss';

const ShareModal = ({ event, isOpen, onClose }) => {
  const handleCopyLink = () => {
    const eventUrl = `${window.location.origin}/evento/${encodeURIComponent(event.name)}/${event.id}`;
    navigator.clipboard.writeText(eventUrl).then(() => {
      message.success("Link copiado para a área de transferência!");
    }).catch(err => {
      message.error("Falha ao copiar o link.");
    });
  };

  const handleShareWhatsApp = () => {
    const eventUrl = `${window.location.origin}/evento/${encodeURIComponent(event.name)}/${event.id}`;
    const text = `Confira este evento incrível: ${eventUrl}`;
    const encodedText = encodeURIComponent(text);
    window.open(`https://wa.me/?text=${encodedText}`, '_blank', 'noopener,noreferrer');
  };

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay`} onClick={onClose}>
      <div className={`modal-content  ${isOpen ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>&times;</span>
        <p>Compartilhar com família e amigos</p>
        <img className='image-modal' src={event?.image_url} alt="Evento" />
        <div className="share-buttons">
          <button onClick={handleCopyLink}> <Share />Copiar link</button>
          <button onClick={handleShareWhatsApp}> <WhatsApp />WhatsApp</button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
