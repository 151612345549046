import React, { useState } from 'react'
import { Breadcrumb, Button, Divider, Input, Layout, Table, theme } from 'antd'
import { Content } from 'antd/es/layout/layout';
import './PriceList.scss'
import { priceMock } from './saleInfoMock';

const PriceList = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  const columns = [
    {
      title: 'Método de pagamento',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 200, // Defina a largura desejada para a coluna
    },
    {
      title: 'Visa',
      dataIndex: 'visa',
      key: 'visa',
      // width: '12%',
    },
    {
      title: 'Hiper',
      dataIndex: 'hiper',
      key: 'hiper',
      // width: '12%',
    },
    {
      title: 'Elo',
      dataIndex: 'elo',
      key: 'elo',
      // width: '12%',
    },
    {
      title: 'MasterCard',
      dataIndex: 'mastercard',
      key: 'mastercard',
      // width: '12%',
    },
    {
      title: 'Aura',
      dataIndex: 'aura',
      key: 'aura',
      // width: '12%',
    },
    {
      title: 'American Express',
      dataIndex: 'american',
      key: 'american',
      // width: '12%',
    },
    {
      title: 'MasterCard',
      dataIndex: 'mastercard',
      key: 'mastercard',
      // width: '12%',
    },
    {
      title: 'Aura',
      dataIndex: 'aura',
      key: 'aura',
      // width: '12%',
    },
    {
      title: 'American Express',
      dataIndex: 'american',
      key: 'american',
      // width: '12%',
    },
    // {
    //   title: 'Valor',
    //   dataIndex: 'subTitle',
    //   // width: '30%',
    //   key: 'subTitle',
    // },
  ];
  const [checkStrictly, setCheckStrictly] = useState(false);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  return (
    <div className='PriceList'>
      <Layout
        style={{
          padding: '1.25rem',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <h2 className='title-page'>TESTE</h2>
          <Divider />
          <Breadcrumb.Item>R$ 40,00</Breadcrumb.Item>
          <Breadcrumb.Item>VENDA #11972046</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className='container-subtitle'>
            <h3 className='subtitle-page'>TESTE</h3>
          </div>
          <Divider />
          <div className="container-table" >
            <Table
              columns={columns}
              scroll={{ x: true }}
              pagination={false}
              // rowSelection={{ ...rowSelection, checkStrictly }}
              dataSource={priceMock}
            />
          </div>
        </Content>
      </Layout>

    </div>
  )
}

export default PriceList
