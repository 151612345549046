import React, { useState } from 'react';
import './DetailsComponent.scss';

const DetailsComponent = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="details-container">
      <button onClick={toggleOpen} className="summary">
        <span className="title">{title}</span>
        <span className="icon">
          {isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="rgb(0, 151, 255)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6 15l6-6l6 6" /></svg>

          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="rgb(0, 151, 255)" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z" /></svg>
          )}
        </span>
      </button>
      <div className={`content-details ${isOpen ? 'open' : 'closed'}`}>
        {content}
      </div>
    </div>
  );
};

export default DetailsComponent;
