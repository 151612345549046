import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiWebhookList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/integrations/webhooks/${getSellerID()}/webhooks`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCreateWebhook = async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/webhooks/${getSellerID()}/webhooks/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiEditWebhook = async (id, jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/webhooks/${getSellerID()}/webhooks/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDetailsWebhook = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/webhooks/${getSellerID()}/webhooks/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteWebhook = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/webhooks/${getSellerID()}/webhooks/${id}`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    if (response.status === 204) {
      return { success: true, message: "Webhook excluído com sucesso!" };
    } else {
      const data = await response.json();
      throw new Error(data.message || "Erro ao excluir o webhook");
    }
  } catch (error) {
    throw error;
  }
};