import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiTransactionDetail = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/transactions/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiTransactionsList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/transactions`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiTransactionsDisputesList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/chargebacks`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiVoidTransaction = async (seller_id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/transactions/${seller_id}/void/`, {
      method: "POST",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiTransactionDownload = async (format, filters) => {
  try {
    const acceptHeader =
      format === 'csv'
        ? 'text/csv'
        : 'application/vnd.ms-excel';

    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/sales/seller/${getSellerID()}/transactions/report/`, filters),
      {
        method: 'GET',
        headers: {
          ...buildHeaders(),
          Accept: acceptHeader,
        },
      }
    );

    return response.blob();
  } catch (error) {
    throw error;
  }
};