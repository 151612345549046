import React from 'react';
import { Table } from 'antd';
import { formatPrice } from '../../../../utils/format';

const SplitRule = ({ data, originalAmount }) => {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (text, record) => {
        if (record.seller.business_name) {
          return record.seller.business_name;
        } else {
          return `${record.seller.first_name} ${record.seller.last_name}`;
        }
      },
    },
    {
      title: 'Porcentagem',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (text, record) => {
        if (record.percentage === "0.00") {
          const percentage = ((record.amount / originalAmount) * 100).toFixed(2);
          return <p>{percentage}%</p>;
        }
        return <p>{record.percentage}%</p>;
      },
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return <p>{formatPrice(record?.amount)}</p>
      },
    },
  ];
  
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      rowKey={(record, index) => index} 
    />
  );
};

export default SplitRule;
