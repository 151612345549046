import React from 'react'
import { Breadcrumb, Divider, Input, Layout, theme } from 'antd'
import './Accreditation.scss'
import { Content } from 'antd/es/layout/layout'
import { LinkOutlined } from '@ant-design/icons'

const Accreditation = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className='Accreditation'>
      <Layout
        style={{
          padding: '1.25rem',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <h2 className='title-page'>Credenciamento</h2>
          <Divider />
          <Breadcrumb.Item>Credenciamento</Breadcrumb.Item>
          <Breadcrumb.Item>Cadastrar novo estabelecimento</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className='subtitle-page'>Formulários de credenciamento</h3>
          <Divider />
          <div className="container-inputs">
            <div className="box-input">
              <p>1. Agente</p>
              <div>
                <button><LinkOutlined /></button>
                <Input type='link' value='https://dl.performerinc.com/register.php?id=Rr17HpfkEG' />
              </div>
            </div>

            <div className="box-input">
              <p>2. Distribuidor N1</p>
              <div>
                <button><LinkOutlined /></button>
                <Input type='link' value='https://dl.performerinc.com/register.php?id=490dFlfloD' />
              </div>
            </div>

            <div className="box-input">
              <p>3. Distribuidor N2</p>
              <div>
                <button><LinkOutlined /></button>
                <Input type='link' value='https://dl.performerinc.com/register.php?id=qLV0S9fxKx' />
              </div>
            </div>

            <div className="box-input">
              <p>4. Lojista</p>
              <div>
                <button><LinkOutlined /></button>
                <Input type='link' value='https://dl.performerinc.com/register.php?id=JzdDuofJm6' />
              </div>
            </div>

          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default Accreditation
