import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiApiKeyList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/integrations/apikeys/${getSellerID()}/apikeys`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCreateApiKey= async (jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/apikeys/${getSellerID()}/apikeys/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiEditApiKey = async (id, jsonData) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/apikeys/${getSellerID()}/apikeys/${id}/`, {
      method: "PATCH",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDetailsApiKey = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/apikeys/${getSellerID()}/apikeys/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteApiKey = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/integrations/apikeys/${getSellerID()}/apikeys/${id}`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    if (response.status === 204) {
      return { success: true, message: "ApiKey excluído com sucesso!" };
    } else {
      const data = await response.json();
      throw new Error(data.message || "Erro ao excluir o webhook");
    }
  } catch (error) {
    throw error;
  }
};