export const priceMock = [
  {
    key: 1,
    name: 'Débito',
    secondValue: 60,
    subTitle: '1',
    visa: '10',
    american: '10',
    aura: '10',
    elo: '10',
    mastercard: '10',
    hiper: '10',
    children: [
      {
        key: 11,
        name: '1x',
        subTitle: '5,00',
        visa: '10',
        american: '10',
        aura: '10',
        elo: '10',
        mastercard: '10',
        hiper: '10',
        children: [
          {
            key: 1212,
            name: 'Taxa-base',
            secondValue: 16,
            subTitle: '3,80',
            visa: '10',
            american: '10',
            aura: '10',
            elo: '10',
            mastercard: '10',
            hiper: '10',
          },
          {
            key: 12,
            name: 'Taxa-base',
            secondValue: 30,
            subTitle: '1,20',
            visa: '10',
            american: '10',
            aura: '10',
            elo: '10',
            mastercard: '10',
            hiper: '10',
          },
        ],
      },

    ],
  },
  {
    key: 2,
    name: 'Crédito',
    secondValue: 60,
    subTitle: '12',
    visa: '10',
    american: '10',
    aura: '10',
    elo: '10',
    mastercard: '10',
    hiper: '10',
    children: [
      {
        key: 113,
        name: '1x',
        secondValue: 42,
        subTitle: '5,00',
        visa: '10',
        american: '10',
        aura: '10',
        elo: '10',
        mastercard: '10',
        hiper: '10',
        children: [
          {
            key: 121,
            name: 'Taxa-base',
            secondValue: 16,
            subTitle: '3,80',
            visa: '10',
            american: '10',
            aura: '10',
            elo: '10',
            mastercard: '10',
            hiper: '10',
            children: [
              {
                key: 1215,
                name: 'Taxa-base',
                secondValue: 16,
                subTitle: '3,80',
                visa: '10',
                american: '10',
                aura: '10',
                elo: '10',
                mastercard: '10',
                hiper: '10',
              },
              {
                key: 126,
                name: 'Taxa-base',
                secondValue: 30,
                subTitle: '1,20',
                visa: '10',
                american: '10',
                aura: '10',
                elo: '10',
                mastercard: '10',
                hiper: '10',
              },
            ],
          },
        ],
      },
      {
        key: 13,
        name: '2x',
        secondValue: 42,
        subTitle: '5,00',
        visa: '10',
        american: '10',
        aura: '10',
        elo: '10',
        mastercard: '10',
        hiper: '10',
        children: [
          {
            key: 122,
            name: 'Taxa-base',
            secondValue: 16,
            subTitle: '3,80',
            visa: '10',
            american: '10',
            aura: '10',
            elo: '10',
            mastercard: '10',
            hiper: '10',
            children: [
              {
                key: 1213,
                name: 'Taxa-base',
                secondValue: 16,
                subTitle: '3,80',
                visa: '10',
                american: '10',
                aura: '10',
                elo: '10',
                mastercard: '10',
                hiper: '10',
              },
              {
                key: 1225,
                name: 'Taxa-base',
                secondValue: 30,
                subTitle: '1,20',
                visa: '10',
                american: '10',
                aura: '10',
                elo: '10',
                mastercard: '10',
                hiper: '10',
              },
            ],
          },
        ],
      },

    ],
  },
];