import React from 'react';
import './ExpiryDateSelector.scss';

const ExpiryDateSelector = ({ selectedMonth, selectedYear, onMonthChange, onYearChange }) => {
  return (
    <div className="field small">
      <div className="title">Data de Validade</div>
      <select id="cc-valid-month" className="input ddl" value={selectedMonth} onChange={onMonthChange}>
        {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((m) => (
          <option key={m} value={m}>{m}</option>
        ))}
      </select>
      <select id="cc-valid-year" className="input ddl" value={selectedYear} onChange={onYearChange}>
        {['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37'].map((y) => (
          <option key={y} value={y}>{y}</option>
        ))}
      </select>
    </div>
  );
};

export default ExpiryDateSelector;