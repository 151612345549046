import React from 'react';
import './ProgressBar.scss';

const Progress = ({min, max, label, currentStep }) => {
  const steps = ['Perfil', 'Dados', 'Endereço', 'Banco', 'Docs']; // Títulos das etapas
  const stepWidth = 100 / (steps.length - 1); // Largura de cada etapa em percentual

  return (
    <div className="progress-container">
      {steps.map((step, index) => (
        <div key={index} className={`step ${index <= currentStep ? 'passed' : ''} ${index === currentStep ? 'active' : ''}`}>
          <div className="bullet" />
          <span className="step-title">{step}</span>
        </div>
      ))}
      <progress
        value={(currentStep) * stepWidth}
        max="100"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={(currentStep) * stepWidth}
        data-name={steps[currentStep]}
      />
    </div>
  );
};

export default Progress;
