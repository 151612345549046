import { Skeleton } from 'antd';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiGetEventNoAuth } from '../../../../api/events';
import { apiSimulationTax } from '../../../../api/simulation';
import { apiCreateTicket, apiGetCheckout, apiPayCheckout } from '../../../../api/ticket';
import logo from "../../../../assets/images/logo.png";
import cardImage from '../../../../assets/images/ticket/card-image.png';
import { ReactComponent as CardPayment } from "../../../../assets/images/ticket/card-payment.svg";
import { ReactComponent as Card } from "../../../../assets/images/ticket/card.svg";
import pixImage from '../../../../assets/images/ticket/pix-image.png';
import { ReactComponent as Pix } from "../../../../assets/images/ticket/pix.svg";
import { useIsMobileHook } from "../../../../utils/WindowHooks";
import { validateCardNumber, validateEmail, validateName, validatePhone, validateTaxpayerID } from '../../../../utils/validatesCheckout';
import DateInput from '../../../common/DateInput/DateInput';
import InputCheckout from '../../../common/InputCheckout/InputCheckout';
import CheckoutQuestions from '../CheckoutQuestions/CheckoutQuestions';
import InfoModal from '../InfoModal/InfoModal';
import './CheckoutTicket.scss';

const CheckoutTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, eventID } = useParams();
  const isMobile = useIsMobileHook(992);
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const { tickets, total, data } = location.state || {};
  const [questionsByTicket, setQuestionsByTicket] = useState({});
  const [dataTicket, setDataTicket] = useState();
  const [dataTicketCheckout, setDataTicketCheckout] = useState();
  const [eventData, setEventData] = useState();
  const [ticketsData, setTicketsData] = useState();

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const openInfoModal = () => setIsInfoModalOpen(true);

  const [isInfoTimeModalOpen, setIsInfoTimeModalOpen] = useState(false);
  const openInfoTimeModal = () => setIsInfoTimeModalOpen(true);
  useEffect(() => {
    if (eventData) {
      try {
        const startDate = parseISO(eventData.starts_at);
        const endDate = parseISO(eventData.ends_at);

        const formattedStart = format(startDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });
        const formattedEnd = format(endDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });

        setFormattedStartDate(formattedStart);
        setFormattedEndDate(formattedEnd);
      } catch (error) {
        console.error('Error parsing or formatting date:', error);
      }
    }
  }, [eventData]);

  const calculateTotal = (tickets) => {
    if (!tickets || tickets.length === 0) return 0;

    const totalInCents = tickets.reduce((acc, ticket) =>
      acc + (ticket.price * ticket.quantity), 0
    );

    return totalInCents;
  };


  const renderPriceStash = (price) => {
    if (price === 0) {
      return 'Gratuito';
    }

    const formattedPrice = (price / 100).toFixed(2);

    return `R$ ${formattedPrice.replace('.', ',')}`;
  };

  const renderPrice = (price) => {
    if (price === 0) {
      return 'Gratuito';
    }

    return `R$ ${price?.toFixed(2).replace('.', ',')}`;
  };

  const generateQuestionsByTicket = (tickets) => {
    const groupedQuestions = {};

    tickets.forEach((ticket) => {
      // Garante que cada bilhete tenha um array questions, mesmo que vazio
      groupedQuestions[ticket.id] = {
        title: ticket.name,
        questions: ticket.questions && ticket.questions.length > 0
          ? ticket.questions.flatMap((question) =>
            Array(ticket.quantity).fill().map((_, index) => ({
              question_id: question.id,
              question_label: question.prompt,
              question_type: question.type,
              options: question.values,
              uniqueKey: `${question.id}-${ticket.id}-${index}`,
              answer: ''
            }))
          )
          : []  // Se não houver perguntas, define questions como um array vazio
      };
    });

    return groupedQuestions;
  };

  useEffect(() => {
    if (tickets) {
      const generatedQuestions = generateQuestionsByTicket(tickets);
      setQuestionsByTicket(generatedQuestions);
    }
  }, [tickets]);

  const handleAnswerChange = (ticketId, uniqueKey, answer) => {
    setQuestionsByTicket(prevQuestions => ({
      ...prevQuestions,
      [ticketId]: {
        ...prevQuestions[ticketId],
        questions: prevQuestions[ticketId].questions.map(q =>
          q.uniqueKey === uniqueKey ? { ...q, answer } : q
        )
      }
    }));
  };

  const hasQuestions = Object.keys(questionsByTicket).some(ticketId => questionsByTicket[ticketId].questions.length > 0);

  const prepareDataForApi = () => {
    const hasQuestionsTeste = Object.keys(questionsByTicket).some(ticketId => questionsByTicket[ticketId].questions.length > 0);

    const apiData = [];

    Object.keys(questionsByTicket).forEach(ticketId => {
      const ticket = questionsByTicket[ticketId];
      const ticketQuantity = tickets.find(t => t.id === ticketId)?.quantity || 0;

      if (ticketQuantity > 0) {
        for (let i = 0; i < ticketQuantity; i++) {
          apiData.push({
            ticket: ticketId,
            question_answers: hasQuestionsTeste
              ? (ticket.questions || [])
                .filter(q => q.uniqueKey.startsWith(`${q.question_id}-${ticketId}-${i}`))
                .map(q => ({
                  question_id: q.question_id,
                  answer: q.answer
                }))
              : []
          });
        }
      }
    });

    return apiData;
  };
  const [errorForm, setErrorForm] = useState({ error: [] });

  const validateForm = () => {
    const errors = [];

    Object.keys(questionsByTicket).forEach(ticketId => {
      const ticketQuestions = questionsByTicket[ticketId].questions;

      ticketQuestions.forEach(question => {
        if (!question.answer || question.answer.trim() === '') {
          errors.push(question.uniqueKey);
        }
      });
    });

    return errors.length > 0 ? errors : null;
  };

  const [loadingFetchingInstallments, setLoadingFetchingInstallments] = useState(false);
  const handleCreateTicket = async () => {
    const validationErrors = validateForm();

    if (validationErrors) {
      setErrorForm({ error: validationErrors });
      return;
    }

    if (validate()) {
      setLoadingFetchingInstallments(true);
      const payload = {
        customer: {
          name: formData.customerName,
          email: formData.customerEmail,
          phone_number: formData.customerPhone,
        }
      }
      const data = await apiCreateTicket(eventID, { tickets: prepareDataForApi(), ...payload });
      setDataTicket(data)
      navigate(`/evento/checkout/${eventID}/${data.id}`, { state: { data: data, tickets, total, eventData, dataTicket } });
      setLoadingFetchingInstallments(false);
    }
  };

  // PAYMENT FORM
  const [paymentType, setPaymentType] = useState('card');
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');


  const handleMonthChange = (month) => {
    setSelectedMonth(month);

    setErrors(prevErrors => ({
      ...prevErrors,
      selectedMonth: null
    }));
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setErrors(prevErrors => ({
      ...prevErrors,
      selectedYear: null
    }));
  };

  const [formData, setFormData] = useState({
    customerName: '',
    customerEmail: '',
    customerConfirmationEmail: '',
    customerPhone: '',
    ccNumber: '',
    ccName: '',
    ccCvv: '',
    taxpayer: '',
    addressCep: '',
    addressLine1: '',
    addressNumber: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressComplement: '',
  });

  const [validFields, setValidFields] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateField(name, value);
  };

  const validatePayment = () => {
    const newErrors = {};
    if (dataTicketCheckout && paymentType === 'card') {
      const ccNameError = validateName(formData?.ccName);
      if (ccNameError) {
        newErrors.ccName = ccNameError;
      }
      if (!formData.ccCvv) newErrors.ccCvv = 'Esse campo é obrigatório';

      const ccNumberError = validateCardNumber(formData?.ccNumber);
      if (ccNumberError) {
        newErrors.ccNumber = ccNumberError;
      }

      if (selectedInstallment === null) newErrors.installments = 'Esse campo é obrigatório';

      // Validação do mês do cartão
      if (!selectedMonth || selectedMonth < 1 || selectedMonth > 12) {
        newErrors.selectedMonth = 'Mês inválido';
      }

      // Tratamento de ano no formato de dois dígitos
      let fullYear = parseInt(selectedYear, 10);
      if (fullYear < 100) {
        fullYear += (fullYear < 50 ? 2000 : 1900); // Assumindo anos < 50 como 2000+ e anos >= 50 como 1900+
      }

      // Validação do ano do cartão
      if (!selectedYear || fullYear < new Date().getFullYear()) {
        newErrors.selectedYear = 'Ano inválido';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const validate = () => {
    const newErrors = {};
    const nameError = validateName(formData?.customerName);
    if (nameError) {
      newErrors.customerName = nameError;
    }

    const emailError = validateEmail(formData?.customerEmail);
    if (emailError) {
      newErrors.customerEmail = emailError;
    }

    const emailConfirmationError = validateEmail(formData?.customerConfirmationEmail);
    if (emailConfirmationError) {
      newErrors.customerConfirmationEmail = emailConfirmationError;
    }

    const phoneError = validatePhone(formData?.customerPhone);
    if (phoneError) {
      newErrors.customerPhone = phoneError;
    }

    if (formData?.customerEmail !== formData?.customerConfirmationEmail) {
      newErrors.customerConfirmationEmail = 'O e-mail de confirmação deve ser igual ao e-mail informado.';
    }

    if (dataTicketCheckout && paymentType === 'card') {
      const ccNameError = validateName(formData?.ccName);
      if (ccNameError) {
        newErrors.ccName = ccNameError;
      }
      if (!formData.ccCvv) newErrors.ccCvv = 'Esse campo é obrigatório';

      const ccNumberError = validateCardNumber(formData?.ccNumber);
      if (ccNumberError) {
        newErrors.ccNumber = ccNumberError;
      }

      if (selectedInstallment === null) newErrors.installments = 'Esse campo é obrigatório';

      // Validação do mês do cartão
      if (!selectedMonth || selectedMonth < 1 || selectedMonth > 12) {
        newErrors.selectedMonth = 'Mês inválido';
      }

      // Tratamento de ano no formato de dois dígitos
      let fullYear = parseInt(selectedYear, 10);
      if (fullYear < 100) {
        fullYear += (fullYear < 50 ? 2000 : 1900); // Assumindo anos < 50 como 2000+ e anos >= 50 como 1900+
      }

      // Validação do ano do cartão
      if (!selectedYear || fullYear < new Date().getFullYear()) {
        newErrors.selectedYear = 'Ano inválido';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateField = (name, value) => {
    let error = '';
    let isValid = true;

    if (name === 'customerName') {
      error = validateName(value);
      isValid = error === '';
    }

    if (name === 'customerPhone') {
      error = validatePhone(value);
      isValid = error === '';
    }

    if (name === 'customerEmail') {
      error = validateEmail(value);
      isValid = error === '';
    }
    // INITIAL FORMS VALIDATIONS

    // CARD VALIDATIONS
    if (name === 'ccName') {
      error = validateName(value);
      isValid = error === '';
    }

    if (name === 'ccNumber') {
      error = validateCardNumber(value);
      isValid = error === '';
    }

    if (name === 'taxpayer') {
      error = validateTaxpayerID(value);
      isValid = error === '';
    }

    if (value.trim() === '' && !error) {
      error = 'Este campo é obrigatório';
      isValid = false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setValidFields((prevValid) => ({
      ...prevValid,
      [name]: isValid,
    }));
  };

  const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
  };
  const [installments, setInstallments] = useState([]);

  const getInstallmentValue = (data, installment, buyerLiable) => {
    let installments = data[buyerLiable ? "buyer_liable" : "seller_liable"]["credit"]
    installment = installments[installment]
    let maxValue = {}
    for (let brand in installment) {
      let value = installment[brand]
      if (!parseFloat(maxValue.amount) || parseFloat(value.amount) > parseFloat(maxValue.amount)) {
        maxValue = value
      }
    }
    return maxValue
  }
  const fetchInstallments = async (max_installments, max_installments_no_tax, sale_price, sale_seller_id) => {
    try {
      const data = await apiSimulationTax(sale_price, sale_seller_id);
      const newInstallments = [];

      for (let i = 0; i < parseInt(max_installments > 12 ? 12 : max_installments); i++) {
        const hasTax = (i + 1) > parseInt(max_installments_no_tax);
        const value = getInstallmentValue(data, i + 1, hasTax);
        const installmentFormatted = (parseFloat(value.amount) / (i + 1)).toFixed(2).replace(".", ",");

        newInstallments.push({
          text: `${i + 1}x de R$ ${installmentFormatted} ${hasTax ? "(com juros)" : "(sem juros)"}`,
          installments: i + 1
        });
      }

      setInstallments(newInstallments);
    } catch (error) {
      console.error('Erro ao buscar parcelas', error);
    } finally {
      setLoading(false);
    }
  };

  const formatToBRL = (amount) => {
    const formattedAmount = (amount / 100).toFixed(2);
    return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  //TIMER
  const [timeLeft, setTimeLeft] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  useEffect(() => {
    apiGetEventNoAuth(eventID).then(data => {
      setEventData(data);
    });
  }, [eventID]);

  useEffect(() => {
    if (eventID && id) {
      setLoadingFetchingInstallments(true);
      apiGetEventNoAuth(eventID).then(dataEventNoAuth => {
        apiGetCheckout(eventID, id ? id : dataTicket?.id).then(data => {
          setDataTicketCheckout(data);
          setTicketsData(data.tickets);
          if ((!data.is_payable && !data.expired) || data.status === 'PAID' || data.status === 'FREE') {
            navigate(`/evento/pagamento/${eventID}/${data?.id}`, { state: { initial: true } });
          } else {
            fetchInstallments(data.payment_plan.maxInstallments, data.payment_plan.maxInstallmentsWithoutInterest, formatToBRL(data?.total), (data?.seller || data?.account));
            const duration = 15 * 60 * 1000; // 15 minutos em milissegundos
            const createdDate = new Date(data?.created_at);
            const endTime = createdDate.getTime() + duration;
            setEndTime(endTime);
          }
          setLoadingFetchingInstallments(false);
        });
      });


    }
  }, [eventID, id]);

  useEffect(() => {
    if (dataTicketCheckout) {
      const duration = 15 * 60 * 1000;
      if (!endTime) {
        const createdDate = new Date(dataTicketCheckout?.created_at);
        const calculatedEndTime = createdDate.getTime() + duration;
        setEndTime(calculatedEndTime);
      }

      if (endTime) {
        const updateTimer = () => {
          const now = Date.now();
          const timeRemaining = Math.max(0, Math.floor((endTime - now) / 1000));
          setTimeLeft(timeRemaining);

          if (timeRemaining <= 0) {
            openInfoTimeModal();
            return;
          }

          requestAnimationFrame(updateTimer);
        };

        updateTimer();

        return () => {
        };
      }
    }
  }, [endTime, location.state, openInfoTimeModal, id]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const handleChangeRadioGroup = (e) => {
    setSelectedInstallment(Number(e.target.value));

    setErrors(prevErrors => ({
      ...prevErrors,
      installments: null
    }));
  };

  const parseValueFromString = (text) => {
    const match = text.match(/R\$ ([\d,]+(\.\d+)?)/);
    return match ? parseFloat(match[1].replace(',', '.')) : 0;
  };

  const formatCurrency = (value) => {
    return `R$ ${value.toFixed(2).replace('.', ',')}`;
  };

  const selectedOption = installments.find(option => option.installments === selectedInstallment);
  const valuePerInstallment = selectedOption ? parseValueFromString(selectedOption.text) : 0;
  const totalValue = valuePerInstallment * (selectedOption ? selectedOption.installments : 0);
  const handleSubmit = () => {
    if (validatePayment()) {
      if (paymentType === 'pix') {
        const payload = {
          type: 'pix',
        }
        setLoadingFetchingInstallments(true);
        apiPayCheckout(eventData.id, dataTicketCheckout?.id, payload).then((data) => {
          setLoadingFetchingInstallments(false);
          navigate(`/evento/pagamento/${eventData.id}/${dataTicketCheckout?.id}`, { state: { pix: data, initial: true } });
        })
      }

      if (paymentType === 'card') {
        const payload = {
          type: 'credit_card',
          installments: selectedInstallment,
          card: {
            number: formData.ccNumber,
            name: formData.ccName,
            cvv: formData.ccCvv,
            expirationMonth: selectedMonth,
            expirationYear: selectedYear
          }
        }
        setLoadingFetchingInstallments(true);
        apiPayCheckout(eventData.id, dataTicketCheckout?.id, payload).then((data) => {
          setLoadingFetchingInstallments(false);
          navigate(`/evento/pagamento/${eventData.id}/${dataTicketCheckout?.id}`, { state: { card: data, initial: true } });
        }).catch((error) => {
          setLoadingFetchingInstallments(false);
          openInfoModal();
        })
      }

    }
  };

  const aggregateTickets = (tickets) => {
    const ticketMap = {};

    tickets && tickets.forEach(ticketData => {
      const { ticket } = ticketData;
      const { name, amount } = ticket;

      if (ticketMap[name]) {
        ticketMap[name].quantity += 1;
        ticketMap[name].price = amount;
      } else {
        ticketMap[name] = {
          id: ticket.id,
          name: ticket.name,
          quantity: 1,
          price: amount
        };
      }
    });

    return Object.values(ticketMap);
  };

  return (
    <>
      <Helmet>
        <title>Checkout - {eventData ? eventData.name : ''}</title>
      </Helmet>
      <div className='CheckoutTicket'>
        <div style={{ width: '100%', height: '66px', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '100%' }} />
        </div>
        <div className="container-checkout-ticket">
          <div className="container-checkout-ticket-box">
            <div className="title-checkout">
              <h1>{eventData?.name}</h1>
              <div className="event-information-container">
                <span className="mdi--clock-outline" />
                <div>
                  <p className="title">
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                </div>
              </div>
              <div className="event-information-container">
                <span className="carbon--location" />
                <div>
                  <p className="title">
                    {`${eventData?.address?.street}, ${eventData?.address?.number} - ${eventData?.address?.neighborhood}, ${eventData?.address?.city} - ${eventData?.address?.state}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="ticket-result">
              <div className='ticket-result-container'>
                <div className="ticket-result-header">
                  <div className="header">
                    <p>Resumo da compra</p>
                  </div>
                  <div className="ticket-result-items">
                    {ticketsData && aggregateTickets(ticketsData)?.map(ticket => (
                      <div className="ticket-item" key={ticket.id}>
                        <div>
                          <span className="ticket-name">{`${ticket.quantity}x ${ticket.name}`}</span>
                          <p className="price">
                            <span>{renderPriceStash(ticket.price)}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                    {!ticketsData && tickets?.map(ticket => (
                      <div className="ticket-item" key={ticket.id}>
                        <div>
                          <span className="ticket-name">{`${ticket.quantity}x ${ticket.name}`}</span>
                          <p className="price">
                            <span>{renderPrice(ticket.price)}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                    <div className="ticket-item fix">
                      <div>
                        <span className="ticket-name">Total</span>
                        <p className="price">
                          <span>{!ticketsData ? renderPrice(calculateTotal(tickets)) : renderPriceStash(calculateTotal(aggregateTickets(ticketsData)))}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {id ? <div className="ticket-time">
                  <div className="time-current">
                    <svg height="24" width="24" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.6668 20.4999V13.8333C21.6668 13.3912 21.4912 12.9673 21.1787 12.6547C20.8661 12.3422 20.4422 12.1666 20.0002 12.1666C19.5581 12.1666 19.1342 12.3422 18.8217 12.6547C18.5091 12.9673 18.3335 13.3912 18.3335 13.8333V22.1666C18.3335 22.6086 18.5091 23.0325 18.8217 23.3451C19.1342 23.6577 19.5581 23.8333 20.0002 23.8333H28.3335C28.7755 23.8333 29.1994 23.6577 29.512 23.3451C29.8246 23.0325 30.0002 22.6086 30.0002 22.1666C30.0002 21.7246 29.8246 21.3006 29.512 20.9881C29.1994 20.6755 28.7755 20.4999 28.3335 20.4999H21.6668ZM20.0002 7.16659C17.3631 7.16659 14.7852 7.94858 12.5926 9.41366C10.3999 10.8787 8.69094 12.9611 7.68177 15.3975C6.6726 17.8338 6.40856 20.5147 6.92303 23.1011C7.4375 25.6875 8.70737 28.0633 10.5721 29.928C12.4368 31.7927 14.8125 33.0626 17.399 33.5771C19.9854 34.0915 22.6663 33.8275 25.1026 32.8183C27.539 31.8092 29.6213 30.1002 31.0864 27.9075C32.5515 25.7149 33.3335 23.137 33.3335 20.4999C33.3335 16.9637 31.9287 13.5723 29.4283 11.0718C26.9278 8.57135 23.5364 7.16659 20.0002 7.16659ZM3.3335 20.4999C3.3335 17.2036 4.31098 13.9812 6.14234 11.2404C7.97369 8.4996 10.5767 6.36339 13.6221 5.10193C16.6675 3.84047 20.0187 3.51042 23.2517 4.1535C26.4847 4.79659 29.4544 6.38394 31.7853 8.71481C34.1162 11.0457 35.7035 14.0154 36.3466 17.2484C36.9897 20.4814 36.6596 23.8325 35.3982 26.878C34.1367 29.9234 32.0005 32.5264 29.2597 34.3577C26.5188 36.1891 23.2965 37.1666 20.0002 37.1666C15.5799 37.1666 11.3407 35.4106 8.21505 32.285C5.08944 29.1594 3.3335 24.9202 3.3335 20.4999H3.3335Z"></path></svg>
                    <p>{formatTime(timeLeft)}</p>
                  </div>
                  <p>Após este tempo, os ingressos serão liberados para venda novamente.</p>
                </div> : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="container-infos">
          <div className="container-infos-box">
            {loadingFetchingInstallments && <Skeleton active paragraph={{ rows: 10 }} />}
            {hasQuestions && !dataTicket?.tickets && !loadingFetchingInstallments && !dataTicketCheckout && !id &&
              <>
                <h1 className='title-checkout'>Perguntas iniciais</h1>
                {Object.keys(questionsByTicket).map(ticketId => {
                  const ticket = questionsByTicket[ticketId];
                  const hasAnswers = ticket.questions.some(q => q.prompt !== '');

                  if (hasAnswers) {
                    return (
                      <div key={ticketId}>
                        {Array.from({ length: tickets.find(t => t.id === ticketId).quantity }).map((_, index) => (
                          <div key={index}>
                            <h2>{`Ingresso nº${index + 1} - ${ticket.title}`}</h2>
                            <CheckoutQuestions
                              questions={ticket.questions.filter(q => q.uniqueKey.startsWith(`${q.question_id}-${ticketId}-${index}`))}
                              onAnswerChange={(uniqueKey, answer) => handleAnswerChange(ticketId, uniqueKey, answer)}
                              errorForm={errorForm}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  }
                  return null;
                })}

              </>}

            {!loadingFetchingInstallments && !dataTicketCheckout && !id &&
              <>
                <h3>Informação para o recebimento do ingresso</h3>
                <div className="container-data">

                  {isMobile && <>
                    <div className="row">
                      <InputCheckout
                        disabled={isDisabled}
                        label="Nome"
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleChange}
                        errorMessage={errors.customerName}
                        isValid={validFields.customerName}
                      />
                      <InputCheckout
                        disabled={isDisabled}
                        label="Telefone"
                        name="customerPhone"
                        value={formData.customerPhone}
                        onChange={handleChange}
                        errorMessage={errors.customerPhone}
                        isValid={validFields.customerPhone}
                      />
                    </div>
                    <div className="row">
                      <InputCheckout
                        disabled={isDisabled}
                        label="E-mail"
                        name="customerEmail"
                        type='email'
                        className="small"
                        value={formData.customerEmail}
                        onChange={handleChange}
                        errorMessage={errors.customerEmail}
                        isValid={validFields.customerEmail}
                      />
                      <InputCheckout
                        disabled={isDisabled}
                        label="Confirmação do e-mail"
                        name="customerConfirmationEmail"
                        type='email'
                        className="small"
                        value={formData.customerConfirmationEmail}
                        onChange={handleChange}
                        errorMessage={errors.customerConfirmationEmail}
                        isValid={validFields.customerConfirmationEmail}
                      />
                    </div>
                  </>}
                  {!isMobile && <>
                    <div className="row">
                      <InputCheckout
                        disabled={isDisabled}
                        label="Nome"
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleChange}
                        errorMessage={errors.customerName}
                        isValid={validFields.customerName}
                      />
                      <InputCheckout
                        disabled={isDisabled}
                        label="E-mail"
                        name="customerEmail"
                        type='email'
                        className="small"
                        value={formData.customerEmail}
                        onChange={handleChange}
                        errorMessage={errors.customerEmail}
                        isValid={validFields.customerEmail}
                      />
                    </div>
                    <div className="row">
                      <InputCheckout
                        disabled={isDisabled}
                        label="Telefone"
                        name="customerPhone"
                        value={formData.customerPhone}
                        onChange={handleChange}
                        errorMessage={errors.customerPhone}
                        isValid={validFields.customerPhone}
                      />
                      <InputCheckout
                        disabled={isDisabled}
                        label="Confirmação do e-mail"
                        name="customerConfirmationEmail"
                        type='email'
                        className="small"
                        value={formData.customerConfirmationEmail}
                        onChange={handleChange}
                        errorMessage={errors.customerConfirmationEmail}
                        isValid={validFields.customerConfirmationEmail}
                      />
                    </div>
                  </>}
                </div>
                <div className="ballon">
                  <div className="container-ballon">
                    {total === 0 ? "Os ingressos serão enviados por e-mail para esse email." : "Os ingressos serão enviados por e-mail assim que recebermos a confirmação do pagamento."}
                  </div>
                </div >
                <button className={`button-continue ${total === 0 ? "pay" : ""}`} onClick={handleCreateTicket}>{total === 0 ? 'Finalizar' : 'Continuar'}</button>
              </>
            }

            {!loadingFetchingInstallments && id &&
              <div className="infos-payment">
                <div className="ballon">
                  <div className="container-ballon">
                    Os ingressos serão enviados por e-mail assim que recebermos a confirmação do pagamento.
                  </div>
                </div >

                <div className="payment-type-container">
                  <h2>Informações de pagamento</h2>
                  <div className="payment-buttons">
                    <label className={`payment-type-button ${paymentType === 'card' ? 'active' : ''}`}>
                      <input
                        type="radio"
                        name="paymentType"
                        value="card"
                        checked={paymentType === 'card'}
                        onChange={() => handlePaymentTypeChange('card')}
                      />
                      <span className="custom-radio"></span>
                      <Card />
                      Cartão de Crédito
                      <div className="tag-card">
                        Parcele em até 12x
                      </div>
                    </label>
                    <label className={`payment-type-button ${paymentType === 'pix' ? 'active' : ''}`}>
                      <input
                        type="radio"
                        name="paymentType"
                        value="pix"
                        checked={paymentType === 'pix'}
                        onChange={() => handlePaymentTypeChange('pix')}
                      />
                      <span className="custom-radio"></span>
                      <Pix />
                      Pix
                    </label>
                  </div>

                  {!loadingFetchingInstallments && paymentType === 'card' && <div className="payment-card">
                    <div className="payment-header">
                      <CardPayment className='tag-payment' />
                      <div className="info-header">
                        <span>Dados do cartão</span>
                        <span>Parcele em até 12x</span>
                      </div>
                      <img src={cardImage} alt="Cartão" />
                    </div>

                    <div className="payment-info-card">

                      <InputCheckout
                        disabled={isDisabled}
                        label="Nome impresso no cartão"
                        name="ccName"
                        className='small'
                        value={formData.ccName}
                        onChange={handleChange}
                        errorMessage={errors.ccName}
                        isValid={validFields.ccName}
                      />
                      <div className="row">
                        <InputCheckout
                          disabled={isDisabled}
                          label="Número do cartão"
                          name="ccNumber"
                          value={formData.ccNumber}
                          placeholder='0000 0000 0000 0000'
                          onChange={handleChange}
                          errorMessage={errors.ccNumber}
                          isValid={validFields.ccNumber} />
                        <DateInput
                          error={errors.selectedMonth || errors.selectedYear}
                          selectedMonth={selectedMonth}
                          selectedYear={selectedYear}
                          onMonthChange={handleMonthChange}
                          onYearChange={handleYearChange}
                        />
                        <InputCheckout
                          disabled={isDisabled}
                          label="Código de segurança"
                          name="ccCvv"
                          className='small'
                          placeholder='000'
                          value={formData.ccCvv}
                          maxLength={4}
                          onChange={handleChange}
                          errorMessage={errors.ccCvv}
                          isValid={validFields.ccCvv} />
                      </div>
                      <div className="divider" />
                      <div className="container-installments">
                        <div className="radio-group">
                          {installments.map((option) => {
                            const valuePerInstallment = parseValueFromString(option.text);
                            const totalValue = valuePerInstallment * option.installments;

                            return (
                              <label
                                key={option.installments}
                                className={`radio-label ${selectedInstallment === option.installments ? 'selected' : ''}`}
                              >
                                <input
                                  type="radio"
                                  name="installments"
                                  value={option.installments}
                                  checked={selectedInstallment === option.installments}
                                  onChange={handleChangeRadioGroup}
                                />
                                <span className="custom-radio"></span>
                                <span className="option-text">
                                  {option.text.replace(/\s\(com juros\)/, '')} - <strong>{formatCurrency(totalValue)} com juros</strong>
                                </span>
                              </label>
                            );
                          })}
                          {errors.installments && <div className="error">{errors.installments}</div>}
                        </div>
                        {selectedInstallment !== null && (<div className="info-box">
                          <span className='price'>{selectedInstallment}x {formatCurrency(valuePerInstallment)}</span>
                          <p className="details">Total: {formatCurrency(totalValue)}&nbsp; <span>com juros</span>
                          </p>
                        </div>)}
                      </div>
                    </div>
                  </div>
                  }
                  {!loadingFetchingInstallments && paymentType === 'pix' && <div className="payment-card">
                    <div className="payment-header">
                      <CardPayment className='tag-payment' />
                      <div className="info-header">
                        <span className='title'>Pagamento instantâneo</span>
                        <span></span>
                      </div>
                      <img className='pix' src={pixImage} alt="PIX" />
                    </div>
                    <div className="payment-info-card">
                      <div className="info-pix">
                        <span>Como pagar com o Pix?</span>
                        <p>Ao finalizar a compra, será gerado um <strong>QR Code de pagamento.</strong> Use o aplicativo do seu banco ou carteira digital para escaneá-lo e realizar o pagamento.</p>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            }
            {id && eventID && !loadingFetchingInstallments &&
              <div style={{
                width: '100%', display: 'flex', justifyContent: 'flex-end'
              }} >
                <button onClick={handleSubmit} className='payment-success'>Pagar agora</button>
              </div>
            }
          </div>
          <div className='right-box' />

        </div>
        <InfoModal
          event={eventData}
          title='Não foi possível finalizar o pedido'
          content='Analisamos os dados preenchidos junto ao processador de pagamentos e não foi possível aprovar o seu pedido. Saiba mais sobre o processo de análise '
          isOpen={isInfoModalOpen}
        />

        <InfoModal
          event={eventData}
          title='O tempo para a compra expirou'
          content='Isso é necessário para que uma reserva não fique presa e possa estar disponível para compra novamente.<br/><strong>Você pode recomeçar a compra clicando no botão abaixo.</strong>'
          isOpen={isInfoTimeModalOpen}
        />
      </div >
    </>
  );
};

export default CheckoutTicket;
