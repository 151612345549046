export const serviceCategories = {
  ADULT_CONTENT: 'Conteúdo Adulto',
  ARCHITECTURAL_AND_ENGINEERING: 'Arquitetura e Engenharia',
  ART_AND_ANTIQUE: 'Arte e Antiguidades',
  BAKERY: 'Padaria',
  BEAUTY_AND_BARBER: 'Beleza e Barbearia',
  BROKER: 'Corretor',
  BUTCHERY: 'Açougue',
  CLOTHING_AND_ACCESSORIES: 'Roupas e Acessórios',
  DENTISTRY: 'Odontologia',
  DOOR_TO_DOOR_SALES: 'Vendas Porta a Porta',
  EDUCATION: 'Educação',
  ENTERTAINMENT: 'Entretenimento',
  FLORIST: 'Florista',
  FREIGHT_FORWARDER: 'Agente de Cargas',
  GENERAL_CONTRACTOR: 'Empreiteiro Geral',
  GOVERNMENT: 'Governo',
  HEALTH_AND_BEAUTY_SERVICE: 'Serviço de Saúde e Beleza',
  HOUSEHOLD_APPLIANCE: 'Eletrodomésticos',
  HOUSE_HOLD: 'Doméstico',
  JEWELRY_AND_WATCH: 'Joias e Relógios',
  KEY_CHAIN: 'Chaveiro',
  LEGAL_SERVICE: 'Serviço Jurídico',
  LODGING: 'Hospedagem',
  MEDICAL_SERVICE: 'Serviço Médico',
  NONDURABLE_GOODS: 'Bens Não Duráveis',
  OTHER_SERVICES: 'Outros Serviços',
  PACKAGE_STORE: 'Loja de Pacotes',
  PERSONAL_TRAINER: 'Personal Trainer',
  PET_SUPPLIES: 'Suprimentos para Animais de Estimação',
  PHOTOGRAPHY_AND_VIDEO: 'Fotografia e Vídeo',
  PODIATRIST_AND_CHIROPODIST: 'Podólogo e Quiropodista',
  PROFESSIONAL_SERVICE: 'Serviço Profissional',
  REAL_ESTATE_AGENT: 'Corretor de Imóveis',
  RELIGION_AND_SPIRITUALITY: 'Religião e Espiritualidade',
  REPAIR_SHOPS: 'Oficinas de Reparo',
  RESTAURANT: 'Restaurante',
  SHOE_STORE: 'Loja de Calçados',
  SUPERMARKET: 'Supermercado',
  TAILOR_AND_SEAMSTRESS: 'Alfaiate e Costureira',
  TATTOOIST: 'Tatuador',
  TELECOMMUNICATION: 'Telecomunicação',
  TRANSPORTATION_SERVICE: 'Serviço de Transporte',
  VEHICLE_AND_PARTS: 'Veículos e Peças',
  VEHICLE_SERVICES: 'Serviços de Veículos',
  VETERINARY_SERVICE: 'Serviço Veterinário'
};