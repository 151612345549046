import PropTypes from 'prop-types';
import React from 'react';
import './InputCheckout.scss';

const InputCheckout = ({
  label = '',
  id,
  type = 'text',
  className = '',
  name,
  value,
  onChange,
  errorMessage = '',
  isValid = false,
  onBlur,
  disabled,
  maxLength,
  placeholder
}) => {
  return (
    <div className={`field ${className}`}>
      {label && <div className="title">{label}</div>}
      <input
        id={id}
        type={type}
        disabled={disabled}
        className={`input ${errorMessage ? 'error' : ''} ${isValid ? 'valid' : ''}`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      {errorMessage && <div className="input-error-msg">{errorMessage}</div>}
    </div>
  );
};

InputCheckout.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number
};

export default InputCheckout;
