import { Button, Divider, Form, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useEffect, useState } from 'react';
import { serviceCategories } from '../../../../../utils/serviceCategories';
import InputMoney from '../../../../common/InputMoney/InputMoney';
import './BusinessActivity.scss';

const BusinessActivity = ({ data, onSave, onDiscard, setUnsavedChanges, setDataToSave }) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [localData, setLocalData] = useState(data);
  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState({ price: data.revenue || 0 });

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    if (localData) {
      setDataToSave(localData);
      validateFields(localData);
    }
  }, [localData, setDataToSave]);

  const validateFields = (data) => {
    const newErrors = {};
    if (!data.type) newErrors.type = 'Campo obrigatório';
    if (!data.statement_descriptor) newErrors.statement_descriptor = 'Campo obrigatório';
    if (!data.mcc) newErrors.mcc = 'Campo obrigatório';
    if (!data.email) newErrors.email = 'Campo obrigatório';
    if (!data.revenue) newErrors.revenue = 'Campo obrigatório';
    if (!data.category) newErrors.category = 'Campo obrigatório';
    setErrors(newErrors);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalData(prevData => {
      const newData = { ...prevData, [name]: value };
      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const handleSelectChange = (value, name) => {
    setLocalData(prevData => {
      const newData = { ...prevData, [name]: value };
      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const onChangeInput = (event, nameObject, isNumber) => {
    const value = isNumber ? event.target.value : event.target.value;
    setAmount({ [nameObject]: value });

    setLocalData(prevData => {
      const newData = { ...prevData, revenue: value };
      setUnsavedChanges(true);
      validateFields(newData);
      return newData;
    });
  };

  const handleSave = async () => {
    if (Object.keys(errors).length === 0) {
      await onSave(localData);
      setEnableEdit(false);
      setUnsavedChanges(false);
    } else {
      alert('Por favor, corrija os campos obrigatórios.');
    }
  };

  const handleDiscard = async () => {
    await onDiscard();
    setEnableEdit(false);
    setUnsavedChanges(false);
  };

  return (
    <div className='BusinessActivity'>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className='subtitle-page'>Atividade comercial</p>
        <Button
          onClick={() => setEnableEdit(!enableEdit)}
          type={enableEdit ? "primary" : ""}
        >
          {enableEdit ? "Cancelar" : "Editar"}
        </Button>
      </div>
      <Divider />
      <div className="container-inputs">
        <div className="left-inputs">
          <Form layout="vertical">
            <Form.Item
              label="Tipo de Conta"
              help={errors.type}
              validateStatus={errors.type ? 'error' : ''}
            >
              <Select
                name="type"
                disabled={!enableEdit}
                placeholder="Selecione o Tipo de Conta"
                value={localData?.type || ''}
                onChange={(value) => handleSelectChange(value, 'type')}
              >
                <Option value="SELLER_PJ">Pessoa Jurídica</Option>
                <Option value="SELLER_PF">Pessoa Física</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Nome na Fatura"
              help={errors.statement_descriptor}
              validateStatus={errors.statement_descriptor ? 'error' : ''}
            >
              <Input
                name="statement_descriptor"
                disabled={!enableEdit}
                placeholder='Nome na Fatura'
                value={localData?.statement_descriptor || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Código Mercantil"
              help={errors.mcc}
              validateStatus={errors.mcc ? 'error' : ''}
            >
              <Input
                name="mcc"
                disabled={!enableEdit}
                placeholder='Código Mercantil'
                value={localData?.mcc || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="right-inputs">
          <Form layout="vertical">
            <Form.Item
              label="Email principal"
              help={errors.email}
              validateStatus={errors.email ? 'error' : ''}
            >
              <Input
                name="email"
                disabled={!enableEdit}
                placeholder='Email principal'
                value={localData?.email || ""}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Renda Mensal Declarada"
              help={errors.revenue}
              validateStatus={errors.revenue ? 'error' : ''}
            >
              <InputMoney
                onChange={(e) => onChangeInput(e, "price", true)}
                value={amount?.price || "0,00"}
                placeholder="R$ 0,00"
                disabled={!enableEdit}
              />
            </Form.Item>
            <Form.Item
              label="Categoria de Atuação"
              help={errors.category}
              validateStatus={errors.category ? 'error' : ''}
            >
              <Select
                name="category"
                disabled={!enableEdit}
                placeholder="Selecione a Categoria"
                value={localData?.category || ''}
                onChange={(value) => handleSelectChange(value, 'category')}
              >
                {Object.entries(serviceCategories).map(([key, label]) => (
                  <Option key={key} value={key}>{label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
      {enableEdit &&
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button onClick={handleSave} type="primary">Salvar</Button>
          <Button onClick={handleDiscard}>Descartar</Button>
        </div>
      }
    </div>
  );
};

export default BusinessActivity;
