import React, { useState, useEffect } from 'react';
import { Modal, Select, InputNumber, Tabs, Button } from 'antd';

const { Option } = Select;
const { TabPane } = Tabs;

const TaxAssistantModal = ({ visible, onCancel, onOk, installmentsOptions }) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [filteredInstallmentsOptions, setFilteredInstallmentsOptions] = useState([]);
  const [selectedInstallments, setSelectedInstallments] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [inputTax, setInputTax] = useState(null);

  useEffect(() => {
    if (selectedPaymentType === 'debit') {
      setFilteredInstallmentsOptions(['1']);
    } else {
      setFilteredInstallmentsOptions(installmentsOptions);
    }
    setSelectedInstallments(null); // Reset installments when payment type changes
  }, [selectedPaymentType, installmentsOptions]);

  const handleOk = () => {
    onOk(selectedPaymentType, selectedInstallments, selectedReceiver, inputTax);
  };

  const handleSameTax = () => {
    onOk(selectedPaymentType, selectedInstallments, selectedReceiver, inputTax, true);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: 'Edição em massa',
      children: (
        <>
          <Select
            placeholder="Selecione o tipo"
            onChange={(value) => setSelectedPaymentType(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="credit">Crédito</Option>
            <Option value="debit">Débito</Option>
          </Select>
          <Select
            placeholder="Selecione as parcelas"
            onChange={(value) => setSelectedInstallments(value)}
            value={selectedInstallments}
            style={{ width: '100%', marginBottom: '10px' }}
            disabled={!selectedPaymentType}
          >
            {filteredInstallmentsOptions.map((installment) => (
              <Option key={installment} value={installment}>
                {installment}x
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Selecione o recebedor"
            onChange={(value) => setSelectedReceiver(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="Administrador">Administrador</Option>
            <Option value="Agente">Agente</Option>
            <Option value="Distribuidor N1">Distribuidor N1</Option>
            <Option value="Distribuidor N2">Distribuidor N2</Option>
            <Option value="Programador">Programador</Option>
          </Select>
          <InputNumber
            placeholder="Taxa"
            onChange={(value) => setInputTax(value)}
            style={{ width: '100%' }}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Taxa Final',
      children: (
        <div>
          <Select
            placeholder="Selecione o tipo"
            onChange={(value) => setSelectedPaymentType(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="credit">Crédito</Option>
            <Option value="debit">Débito</Option>
          </Select>
          <Select
            placeholder="Selecione as parcelas"
            onChange={(value) => setSelectedInstallments(value)}
            value={selectedInstallments}
            style={{ width: '100%', marginBottom: '10px' }}
            disabled={!selectedPaymentType}
          >
            {filteredInstallmentsOptions.map((installment) => (
              <Option key={installment} value={installment}>
                {installment}x
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Selecione o recebedor"
            onChange={(value) => setSelectedReceiver(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="Administrador">Administrador</Option>
            <Option value="Agente">Agente</Option>
            <Option value="Distribuidor N1">Distribuidor N1</Option>
            <Option value="Distribuidor N2">Distribuidor N2</Option>
            <Option value="Programador">Programador</Option>
          </Select>
          <InputNumber
            placeholder="Taxa Final"
            onChange={(value) => setInputTax(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <Button type="primary" onClick={handleSameTax} style={{ width: '100%' }}>
            Mesma Taxa
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title="Assistente de taxas"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Modal>
  );
};

export default TaxAssistantModal;
