import React from 'react';
import './PaymentTable.scss'

const PaymentTable = ({ mockObject }) => {
  const flags = mockObject && Object.keys(mockObject?.credit[1]);
  return (
    <div className="container-table">
      <div className="shadow">
        {mockObject &&
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th className="text-center"></th>
                {flags.map((flag) => (
                  <th key={flag} className="text-center" colSpan="2">{flag}</th>
                ))}
              </tr>
              <tr>
                <th className="text-center"></th>
                {flags.map((flag) => (
                  <React.Fragment key={flag}>
                    <th className="text-center" colSpan="1">Valor</th>
                    <th className="text-center" colSpan="1">Receber</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Débito</td>
                {flags.map((flag) => (
                  <React.Fragment key={flag}>
                    <td className="text-center">R$ {mockObject.debit['1'][flag].amount}</td>
                    <td className="text-center">R$ {mockObject.debit['1'][flag].receivable_amount}</td>
                  </React.Fragment>
                ))}
              </tr>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((installment) => (
                <tr key={`credit-${installment}`}>
                  <td className="text-center">Crédito {installment}x</td>
                  {flags.map((flag) => (
                    <React.Fragment key={flag}>
                      <td className="text-center">R$ {mockObject.credit[installment][flag].amount}</td>
                      <td className="text-center">R$ {mockObject.credit[installment][flag].receivable_amount}</td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    </div>
  );
};

export default PaymentTable;
