import { LinkOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Form,
  Layout,
  Result,
  Skeleton,
  Table,
  message,
  notification,
  theme
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  apiCreateWebhook,
  apiDeleteWebhook,
  apiEditWebhook,
  apiWebhookList,
} from "../../../api/webhook";
import SellerDropdown from "../../common/SellerDropdown/SellerDropdown";
import "./WebhookList.scss";
import WebhookModalCreate from "./WebhookModalCreate/WebhookModalCreate";

const { Content } = Layout;

const WebhookList = () => {
  const [dataSales, setDataSale] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSales);
  const [webhookInfo, setWebhookInfo] = useState();

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setFilteredData(dataSales.results);
    } else {
      const filtered = dataSales.results?.filter((item) =>
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  };
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status === "500" ? "500" : "404"}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const handleListWebhooks = async (page) => {
    setLoading(true);
    try {
      const json = await apiWebhookList({ page });
      setDataSale(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os webhooks",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os webhooks, Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("500");
        notification.error("Erro ao buscar os webhooks");
      }
    }
  };

  const onChange = (pagination) => {
    handleListWebhooks(pagination.current);
  };

  useEffect(() => {
    if (dataSales?.length <= 1) {
      handleListWebhooks(pagination.current);
    }
  }, []);

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      notification.success({
        message: "Sucesso",
        description: "Link copiado com sucesso!",
        placement: "topRight",
      });
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "url",
      key: "url",
      render: (confirmed) => (
        <button onClick={() => handleCopy(confirmed)} className="link-button">
          <LinkOutlined />
        </button>
      ),
    },
    {
      title: "Events",
      dataIndex: "events",
      key: "events",
      sorter: (a, b) => {
        const statusOrder = {
          1: 1,
          0: 2,
        };
        return statusOrder[a.confirmed] - statusOrder[b.confirmed];
      },
      responsive: ["sm"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link
          onClick={() => {
            showModal(confirme);
          }}
        >
          Editar
        </Link>
      ),
      key: "",
    },
  ];

  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (info) => {
    setIsModalVisible(true);
    info ? setWebhookInfo(info) : setWebhookInfo();
  };

  const [form] = Form.useForm();
  const [loadingCreate, setLoadingCreate] = useState(false);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoadingCreate(true);
      const eventsString = values.events.join(",");
      const formattedValues = { ...values, events: eventsString };
      await apiCreateWebhook(formattedValues);
      notification.success({ message: "Webhook criado com sucesso!" });
      form.resetFields();
      setIsModalVisible(false);
      handleListWebhooks(1);
      setWebhookInfo();
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao criar o webhook! Preencha todos os campos de informações necessárias.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao criar o webhook. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        message.error("Por favor, preencha todos os campos corretamente.");
      }
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleEdit = async (id) => {
    try {
      const values = await form.validateFields();
      setLoadingCreate(true);
      const eventsString = values.events.join(",");
      const formattedValues = { ...values, events: eventsString };
      await apiEditWebhook(id, formattedValues);
      notification.success({ message: "Webhook Editado com sucesso!" });
      form.resetFields();
      setIsModalVisible(false);
      handleListWebhooks(1);
      setWebhookInfo();
    } catch (error) {
      message.error("Por favor, preencha todos os campos corretamente.");
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      await apiDeleteWebhook(id);
      notification.success({ message: "Webhook excluído com sucesso!" });
      form.resetFields();
      setIsModalVisible(false);
      handleListWebhooks(1);
      setWebhookInfo();
    } catch (error) {
      notification.error({ message: "Houve um erro inesperado" });
    } finally {
      setLoadingCreate(false);
    }
  };

  return (
    <div className="WebhookList">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <h2 className="title-page">Webhooks</h2>
                <SellerDropdown />
              </div>
              <Divider />
              <Breadcrumb.Item>Integrações</Breadcrumb.Item>
              <Breadcrumb.Item>Webhooks</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="box-divider">
                <h2 className="subtitle-page">Webhooks</h2>
                <Button onClick={() => showModal()} type="primary">
                  Criar Webhook
                </Button>
              </div>
              <Divider />
              {/*<Input.Search*/}
              {/*  placeholder='Pesquisar'*/}
              {/*  allowClear*/}
              {/*  enterButton*/}
              {/*  onSearch={handleSearch}*/}
              {/*  onChange={(e) => handleSearch(e.target.value)}*/}
              {/*  style={{ marginBottom: 16, maxWidth: '200px' }}*/}
              {/*/>*/}
              {loading ? (
                <Skeleton active />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  dataSource={
                    filteredData &&
                    filteredData.map((item) => ({ ...item, key: item.id }))
                  }
                  pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  bordered
                  onChange={onChange}
                />
              )}
            </Content>
            <Divider />
          </Layout>
          <WebhookModalCreate
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            form={form}
            loading={loadingCreate}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            webhookInfo={webhookInfo}
          />
        </>
      )}
    </div>
  );
};

export default WebhookList;
