import { baseSiteEndPoint, buildHeaders } from "./common";

export const apiCreateTicket = async (eventID, jsonData) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/event/event/${eventID}/checkout/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetCheckout = async (eventID, checkoutID) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/event/event/${eventID}/checkout/${checkoutID}/pay
`, {
      method: "GET",
      headers: buildHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Erro na resposta: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPayCheckout = async (eventID, checkoutID, jsonData) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/event/event/${eventID}/checkout/${checkoutID}/pay/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(jsonData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Erro ${response.status}: ${errorData.message || 'Erro desconhecido'}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
