import React, { useEffect, useState } from 'react';
import { MaskIdentity } from '../../../../utils/MaskIdentity';
import RadioTicket from '../../../common/RadioTicket/RadioTicket';
import SelectTicket from "../../../common/SelectTicket/SelectTicket";
import "./Question.scss";

const Question = ({ question, setAnswer, answer, displayAllErrors, errorForm, uniqueKey }) => {
  const { type, prompt, options } = question;
  const [valid, setValid] = useState(false);
  const [inputValue, setInputValue] = useState(answer || '');

  const typeEnums = {
    INTEGER: "number",
    CPF: "cpf",
    PHONE: "phone",
    STRING: "text",
    DATE: "date",
    EMAIL: "email",
    LIST: "list",
    RADIO: "radio"
  };
  useEffect(() => {
    if (errorForm && errorForm.error && errorForm.error.includes(uniqueKey)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [errorForm, uniqueKey]);
  useEffect(() => {
    if (displayAllErrors) {
      answer?.length <= 0 && setValid(true);
    }
  }, [displayAllErrors, answer]);

  const handleChange = (value) => {
    setAnswer(value);
    if (errorForm?.error[question?.id]) {
      delete errorForm.error[question?.id];
    }
    if (value) {
      setValid(false);
    }
    setValid(false);
  };

  const handleBlur = (validationFn) => {
    if (!validationFn(answer)) setValid(true);
  };

  const renderInput = () => {
    switch (typeEnums[type]) {
      case "phone":
        const phoneMask = answer.length > 6 ? "($1) $2-$3" : answer.length > 2 ? "($1) $2" : "$1";
        const phoneGroupRegex = answer.length > 10
          ? /^(\d{1,2})(\d{0,5})(\d{0,4}).*/
          : /^(\d{1,2})(\d{0,4})(\d{0,4}).*/;

        const formattedPhone = answer.replace(phoneGroupRegex, phoneMask);

        const getCleanNumber = (formattedNumber) => {
          return formattedNumber.replace(/\D/g, '');
        };

        const handlePhoneChange = (e) => {
          const inputValue = e.target.value;
          const cleanNumber = getCleanNumber(inputValue);
          handleChange(cleanNumber);
        };

        return (
          <div className='input-box'>
            <label htmlFor="phone">{prompt}</label>
            <input
              className={(errorForm?.error[question?.id] && 'error') || (valid && 'error')}
              type="text"
              name='phone'
              required
              onBlur={() => handleBlur(value => /^\d{10,11}$/.test(getCleanNumber(value)))}
              onChange={handlePhoneChange}
              value={formattedPhone}
            />
            <p>{(errorForm?.error[question?.id] && "Verifique o número") || (valid && "Verifique o número")}</p>
          </div>
        );
      case "cpf":
        const validateCpf = (cpf) => {
          const cpfRegex = /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/;
          return cpfRegex.test(cpf);
        };
        return (
          <div className='input-box'>
            <label htmlFor="cpf">{prompt}</label>
            <MaskIdentity
              type="text"
              name='identifier'
              className={(errorForm?.error[question?.id] && 'error') || (valid && 'error')}
              value={answer}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={() => handleBlur(validateCpf)}
            />
            <p>{!validateCpf(answer) && valid && "CPF inválido"}</p>
          </div>
        );
      case "email":
        const emailValidationRegex = /^\S+@\S+\.\S+$/;

        return (
          <div className='input-box'>
            <label htmlFor="email">{prompt}</label>
            <input
              className={(errorForm?.error[question?.id] && 'error') || (valid && 'error')}
              type="email"
              name='email'
              required
              onChange={(e) => handleChange(e.target.value)}
              value={answer}
              onFocus={() => setValid(false)}
              onBlur={() => handleBlur(value => emailValidationRegex.test(value))}
            />
            <p>{(errorForm?.error[question?.id] && "Campo de email inválido") || (valid && "Campo de email inválido")}</p>
          </div>
        );
      case "text":
        return (
          <div className='input-box'>
            <label htmlFor="text">{prompt}</label>
            <input
              className={(errorForm?.error[question?.id] && 'error') || (valid && 'error')}
              type="text"
              required
              name='text'
              onChange={(e) => handleChange(e.target.value)}
              value={answer}
            />
            <p>{(valid && "Preencha o campo") || (errorForm?.error[question?.id] && "Preencha o campo")}</p>
          </div>
        );
      case "date":
        const validateDate = (date) => {
          const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
          return dateRegex.test(date);
        };

        const formatToISO = (date) => {
          const [day, month, year] = date.split('/').map(Number);
          return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        };

        const applyMask = (value) => {
          const cleanedValue = value.replace(/\D/g, '');
          const day = cleanedValue.slice(0, 2);
          const month = cleanedValue.slice(2, 4);
          const year = cleanedValue.slice(4, 8);

          if (cleanedValue.length <= 2) return day;
          if (cleanedValue.length <= 4) return `${day}/${month}`;
          return `${day}/${month}/${year}`;
        };

        const handleDateChange = (e) => {
          const newValue = e.target.value;
          const formattedValue = applyMask(newValue);
          setInputValue(formattedValue);

          if (validateDate(formattedValue)) {
            handleChange(formatToISO(formattedValue));
          } else {
            handleChange('');
          }
        };

        const handleDateBlur = () => {
          const validateDate = (date) => {
            const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
            return dateRegex.test(date);
          };

          if (!validateDate(inputValue)) {
            setValid(true);
          } else {
            setValid(false);
            handleChange(formatToISO(inputValue));
          }
        };

        return (
          <div className='input-box'>
            <label htmlFor="date">{prompt}</label>
            <input
              className={(errorForm?.error[question?.id] && 'error') || (valid && 'error')}
              type="text"
              required
              name='date'
              onChange={handleDateChange}
              value={applyMask(inputValue)}
              onBlur={() => handleDateBlur(validateDate(inputValue))}
            />
            <p>{(errorForm?.error[question?.id] && "Formato inválido para data") || (valid && "Formato inválido para data")}</p>
          </div>
        );
      case "list":
        return (
          <SelectTicket
            label={prompt}
            defaultOption="Selecione uma opção"
            options={options || []}
            updateState={handleChange}
            displayError={valid}
            displayErrorMessage="Campo obrigatório"
            value={answer}
          />
        );
      case "list":
        return (
          <SelectTicket
            label={prompt}
            defaultOption="Selecione uma opção"
            options={options || []}
            updateState={handleChange}
            displayError={valid}
            displayErrorMessage="Campo obrigatório"
            value={answer}
          />
        );
      case "radio":
        return (
          <RadioTicket
            label={prompt}
            options={options || []}
            updateState={setAnswer}
            checked={answer}
            displayError={valid}
            displayErrorMessage="Campo obrigatório"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="Question">
      {renderInput()}
    </div>
  );
};

export default Question;
