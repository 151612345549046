import React from 'react'
import './FinalRegister.scss'
import { Button, Divider } from 'antd'
import logo from '../../../../assets/images/register/logo-dlpay.svg'
import ilustration from '../../../../assets/images/register/ilustration.svg'

const FinalRegister = () => {
  return (
    <div className='FinalRegister'>
      <div className="header-final">
        <img src={logo} alt="Logo" />
      </div>
      <div className="content">
        <p className="title">Eba, cadastro finalizado!</p>
        <img src={ilustration} alt="Ilustração" />
        <p className="describe">Estamos analisando suas informações em <br />até x dias úteis <br /><br />
          Fique de olho em seu email, <br />quando finalizar a gente te avisa!</p>

        <div className="container-buttons">
          <Button className='button-continue' size='large' type="primary">Ok, obrigado!</Button>
          <Button className='button-continue' size='large' type="default">Visualizar cadastro</Button>
        </div>
      </div>
    </div>
  )
}

export default FinalRegister
