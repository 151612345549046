import { Skeleton } from 'antd';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetEventNoAuth } from '../../../../api/events';
import logo from "../../../../assets/images/logo.png";
import { ReactComponent as Drecrement } from "../../../../assets/images/ticket/decrement.svg";
import { ReactComponent as Increment } from "../../../../assets/images/ticket/increment.svg";
import { ReactComponent as Location } from "../../../../assets/images/ticket/location.svg";
import { ReactComponent as Transfer } from "../../../../assets/images/ticket/transfer.svg";
import ShareModal from "../ShareModal/ShareModal";
import './TicketSelector.scss';

const TicketSelector = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const [quantities, setQuantities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getMapUrl = () => {
    const { name, street, number, city, state } = event?.address || {};
    const address = `${name}, ${street}, ${number}, ${city}, ${state}`;
    return `https://www.google.com/maps/search/${encodeURIComponent(address)}`;
  };

  useEffect(() => {
    apiGetEventNoAuth(id).then(data => {
      setEvent(data);
      setQuantities(data?.tickets?.map(() => 0));
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (event) {
      try {
        const startDate = parseISO(event.starts_at);
        const endDate = parseISO(event.ends_at);

        const formattedStart = format(startDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });
        const formattedEnd = format(endDate, 'dd MMM - yyyy • HH:mm', { locale: ptBR });

        setFormattedStartDate(formattedStart);
        setFormattedEndDate(formattedEnd);
      } catch (error) {
        console.error('Error parsing or formatting date:', error);
      }
    }
  }, [event]);

  function renderHTML(html) {
    return (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );
  }

  const calculateTotal = () => {
    return event?.tickets?.reduce((total, ticket, index) => {
      return total + (ticket.amount * quantities[index]);
    }, 0) / 100;
  };

  const hasItems = quantities.some(quantity => quantity > 0);

  const updateQuantity = (index, change) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      const newQuantity = Math.max(0, newQuantities[index] + change);
      newQuantities[index] = newQuantity;
      return newQuantities;
    });
  };

  const total = calculateTotal();
  const installment = (total / 12).toFixed(2);

  const handleCheckout = () => {
    const selectedTickets = event?.tickets?.map((ticket, index) => ({
      id: ticket.id,
      name: ticket.name,
      quantity: quantities[index],
      price: ticket.amount / 100,
      questions: ticket.questions
    })).filter(ticket => ticket.quantity > 0);

    navigate(`/evento/checkout/${event.id}`, { state: { tickets: selectedTickets, total, event } });
  };

  return (
    <>
      <Helmet>
        <title>{event.name}</title>
      </Helmet>
      <div className='TicketSelector'>
        <div style={{ width: '100%', height: '66px', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '100%' }} />
        </div>
        <div className="header-event" style={{ backgroundImage: `url(${isLoading ? '' : event?.image_url})` }}>
          <div className="header-image-container">
            {!isLoading && <div className="header-image" style={{ backgroundImage: `url(${isLoading ? '' : event?.image_url})` }}>
              {!isLoading && <button onClick={openModal}>
                <Transfer />Compartilhar
              </button>}
            </div>}
            <div className="header-disabled">
              {!isLoading && <button onClick={openModal}>
                <Transfer />Compartilhar
              </button>}
            </div>
          </div>
        </div>
        <div className="event-section">
          {!isLoading && <div className="summary">
            <div className="info-summary">
              <div className="tag">
                <span className="ion--card-outline" /><p>Parcele sua compra em até 12x</p>
              </div>
              <h1>{event.name}</h1>
              <div className="event-information-container">
                <span className="mdi--clock-outline" />
                <div>
                  <p className="title">
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                </div>
              </div>
              <div className="event-information-container">
                <span className="carbon--location" />
                <div>
                  <p className="title">
                    {`${event?.address?.street}, ${event?.address?.number}${event?.address?.complement ? ` - ${event?.address?.complement}` : ''}, ${event?.address?.neighborhood}, ${event?.address?.city} - ${event?.address?.state}, ${event?.address?.postal_code}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="ticket-section">
              <div className="header">
                <p>Ingressos</p>
                <div className="price-info">
                  R$ {calculateTotal().toFixed(2)}
                </div>
              </div>
              {event?.tickets?.map((ticket, index) => (
                <div key={ticket.id} className="ticket-item">
                  <div className="ticket-details">
                    <div className="ticket-title">
                      <span>{ticket.name}</span>
                    </div>
                    <p className="price">
                      <span>
                        {ticket.amount === 0 ? 'Gratuito' : `R$ ${(ticket.amount / 100).toFixed(2)}`}
                      </span>
                      {ticket.amount > 0 && <span> (+ taxa)</span>}
                    </p>
                    {ticket.amount > 0 && (
                      <>
                        <p className="installments">em até 12x R$ {((ticket.amount / 12) / 100).toFixed(2)}</p>
                        <p className="validity">
                          Vendas até {event ? new Date(event.ends_at).toLocaleDateString('pt-BR') : 'data não disponível'}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="quantity-controls">
                    <button aria-label="Decrease Amount" onClick={() => updateQuantity(index, -1)} className={`decrease-btn ${quantities[index] === 0 ? 'disabled' : ''}`}><Drecrement /></button>
                    <span className="quantity">{quantities[index]}</span>
                    <button aria-label="Increase Amount" onClick={() => updateQuantity(index, 1)} className="increase-btn"><Increment /></button>
                  </div>
                </div>
              ))}
              {!hasItems && <div className="total-summary">
                <div className="purchase-button">
                  <button type="button">SELECIONE UM INGRESSO</button>
                </div>
              </div>}
              {hasItems && (
                <div className="total-summary">
                  <div className="total-details">
                    <p>Total</p>
                    <p className="total-price">R$ {total.toFixed(2)}</p>
                    <p className="total-installments">ou 12x R$ {installment}</p>
                  </div>
                  <div className="open-button">
                    <button type="button" onClick={handleCheckout}>COMPRAR INGRESSOS</button>
                  </div>
                </div>
              )}
            </div>
          </div>}
          {isLoading && <div className="summary">
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>}
          {!isLoading && <div className="event-payment">
            <div className="ticket-description">
              <div className="description">
                <p className='title'>Descrição do evento</p>
                <div className="render-html">
                  {event?.description && renderHTML(JSON.parse(event?.description))}
                </div>
                <div className="divider" />
                <p className='title'>Local</p>
                <p className='address subtitle'>
                  {event?.address?.name}
                </p>
                <p className='address info'>
                  {event?.address?.street}, {event?.address?.number}
                  <br />
                  {event?.address?.city} - {event?.address?.state}
                </p>
                <a
                  className="location-button"
                  href={getMapUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Location />
                  VER NO MAPA
                </a>
              </div>
            </div>
          </div>}
        </div>
        <ShareModal event={event} isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>
  );
}

export default TicketSelector;
