import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  Result,
  Select,
  Skeleton,
  Space,
  Table,
  message,
  notification,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  apiCheckoutDelete,
  apiCheckoutDetail,
  apiCheckoutEdit,
  apiPaymentDelete,
  apiPaymentDetail,
  apiPaymentDownload,
  apiPaymentEdit,
  apiPaymentsCheckout
} from "../../../api/payment";
import InputMoney from "../../common/InputMoney/InputMoney";
import "./PaymentInfo.scss";
const PaymentInfo = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const checkoutID = parts[parts.length - 1];
  const [dataTransaction, setDataTransaction] = useState();
  const [name, setName] = useState();
  const [boleto, setBoleto] = useState();
  const [credit, setCredit] = useState();
  const [pix, setPix] = useState();
  const [boletoMonthDay, setBoletoMothDay] = useState();
  const [maxInstallments, setMaxInstallments] = useState();
  const [maxBoletonstallments, setMaxBoletonstallments] = useState();
  const [maxInstallmentsTax, setMaxInstallmentsTax] = useState();
  const [description, setDescription] = useState("TESTE");
  const [reference, setReference] = useState();
  const [date, setDate] = useState();
  const [link, setLink] = useState();
  const [amount, setAmount] = useState({ price: 0 });
  const [planID, setPlanID] = useState();
  const [loading, setLoading] = useState(false);

  const [enableEdit, setEnableEdit] = useState(false);

  const handleSubmit = async () => {
    try {
      if (amount.price === 0) {
        notification.error({
          message: "Erro",
          description: "Preencha o campo de valor!",
          placement: "topRight",
        });
        return;
      }

      const jsonData = {
        allow_boleto: boleto,
        allow_credit_card: credit,
        allow_pix: pix,
        boleto_due_month_day: boletoMonthDay,
        boleto_max_installments: maxBoletonstallments,
        max_installments: maxInstallments,
        max_installments_no_tax: maxInstallmentsTax,
        name,
      };

      const payload = {
        amount: amount.price,
        description,
        name,
        expiration_date: date,
        link,
        reference: !reference
          ? name
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "")
            .slice(0, 100)
          : reference,
      };

      await apiPaymentEdit(planID, jsonData);

      await apiCheckoutEdit(checkoutID, payload);

      notification.success({
        message: "Sucesso",
        description: "Link de pagamento editado com sucesso.",
        placement: "topRight",
      });
      navigate(`/pagamentos`);
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao editar o link de pagamento! Preencha todos os campos de informações do plano de pagamento.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao editar o link de pagamento. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao processar a requisição. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      }
    }
  };
  const initialValues = {
    name: "",
    description: "",
    price: "0,00",
    reference: "",
    expirateDate: "",
  };
  const handleDateChange = (e) => {
    const { value } = e.target;
    const formattedDate = formatDate(value);
    setDate(formattedDate);
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const [idPaymentDetails, setIdPaymentDetails] = useState();
  const handleList = async () => {
    setLoading(true);
    try {
      await apiCheckoutDetail(checkoutID).then((json) => {
        form.setFieldsValue({ expirateDate: json?.expiration_date });
        setDate(json.expiration_date);
        setDataTransaction(json);
        setLink(json?.link);
        setDescription(json?.description);
        form.setFieldsValue({ description: json?.description });
        setReference(json.reference);
        form.setFieldsValue({ reference: json?.reference });
        setAmount({ price: json?.amount });
        setPlanID(json.payment_plan);

        apiPaymentDetail(json.payment_plan).then((paymentJson) => {
          setName(paymentJson.name);
          setIdPaymentDetails(paymentJson.id)
          form.setFieldsValue({ name: json?.name });
          setBoleto(paymentJson.allow_boleto);
          setCredit(paymentJson.allow_credit_card);
          setPix(paymentJson.allow_pix);
          setMaxBoletonstallments(Number(paymentJson.boleto_max_installments));
          setBoletoMothDay(paymentJson.boleto_due_month_day);
          setMaxInstallmentsTax(Number(paymentJson.max_installments_no_tax));
          setMaxInstallments(Number(paymentJson.max_installments));
          setLoading(false);
        });
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar as informações!",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar as informações. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      }
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  const handlePixToggle = () => {
    setPix((prevPix) => !prevPix);
  };

  const handleCrediToggle = () => {
    setCredit((prevPix) => !prevPix);
  };

  const handleBoletoToggle = () => {
    setBoleto((prevPix) => !prevPix);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => {
      notification.success({
        message: "Sucesso",
        description: "Link copiado com sucesso!",
        placement: "topRight",
      });
    });
  };

  const deleteCheckoutAndPaymentAll = async () => {
    try {
      const [checkoutResponse, paymentResponse] = await Promise.all([
        apiCheckoutDelete(checkoutID),
        apiPaymentDelete(planID),
      ]);
      if (checkoutResponse.status === 204 && paymentResponse.status === 204) {
        return { checkoutResponse, paymentResponse };
      } else {
        throw new Error("Erro ao excluir checkout ou link de pagamento");
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteContent = async () => {
    try {
      await deleteCheckoutAndPaymentAll();

      notification.info({
        message: "Sucesso",
        description: "Link de pagamento foi excluído com sucesso!",
        placement: "topRight",
      });
      navigate(`/pagamentos`);
    } catch (error) {
      notification.error({
        message: "Erro",
        description:
          "Ocorreu um erro ao excluir o checkout e o plano de pagamento. Por favor, tente novamente mais tarde.",
        placement: "topRight",
      });
    }
  };

  const onChangeInput = (event, nameObject, isNumber) => {
    setAmount({
      [nameObject]: isNumber ? Number(event.target.value) : event.target.value,
    });
  };

  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.name && values.description && values.expirateDate) {
          handleSubmit();
        } else {
          message.error("Preencha todos os campos obrigatórios.");
        }
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  // Estados para a tabela
  const [paymentData, setPaymentData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, total: 0, pageSize: 10 });

  // Função para buscar dados
  const fetchPaymentData = async (page = 1) => {
    setLoading(true);
    try {
      const response = await apiPaymentsCheckout(checkoutID, { page });
      setPaymentData(response.results);
      setPagination({ ...pagination, total: response.count, current: page });
    } catch (error) {
      notification.error({ message: "Erro", description: "Erro ao carregar dados.", placement: "topRight" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  const statusMap = {
    succeeded: <span className="badge badge-success">Confirmada</span>,
    failed: <span className="badge badge-error">Não autorizada</span>,
    canceled: <span className="badge badge-error">Cancelado</span>,
    waiting: <span className="badge yellow">Aguardando</span>,
    mixed: <span className="badge yellow">Múltiplos Status</span>,
    pre_authorized: <span className="badge yellow">Pré Autorizado</span>,
    reversed: <span className="badge yellow">Invertido</span>,
    pending: <span className="badge orange">Pendente</span>,
    new: <span className="badge blue">Novo</span>,
    created: <span className="badge blue">Novo</span>,
    partial_refunded: <span className="badge blue">Reembolsado Parcial</span>,
    dispute: <span className="badge badge-error">Em disputa</span>,
    chargeback: <span className="badge black">Chargeback</span>
  };

  const renderStatus = (status) => {
    return statusMap[status] || <span className="badge black">Indefinido</span>;
  };

  const columns = [
    {
      title: "Cliente",
      dataIndex: ["customer", "name"],
      key: "customer_name",
      responsive: ["sm"],
    },
    {
      title: "Email",
      dataIndex: ["customer", "email"],
      key: "customer_email",
    },
    {
      title: "Telefone",
      dataIndex: ["customer", "phone_number"],
      key: "customer_phone",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, confirme) => (
        <Button
          type="link"
          onClick={() => window.location.href = `/detalhe/pagamento/${checkoutID}/${confirme.id}`} para recarregar a página
        >
          <InfoCircleOutlined />
        </Button>
      ),
    },
  ];

  const onTableChange = (pagination) => {
    fetchPaymentData(pagination.current);
  };

  const handleDownload = async (format) => {
    try {
      const blob = await apiPaymentDownload(checkoutID, idPaymentDetails, format);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `detalhes-pagamento-${name}.${format === 'csv' ? 'csv' : 'xlsx'}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const items = [
    {
      label: 'CSV',
      key: 'csv',
      icon: <FileDoneOutlined />,
    },
    {
      label: 'EXCEL',
      key: 'excel',
      icon: <FileDoneOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    handleDownload(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="PaymentInfo">
      {activeError ? (
        renderError(errorStatus)
      ) : (
        <>
          <Layout
            style={{
              padding: "1.25rem",
            }}
          >
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Breadcrumb
                  style={{
                    margin: "16px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <h2 className="title-page">{name}</h2>
                    <Button
                      onClick={() => setEnableEdit(!enableEdit)}
                      type={`${enableEdit ? "primary" : ""}`}
                    >
                      EDITAR
                    </Button>
                  </div>
                  <Divider />
                  <Breadcrumb.Item className='goBack' onClick={() => navigate(-1)}>Pagamentos</Breadcrumb.Item>
                  <Breadcrumb.Item>{dataTransaction?.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">1. Link de Pagamento</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <Form form={form} initialValues={initialValues}>
                        <Form.Item
                          label="Nome do Link"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o nome do link.",
                            },
                          ]}
                        >
                          <Input
                            disabled={!enableEdit}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Descrição"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira a descrição.",
                            },
                          ]}
                        >
                          <Input
                            disabled={!enableEdit}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Valor"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira o valor.",
                            },
                          ]}
                        >
                          <InputMoney
                            disabled={!enableEdit}
                            onChange={(e) => onChangeInput(e, "price", true)}
                            value={amount?.price || "0,00"}
                            placeholder="R$ 0,00"
                          />
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="container-right">
                      <Form form={form}>
                        <Form.Item
                          label="Referência"
                          name="reference"
                          rules={[
                            {
                              required: false,
                              message: "Por favor, insira a referência.",
                            },
                          ]}
                        >
                          <Input
                            disabled={!enableEdit}
                            onChange={(e) => setReference(e.target.value)}
                            value={reference}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Data de Vencimento"
                          name="expirateDate"
                          rules={[
                            {
                              required: true,
                              message:
                                "Por favor, selecione a data de vencimento.",
                            },
                          ]}
                        >
                          <input
                            onChange={handleDateChange}
                            type="date"
                            disabled={!enableEdit}
                            name="expirateDate"
                            id="expirateDate"
                            value={initialValues?.expirateDate}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="link">
                    <button onClick={() => handleCopy()}>
                      <LinkOutlined />
                    </button>
                    <Input type="link" disabled value={link} />
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">2. Plano de Pagamento</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <Card>
                        <p className="title">Boleto</p>
                        <Divider />
                        <Checkbox
                          disabled={!enableEdit}
                          checked={boleto}
                          onChange={handleBoletoToggle}
                        >
                          Permitir Boleto
                        </Checkbox>
                        {false && (
                          <>
                            <div>
                              <p>Máximo de parcelas do Cârne</p>
                              <Select
                                disabled={!enableEdit}
                                placeholder="Selecione um número"
                                onChange={(e) => setMaxBoletonstallments(e)}
                                value={maxBoletonstallments}
                              >
                                {[...Array(12).keys()].map((num) => (
                                  <Option key={num + 1} value={num + 1}>
                                    x{num + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <p>Data de vencimento (BOLETO)</p>
                              <Input
                                disabled={!enableEdit}
                                type="number"
                                value={boletoMonthDay}
                                onChange={(e) =>
                                  setBoletoMothDay(e.target.value)
                                }
                              />
                            </div>
                          </>
                        )}
                      </Card>
                    </div>
                    <div className="container-right">
                      <Card>
                        <p className="title">Cartão</p>
                        <Divider />
                        <Checkbox
                          disabled={!enableEdit}
                          checked={credit}
                          onChange={handleCrediToggle}
                        >
                          Permitir Cartão de Crédito
                        </Checkbox>
                        {credit && (
                          <>
                            <div>
                              <p>Máximo de parcelas</p>
                              <Select
                                disabled={!enableEdit}
                                placeholder="Selecione um número"
                                onChange={(e) => setMaxInstallments(e)}
                                value={maxInstallments}
                              >
                                {[...Array(12).keys()].map((num) => (
                                  <Option key={num + 1} value={num + 1}>
                                    x{num + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <p>Máximo de parcelas sem taxa</p>
                              <Select
                                disabled={!enableEdit}
                                placeholder="Selecione um número"
                                onChange={(e) => setMaxInstallmentsTax(e)}
                                value={maxInstallmentsTax}
                              >
                                <Option key={0} value={0}>
                                  Juros ao comprador
                                </Option>
                                {[...Array(12).keys()].map((num) => (
                                  <Option key={num + 1} value={num + 1}>
                                    x{num + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </>
                        )}
                      </Card>
                      <Card style={{ marginTop: "20px" }}>
                        <p className="title">PIX</p>
                        <div>
                          <Checkbox
                            disabled={!enableEdit}
                            checked={pix}
                            onChange={handlePixToggle}
                          >
                            Permitir PIX
                          </Checkbox>
                        </div>
                      </Card>
                    </div>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">3. Pagamentos</h3>
                  </div>
                  <Divider />
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={paymentData}
                      pagination={pagination}
                      loading={loading}
                      onChange={onTableChange}
                      rowKey={(record) => record.id}
                      locale={{ emptyText: <Empty description="Sem dados" /> }}
                    />
                  )}
                </Content>

                <div className="download-section">
                  <Dropdown menu={menuProps} >
                    <Button>
                      <Space>
                        Baixar Relatório
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </div>
                <div
                  className="container-buttons"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyItems: "space-between",
                    width: "100%",
                  }}
                >
                  <Button onClick={() => deleteContent()} type="primary" danger>
                    Excluir link de pagamento
                  </Button>
                  {enableEdit && (
                    <Button onClick={() => onFinish()} type="primary">
                      Salvar alterações
                    </Button>
                  )}
                </div>

              </>
            )}
          </Layout>
        </>
      )}
    </div>
  );
};

export default PaymentInfo;
