import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccess } from '../../../api/login';
import { getItemFromStorage } from '../../../helpers/storage';
import './SellerDropdown.scss';

const { Option } = Select;

const SellerDropdown = (initial) => {
  const [sellers, setSellers] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [sellersLoaded, setSellersLoaded] = useState(false);
  const user = getItemFromStorage("userInfo");
  const [getSellerStorage, setGetSellerStorage] = useState(null);

  useEffect(() => {
    try {
      const sellerStorage = localStorage.getItem("seller");
      if (sellerStorage) {
        setGetSellerStorage(JSON.parse(sellerStorage));
      } else {
        setGetSellerStorage(null);
      }
    } catch (error) {
      console.error("Error parsing seller storage", error);
      setGetSellerStorage(null);
      window.localStorage.clear();
      window.location.href = "/login";
    }
  }, []);

  const getSellers = async () => {
    if (user) {
      const userId = JSON.parse(user).id;
      const data = await getAccess(userId);
      setSellers(data.results);

      const savedSeller = localStorage.getItem("seller");
      if (selectedSeller) {
        try {
          const parsedSeller = JSON.parse(savedSeller);
          if (parsedSeller && parsedSeller.id) {
            setSelectedSeller(parsedSeller);
          }
        } catch (e) {
          localStorage.removeItem("seller");
        }
      }
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleSelectChange = (value) => {
    const selected = sellers.find(seller => seller.seller.id === value);
    if (selected) {
      const sellerData = { id: selected.seller.id, display_name: selected.seller.display_name };
      localStorage.setItem("seller", JSON.stringify(sellerData));
      setSelectedSeller(sellerData);
      isEmptyObject(initial) ? window.location.reload() :
        window.location.href = "/"
    } else {
      localStorage.removeItem("seller");
      setSelectedSeller(null);
    }
  };

  const handleDropdownVisibleChange = (visible) => {
    if (visible && !sellersLoaded) {
      getSellers();
      setSellersLoaded(true);
    }
  };

  return (
    <Select
      className='SellerDropdown'
      placeholder="Selecione um vendedor"
      value={getSellerStorage ? getSellerStorage.display_name : 'Selecione um vendedor'}
      onChange={handleSelectChange}
      showSearch
      onDropdownVisibleChange={handleDropdownVisibleChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {sellers.map((seller) => (
        <Option key={seller.seller.id} value={seller.seller.id}>
          {seller.seller.display_name}
        </Option>
      ))}
    </Select>
  );
};

export default SellerDropdown;
