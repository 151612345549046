import React from "react";
import { Modal, Button, notification } from "antd";

const ApiKeyCopyModal = ({ copyModalVisible, handleCancel, apiKey }) => {
  const handleCopyKey = () => {
    navigator.clipboard.writeText(apiKey?.key)
      .then(() => {
        notification.success({
          message: 'Chave copiada com sucesso!',
          placement: 'topRight',
        });
      })
      .catch((error) => {
        console.error('Erro ao copiar a chave:', error);
        notification.error({
          message: 'Erro ao copiar a chave!',
          placement: 'topRight',
        });
      });
  };

  return (
    <Modal
      title="Chave Única"
      visible={copyModalVisible}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="copy" type="primary" onClick={() => handleCopyKey()}>
          Copiar
        </Button>,
        <Button key="cancel" onClick={() => handleCancel()}>
          Cancelar
        </Button>,
      ]}
    >
      <p>Por favor, copie a chave única abaixo:</p>
      <p><strong>{apiKey?.key}</strong></p>
    </Modal>
  );
};

export default ApiKeyCopyModal;
