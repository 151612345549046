import { useState } from "react";
import { validCNPJ } from "../utils/cnpjValidate";
import { validCPF } from "../utils/cpfValidate";

export const useCpfInput = (initialValue = '') => {
  const [cpf, setCpf] = useState(initialValue);
  const [valid, setValid] = useState(false);

  const handleChange = (value) => {
    console.log(value)
    const cleanValue = value.replace(/[^0-9]/g, '');
    setCpf(cleanValue);

    if (cleanValue.length <= 11) {
      setValid(validCPF(cleanValue));
    } else {
      setValid(validCNPJ(cleanValue));
    }
  };

  return [cpf, handleChange, valid];
};