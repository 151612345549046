import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Checkbox, Button, Spin, notification } from "antd";
import { apiDetailsApiKey } from "../../../../api/apikey";

const ApiKeyListModal = ({
  isModalVisible,
  handleOk,
  handleEdit,
  handleDelete,
  handleCancel,
  form,
  loading,
  webhookInfo,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const fetchApiKeyDetails = async () => {
      try {
        setLoadingDetails(true);
        const data = await apiDetailsApiKey(webhookInfo.id);
        const initialData = {
          name: data.name,
          description: data.description,
        };
        form.setFieldsValue(initialData);
        setActive(data?.active);
      } catch (error) {
        if (error.status === 400) {
          notification.error({
            message: "Erro",
            description: "Ocorreu um erro ao listar os detalhes do webhook!",
            placement: "topRight",
          });
        } else if (error.status === 500) {
          notification.error({
            message: "Erro",
            description:
              "Ocorreu um erro no servidor ao listar os detalhes do webhook. Por favor, tente novamente mais tarde.",
            placement: "topRight",
          });
        } else {
          notification.error("Erro ao buscar detalhes do webhook");
        }
      } finally {
        setLoadingDetails(false);
      }
    };

    if (webhookInfo) {
      fetchApiKeyDetails();
    } else {
      form.resetFields();
    }
  }, [webhookInfo, form]);

  return (
    <>
      <Modal
        title={webhookInfo ? "Edição - Webhook" : "Criação - Webhook"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={webhookInfo ? "Atualizar" : "Submit"}
        cancelText="Cancel"
        footer={[
          webhookInfo && (
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => handleDelete(webhookInfo.id)}
            >
              Deletar
            </Button>
          ),
          <Button
            key="submit"
            type="primary"
            loading={loading || loadingDetails}
            onClick={() =>
              webhookInfo ? handleEdit(webhookInfo.id) : handleOk()
            }
          >
            {webhookInfo ? "Atualizar" : "Criar"}
          </Button>,
        ]}
      >
        <Spin
          spinning={loadingDetails}
          tip="Carregando..."
          style={{ textAlign: "center" }}
        >
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Obrigatório!",
                },
              ]}
            >
              <Input placeholder="Exemplo" />
            </Form.Item>
            <Form.Item
              label="Descrição"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Obrigatório!",
                },
              ]}
            >
              <Input placeholder="exemplo de api key" />
            </Form.Item>
            {webhookInfo && (
              <Form.Item name="active" valuePropName="checked">
                <div style={{ display: "flex", gap: "10px" }}>
                  <p>Ativo</p>
                  <Checkbox
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                </div>
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ApiKeyListModal;
